import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { User } from '../classes/user';
import { Account, AccountPatchRequest, AccountPatchResponse, AccountResponse } from '../models/account';
import { environment } from 'src/environments/environment';
import _ from 'lodash';
import { map, switchMap, first, tap, finalize } from 'rxjs/operators';
import { arraysToObjects } from '../array-fix/functions/array-to-object';
import { objectsToArrays } from '../array-fix/functions/object-to-array';
import { SiteService } from './site.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  account$!: Observable<Account>;
  
  private accountSource!: ReplaySubject<Account>;

  constructor(
    private siteService: SiteService,
    private http: HttpClient
  ) {
    this.accountSource = new ReplaySubject<Account>(1);
    this.account$ = this.accountSource.asObservable();
  }

  get(): Observable<AccountResponse> {

    const url = environment.api.host + environment.api.paths.api.account.get;

    this.siteService.addSubscriptionLog(this, 'account.service.ts->get->this.http.get<AccountResponse>(url)');

    return this.http.get<AccountResponse>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('account.service.ts->get->this.http.get<AccountResponse>(url)')),
      map(res => {

        res.data.data = objectsToArrays(res.data.data);

        this.accountSource.next(res.data);
        
        return res;

      })
    );

  }

  post(data: any): Observable<any> {

    const url = environment.api.host + environment.api.paths.api.account.post;

    this.siteService.addSubscriptionLog(this, 'account.service.ts->post->this.http.post<AccountResponse>(url, data)');

    return this.http.post<AccountResponse>(url, data).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('account.service.ts->post->this.http.post<AccountResponse>(url, data)')),
      map(res => {
        this.accountSource.next(res.data);
        return res;
      })
    );

  }

  patch(data: AccountPatchRequest): Observable<AccountPatchResponse> {

    const url = environment.api.host + environment.api.paths.api.account.patch;

    const arrayToObject = arraysToObjects(data);

    this.siteService.addSubscriptionLog(this, 'account.service.ts->patch->this.account$');

    return this.account$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('account.service.ts->patch->this.account$')),
      first(),
      switchMap(account => {
        return this.http.patch<AccountPatchResponse>(url, { data: arrayToObject, requestedAt: account.requestedAt })
      }),
      map(res => {

      const data = objectsToArrays(res.data.account);

      this.accountSource.next(data);

      return res;

    }));

  }

}
