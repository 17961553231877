import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import { Service } from './classes/service';
import { ServiceProvider } from './classes/service-provider';
import { DynamicComponents } from './dynamic/services/dynamic.service';

@Pipe({
  name: 'serviceProviderServiceByServiceType'
})
export class ServiceProviderServiceByServiceTypePipe implements PipeTransform {

  transform(serviceProviders: ServiceProvider[], serviceType: DynamicComponents | string): Service[] {

    const services: Service[] = [];

    if (Array.isArray(serviceProviders)) {
      
      const serviceProvidersLength = serviceProviders.length;

      for (let i = 0; i < serviceProvidersLength; i++) {

        const serviceProviderServices = serviceProviders[i].form.get('services')?.value;

        if (serviceProviderServices && serviceProviderServices.length) {

          const serviceProviderServicesLength = serviceProviderServices.length

          for (let j = 0; j < serviceProviderServicesLength; j++) {

            if (serviceProviderServices[j].type.value.toLowerCase().includes(serviceType.toLowerCase())) {
              services.push(serviceProviderServices[j].service);
            }

          }

        }

      }

    }

    return services;

  }

}
