import { FormGroup } from "@angular/forms";
import { DynamicComponents } from "../dynamic/services/dynamic.service";
import { ServiceProvider } from "./service-provider";

import _ from "lodash";

export interface serviceProductCategory {
  _meta: { index: number; id: string; deleted?: boolean; },
  name: string;
  value: string;
  disabled: boolean;
};

export interface serviceGroupedProducts { [key: string]: any[] };

export class Service {

  private serviceWrapper: FormGroup;
  private serviceProvider: ServiceProvider;

  form: FormGroup;

  get _meta(): { id: string, index: number } | null {
    return this.serviceWrapper.get('_meta')?.value;
  };

  get type(): DynamicComponents | null {
    return this.serviceWrapper.get('type')?.value;
  }

  get serviceProviderName(): string {
    return this.serviceProvider.name;
  }

  get serviceProviderAddress(): any {
    return this.serviceProvider.address;
  }

  get serviceProviderObj(): any {
    return this.serviceProvider.obj;
  }

  get name(): string {

    const serviceNameControl = this.form.get('name');

    return (serviceNameControl) ? serviceNameControl.value : this.serviceProvider.name;

  }

  get address(): any {

    const serviceAddressControl = this.form.get('defaultAddress');

    return (serviceAddressControl) ? serviceAddressControl.value : this.serviceProvider.address;
    
  }

  get contact(): any {

    const serviceContactControl = this.form.get('contact');

    return (serviceContactControl) ? serviceContactControl.value : this.serviceProvider.contact;
    
  }

  get serviceProviderId(): number | null {
    return this.serviceProvider.id;
  }

  constructor(serviceProvider: ServiceProvider, serviceWrapper: FormGroup) {

    this.serviceProvider = serviceProvider;
    this.serviceWrapper = serviceWrapper;

    this.form = new FormGroup({});

  }

  public getProductCategories(): serviceProductCategory[] {

    const categories: any[] = _.get(this.form.value, 'productCategories');

    return categories;

  }

  public getGroupedProducts(): serviceGroupedProducts {

    const products: any[] = _.get(this.form.value, 'products');

    const grouped = _.groupBy(products, (product: any) => {

      return product.category.value;

    });

    return grouped;

  }

}
