import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'arrangementRegion'
})
export class ArrangementRegionPipe implements PipeTransform {

  transform(arrangements: any, regionInput?: string[]): any {

    if (!regionInput || regionInput.length === 0 || !Array.isArray(arrangements)) {

      return arrangements;

    }

    const response = arrangements.filter(arrangement => {
      
      const data = arrangement?.form?.value || arrangement.data;

      let region: string = _.get(data, 'deceased.placeOfPassing.address.state.value');

      if (region) {
        region = region.toLowerCase();
      } else {
        return true; // Always return true if region is not set
      }

      return _.find(regionInput, r => r === region) !== undefined;

    });

    return response;

  }

}
