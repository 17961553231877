<form [formGroup]="form" (ngSubmit)="onSubmit($event)">

  <div class="flex flex-column">

    <div class="content-container">

      <div class="formgrid grid">

        <div class="field col">
          <label for="input-username">Username:</label>
          <input id="input-username" formControlName="username" type="text" autocomplete="username" pInputText class="w-full" [ngClass]="{ 'ng-invalid ng-dirty' : username?.invalid && submitted }" />
        </div>
        
        <div class="field col">
          <label for="input-password">Password</label>
          <input id="input-password" formControlName="password" type="password" autocomplete="current-password" pInputText class="w-full" [ngClass]="{ 'ng-invalid ng-dirty' : password?.invalid && submitted }" />
        </div>

      </div>

    </div>

    <div class="button-actions mt-3">

      <button pButton pRipple type="button" label="Cancel" (click)="onClose($event)" class="p-button-secondary"></button>
      <button pButton pRipple type="submit" label="Submit"></button>
      
    </div>

  </div>

</form>