import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, SkipSelf, ViewChildren } from '@angular/core';
import { AbstractControl, ControlContainer, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailType } from 'src/app/shared/models/arrangement';
import { ModalService } from 'src/app/shared/services/modal.service';
import { emailTypesFormValue } from '../../form-values';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { UniqueId } from 'src/app/shared/uniqueId';
import { Arrangement } from 'src/app/shared/classes/arrangement';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class EmailComponent implements OnInit, AfterViewInit {

  @Input() controlName!: string;

  @ViewChildren('emailInput') emailInputs!: QueryList<ElementRef>;

  options = emailTypesFormValue;

  formRoot!: AbstractControl;
  formGroup!: FormArray;

  get isSubmittedControl(): AbstractControl | null {
    return this.formRoot.get('isSubmitted');
  }

  constructor(
    private controlContainer: ControlContainer,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {

    const control = this.controlContainer.control?.get(this.controlName) as FormArray;

    if (control) {

      this.formGroup = control;
      this.formRoot = this.formGroup.root;

      if (!this.formGroup.length) {

        this.add();

      }

    }

  }

  ngAfterViewInit(): void {

    this.emailInputs.changes.subscribe((emailInputs: QueryList<ElementRef>) => {
      (emailInputs.last.nativeElement as HTMLInputElement).focus();
    });

  }

  add() {

    const uniqueId = new UniqueId();

    let type = EmailType.Other;

    if (this.formGroup.length === 0) {

      type = EmailType.Primary;
      
    } else if (this.formGroup.length === 1) {

      type = EmailType.Secondary;

    }

    const typeIndex = _.findIndex(emailTypesFormValue, emailType => emailType.value === type);

    const email = this.formBuilder.group({
      _meta: this.formBuilder.group({
        id: [uniqueId.getUniqueInArray(this.formGroup.value, '_meta.id', 'id-')],
        index: [this.formGroup.length],
      }),
      email: ['', [Validators.email]],
      type: [emailTypesFormValue[typeIndex]]
    });

    this.formGroup.push(email);

    const arrangement = this.formRoot.get('_arrangement')?.value as Arrangement;

    if (arrangement) {

      arrangement.processMissingValidators();

    }

  }

  remove(index: number) {

    if (this.formGroup.length <= 1) {
      return;
    } else {

      const modal = this.modalService.generic({
        title: 'Confirm',
        copy: ['Are you sure you want to remove this email?'],
        buttons: [
          { label: 'Cancel', key: 'cancel', class: 'p-button-default' },
          { label: 'Confirm', key: 'confirm', class: 'p-button-danger', icon: 'pi-trash' },
        ]
      });

      modal.onClose.subscribe({
        next: key => {

          if (key === 'confirm') {

            this.formGroup.removeAt(index);
        
          }

        }
      });

    }

  }

}
