import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { UniqueId } from "../../uniqueId";

const testData = { 
  arrangementId: 1, 
  userId: 2, 
  data: {
    dateOfBirth: '1982-03-24',
    deceased: {
      name: {
        first: 'ivan',
        last: 'harpley',
      }
    },
    services: [
      {
        title: 'Service One',
        items: [
          {
            title: 'Item 1.1'
          },
          {
            title: 'Item 2.2'
          }
        ]
      },
      {
        title: 'Service Two',
        items: [
          {
            title: 'Item 2.1',
            tags: ['tag 1', 'tag 2', 'tag 3']
          },
          {
            title: 'Item 2.2'
          }
        ]
      }
    ]
  }
};

const arraysToObjects = function(arrayToObject: any) {

  let data = _.cloneDeep(arrayToObject);

  if (_.isPlainObject(data)) {

    for (const key in data) {

      if (data.hasOwnProperty(key)) {

        data[key] = arraysToObjects(data[key]);

      }

    }

    return data;

  } else if (_.isArray(data)) {

    const asObject: any = {};

    for (let index in data) {

      let id: string = '';

      const uniqueId = new UniqueId();

      do {

        id = 'id-' + uniqueId.get();

      } while(asObject[id]);

      if (!_.isPlainObject(data[index]) && !_.isArray(data[index])) {

        asObject[id] = {
          _meta: {
            id: id,
            index: +index,
            value: data[index]
          }
        };

      } else if (_.isPlainObject(data[index])) {

        if (!data[index]._meta) {

          data[index]._meta = {
            id: id,
            index: +index
          };

        } else {

          id = data[index]._meta.id;

          data[index]._meta.index = +index;

        }

        asObject[id] = arraysToObjects(data[index]);

      } else if (_.isArray(data[index])) {

        asObject[id] = arraysToObjects(data[index]);

      }

    }

    return asObject;

  } else {

    return data;

  }

};

export { arraysToObjects, testData };