import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './services/authentication.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const user = this.authenticationService.currentUserValue;
    const token = this.authenticationService.currentTokenValue;
    const isAuthenticated = user && token;
    const isApiUrl = req.url.startsWith(environment.api.host);

    if (isAuthenticated && isApiUrl) {
      req = req.clone({
        setHeaders: {
          authorization: 'Bearer ' + token
        }
      });
    }

    return next.handle(req).pipe(
      tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }
          this.authenticationService.deauthoriseUser();
        }
      })
    );
  }
}
