<image-cropper
    *ngIf="config.data?.imageBase64"
    [imageBase64]="config.data?.imageBase64"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="1"
    format="png"
    output="base64"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
></image-cropper>

<div class="text-center mt-3">
  <button (click)="onOk()" type="submit" pButton pRipple label="Upload"></button>
</div>