import { Directive } from '@angular/core';

@Directive({
  selector: '[appCompiler]'
})
export class CompilerDirective {

  constructor() { }

}
