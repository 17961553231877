import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import { placeAndStreetAreDifferent } from './place-and-street-are-different';

@Pipe({
  name: 'addressToString'
})
export class AddressToStringPipe implements PipeTransform {

  transform(value: any): string {
    
    const address: string[] = [];

    let place = '';
    let street = '';

    if (_.hasIn(value, 'place') && value.place) {
      place = value.place;
    }

    if (_.hasIn(value, 'street') && value.street) {
      street = value.street;
    }

    if (placeAndStreetAreDifferent(place, street) === true) {
      address.push(place);
      address.push(street);
    } else {
      address.push(street);
    }

    if (_.hasIn(value, 'suburb') && value.suburb) {
      address.push(value.suburb);
    }

    if (_.hasIn(value, 'state.value') && value.state.value) {
      address.push(value.state.value);
    }

    if (_.hasIn(value, 'postcode') && value.postcode) {
      address.push(value.postcode);
    }

    return (address.length) ? address.join(', ') : 'N/A';

  }

}
