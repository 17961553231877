import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompilerComponent } from './compiler.component';
import { CompilerModule as MainCompilerModule } from '../../features/compiler/compiler.module';

@NgModule({
  declarations: [
    CompilerComponent
  ],
  imports: [
    CommonModule,
    MainCompilerModule
  ],
  exports: [
    CompilerComponent
  ]
})
export class CompilerModule { }
