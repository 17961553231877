import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsRequiredDirective } from './is-required.directive';
import { ClearReadOnlyDirective } from './clear-read-only.directive';
import { PreventAutoCompleteDirective } from './prevent-auto-complete.directive';
import { SelectAllOnFocusDirective } from './select-all-on-focus.directive';
import { ShowHideDirective } from './show-hide.directive';



@NgModule({
  declarations: [
    IsRequiredDirective,
    ClearReadOnlyDirective,
    PreventAutoCompleteDirective,
    SelectAllOnFocusDirective,
    ShowHideDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IsRequiredDirective,
    ClearReadOnlyDirective,
    PreventAutoCompleteDirective,
    SelectAllOnFocusDirective,
    ShowHideDirective,
  ]
})
export class FormDirectivesModule { }
