import _ from 'lodash';
import { FinancialStructure, FormAddressStructure, FormStructure, FormStructureType, MetaStructure, NoteStructure } from '../forms/form-structure';

export class DynamicFormStructure {

  constructor() {}

  _getGenericProductStructure(): FormStructure {
    
    return _.clone({
      _meta: { type: FormStructureType.FormGroup, value: _.cloneDeep(MetaStructure) },
      name: { type: FormStructureType.FormControl },
      category: { type: FormStructureType.FormControl },
      description: { type: FormStructureType.FormControl },
      note: { type: FormStructureType.FormControl },
      invoiceLabel: { type: FormStructureType.FormControl },
      cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) },
      // userNotes: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) }
    });

  }

  _getGenericProductCategoryStructure(): FormStructure {
    
    return _.clone({
      _meta: { type: FormStructureType.FormGroup, value: _.cloneDeep(MetaStructure) },
      name: { type: FormStructureType.FormControl },
      value: { type: FormStructureType.FormControl },
      disabled: { type: FormStructureType.FormControl, value: false },
    });

  }

  getAudioVisualStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getBirthsDeathsAndMarriagesStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getBurialStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getCateringStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getCoffinStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getCremationStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getEventStaffStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getFlowerStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getMedicalCertificateStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getMiscellaneousStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getMortuaryStructure(): FormStructure {

    return _.clone({
      defaultAddress: {
        type: FormStructureType.FormGroup,
        value: _.cloneDeep(FormAddressStructure),
      },
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getMusicStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getNotificationStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getStationeryStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getTransportStructure(): FormStructure {

    return _.clone({
      defaultAddress: {
        type: FormStructureType.FormGroup,
        value: _.cloneDeep(FormAddressStructure),
      },
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getUrnStructure(): FormStructure {

    return _.clone({
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

  getVenueStructure(): FormStructure {

    return _.clone({
      defaultAddress: {
        type: FormStructureType.FormGroup,
        value: _.cloneDeep(FormAddressStructure),
      },
      productCategories: {
        type: FormStructureType.FormArray,
        value: this._getGenericProductCategoryStructure(),
      },
      products: { 
        type: FormStructureType.FormArray,
        value: this._getGenericProductStructure(),
      }
    });

  }

}