import { Component, OnDestroy, OnInit } from '@angular/core';

import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { Observable, Subject } from 'rxjs';
import { switchMap, first, tap, finalize, takeUntil } from 'rxjs/operators';

import { AccountService } from '../../services/account.service';
import { ModalService } from '../../services/modal.service';

import { Account } from '../../models/account';
import { SiteService } from '../../services/site.service';
import _ from 'lodash';

@Component({
  selector: 'app-account-firstcall-sourcegroup-list',
  templateUrl: './account-firstcall-sourcegroup-list.component.html',
  styleUrls: ['./account-firstcall-sourcegroup-list.component.scss']
})
export class AccountFirstCallSourceGroupListComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  account$: Observable<Account>;
  

  constructor(
    private siteService: SiteService,
    private modalService: ModalService,
    private accountService: AccountService,
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) {

    this.account$ = this.accountService.account$;

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
      
  }

  onEditSource(event: Event, group: any): void {

    const sourceGroupName = {
      input: group.name,
    }

    const modal = this.modalService.inputTextInput({
      title: 'First Call Source Group',
      buttons: [
        { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
        { label: 'Save', key: 'save', class: '' },
      ],
      data: sourceGroupName,
    });

    modal.onClose.subscribe({
      next: (reason: string) => {
        if (reason && reason.toString().toLowerCase() === 'save') {
          group.name = sourceGroupName.input;
          this.updateAccountData();
        }
      }
    });
  }

  onDeleteSourceGroup(group: any): void {
    this.accountService.account$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('account-arrangement-source-list.component.ts->updateAccountData->this.accountService.account$')),
      takeUntil(this.unsubscribe$),
      first(account => account !== undefined)
    ).subscribe({
      next: (account) => {
        const sourceGroupIndex = _.findIndex(account.data.firstCall.sourceGroup, (g: any) => g._meta.id === group._meta.id);

        if (sourceGroupIndex >= 0) {

          if (account.data.firstCall.sourceGroup.length > 1) {
            account.data.firstCall.sourceGroup.splice(sourceGroupIndex, 1);
          } else {
            account.data.firstCall.sourceGroup = null;
          }
          
        }
        this.accountService.patch(account.data).subscribe({
          next: (res) => {
            //console.log(res.data);
          }
        });
      }
    });
  }

  onDeleteSource(event: Event, group: any): void {
    
    const modal = this.modalService.generic({
      title: 'Remove Item',
      copy: ['Are you sure you want to remove this source?'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: '' },
        { label: 'Confirm', key: 'confirm', class: 'p-button-danger', icon: 'pi pi-trash' },
      ]
    });

    modal.onClose.subscribe({
      next: (reason: string) => {

        if (reason.toString().toLowerCase() === 'confirm') {
          
          this.onDeleteSourceGroup(group);
          // this.updateAccountData();

        }

      }
    });

  }

  private updateAccountData(): void {

    this.siteService.addSubscriptionLog(this, 'account-arrangement-source-list.component.ts->updateAccountData->this.accountService.account$');

    this.accountService.account$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('account-arrangement-source-list.component.ts->updateAccountData->this.accountService.account$')),
      takeUntil(this.unsubscribe$),
      first(),
      switchMap(account => this.accountService.patch(account.data)),
    ).subscribe({
      next: res => {
        // console.log(res.data);
      }
    });

  }

}
