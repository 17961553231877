<div *ngIf="formReady | async" class="formgrid grid">
  
  <div class="col-12 flex">
    
    <p-fileUpload #fileUploadElement chooseLabel="Import CSV" mode="basic" [customUpload]="true" accept="text/csv" [maxFileSize]="1000000" (uploadHandler)="upload($event)" [auto]="true"></p-fileUpload>
    <button class="ml-3" type="button" label="Export CSV" pButton pRipple icon="pi pi-file-o" (click)="onExportCSV($event)" pTooltip="CSV" tooltipPosition="bottom"></button>

  </div>
  
  <div class="col-12">
    <p-divider></p-divider>
  </div>

  <div [formGroup]="service.form" class="col-12">

    <app-address-with-heading 
      headingText="Default Delivery Address" 
      addressControlName="defaultAddress"
    ></app-address-with-heading>

  </div>

  <div class="col-12">

    <p-table #table *ngIf="(products | async) as p" [value]="p" dataKey="_meta.id" editMode="row" responsiveLayout="true">
      
      <ng-template pTemplate="emptymessage">

        <tr>

          <td colspan="4">

            <p-card class="card-info w-full">
              <p class="text-center"><strong>No Products Created:</strong> Use the button below to add your first product.</p>
              <p class="text-center mt-3"><button pButton pRipple (click)="onAddProduct($event, 0)" type="button" label="Add your first product item" icon="pi pi-plus" class="p-button-rounded"></button></p>
            </p-card>

          </td>

        </tr>

      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="product-name">Name</th>
          <th class="product-category">Category</th>
          <th class="product-cost">Cost</th>
          <th class="product-actions"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-editing="editing" let-rIndex="rowIndex">
        <tr [pEditableRow]="product">
          <td class="product-name">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="product.name" class="w-full" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ product.name }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="product-category">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <div class="flex" *ngIf="(categories | async) as c">
                  <p-dropdown class="flex-auto" [options]="c | sort:'asc':'name'" [(ngModel)]="product.category" placeholder="Select a Category" optionLabel="name" optionValue="value" [showClear]="false" class="w-full"></p-dropdown>
                  <p-button (click)="onDisplayModifyCategoriesMenu($event, modifyCategoriesMenu, product)" class="flex-initial" icon="pi pi-cog"></p-button>
                  <p-tieredMenu #modifyCategoriesMenu [popup]="true" [model]="modifyCategories" appendTo="body"></p-tieredMenu>
                </div>
              </ng-template>
              <ng-template pTemplate="output">
                {{ product.category.name }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="product-cost">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber [(ngModel)]="product.cost.amount" inputId="currency-us" mode="currency" currency="USD" locale="en-US"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ product.cost.amount | currency:'AUD':'$' }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="product-actions">
            <button *ngIf="!editing" pButton pRipple type="button" (click)="onRowEditInit(product)" pInitEditableRow icon="pi pi-pencil" class="p-button-rounded p-button-text"></button>
            <button *ngIf="!editing" pButton pRipple type="button" (click)="onAddProduct($event, rIndex + 1)" icon="pi pi-plus" class="p-button-rounded p-button-text"></button>
            <button *ngIf="!editing" pButton pRipple type="button" (click)="onBespokeProductPricingToggle($event, product, rIndex)" [icon]="(product.cost.bespoke) ? 'pi pi-unlock' : 'pi pi-lock'" class="p-button-rounded p-button-text"></button>
            <button *ngIf="!editing" pButton pRipple type="button" (click)="onDeleteProduct($event, product, rIndex)" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger"></button>
            <button *ngIf="!editing" pButton pRipple type="button" (click)="onDisplayAdditionalOperationsMenu($event, additionalOperationsMenu, rIndex)" icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text"></button>
            <p-menu #additionalOperationsMenu [popup]="true" [model]="additionalOperations" appendTo="body"></p-menu>
            <button *ngIf="editing" pButton pRipple type="button" (click)="onRowEditSave(product, rIndex)" pSaveEditableRow icon="pi pi-check" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
            <button *ngIf="editing" pButton pRipple type="button" (click)="onRowEditCancel(product, rIndex)" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>

</div>