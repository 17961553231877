import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import _ from 'lodash';
import { TaskStatus } from './models/arrangement';
import { taskStatusType } from './forms/form-enum';

export const taskFilterFn = (tasks: FormGroup[], type: string, form: FormGroup): TaskPipeResponse[] => {

  const visibility: TaskPipeResponse[] = [];

  const formValue = form.value;

  const showAllStatus = _.get(formValue, 'allStatus', false);

  const showComplete = _.get(formValue, 'complete', false);
  const showInProgress = _.get(formValue, 'inProgress', false);
  const showNotStarted = _.get(formValue, 'notStarted', false);
  const showNotRequired = _.get(formValue, 'notRequired', false);
  const showPaused = _.get(formValue, 'paused', false);
  const showNearingOverDue = _.get(formValue, 'nearingOverDue', false);
  const showOverDue = _.get(formValue, 'overDue', false);

  const showFirstCall = _.get(formValue, 'firstCall', false);
  const showBookArrangementMeeting = _.get(formValue, 'bookArrangementMeeting', false);
  const showContacts = _.get(formValue, 'contacts', false);
  const showEventDetails = _.get(formValue, 'eventDetails', false);
  const showPreparationDetails = _.get(formValue, 'preparationDetails', false);
  const showDisposalDetails = _.get(formValue, 'disposalDetails', false);
  const showBDM = _.get(formValue, 'bdm', false);
  const showStatutoryDeclaration = _.get(formValue, 'statutoryDeclaration', false);
  const showNewspaperNotifications = _.get(formValue, 'newspaperNotifications', false);
  const showCostSummary = _.get(formValue, 'costSummary', false);
  const showDocumentLibrary = _.get(formValue, 'documentLibrary', false);

  tasks.forEach((task, index) => {

    const taskValue = task.value;

    const tasksStatus = _.get(taskValue, 'status.value', false);

    const isOverDue = _.get(taskValue, 'overDue', false);
    const isNearingOverDue = _.get(taskValue, 'nearingOverDue', false);
    const isComplete = (tasksStatus === taskStatusType.Completed);
    const isInProgress = (tasksStatus === taskStatusType.InProgress);
    const isNotStarted = (tasksStatus === taskStatusType.NotStarted);
    const isNotRequired = (tasksStatus === taskStatusType.NotRequired);
    const isPaused = (tasksStatus === taskStatusType.Paused);

    visibility.push({ index, visible: false });

    if (showAllStatus) {

      visibility[index].visible = true;

    } else {
      
      if (showComplete && isComplete) {

        visibility[index].visible = true;

      }
      
      if (showInProgress && isInProgress) {

        visibility[index].visible = true;

      }
      
      if (showNotStarted && isNotStarted) {

        visibility[index].visible = true;

      }
      
      if (showNotRequired && isNotRequired) {

        visibility[index].visible = true;

      }
      
      if (showPaused && isPaused) {

        visibility[index].visible = true;

      }
      
      if (showNearingOverDue && isNearingOverDue) {

        visibility[index].visible = true;

      }
      
      if (showOverDue && isOverDue) {

        visibility[index].visible = true;

      }

    }

  });

  if (showFirstCall && type === 'firstCall') {

    return visibility;

  }

  if (showBookArrangementMeeting && type === 'bookArrangementMeeting') {

    return visibility;

  }

  if (showContacts && type === 'contacts') {

    return visibility;

  }

  if (showEventDetails && type === 'eventDetails') {

    return visibility;

  }

  if (showPreparationDetails && type === 'preparationDetails') {

    return visibility;

  }

  if (showDisposalDetails && type === 'disposalDetails') {

    return visibility;

  }

  if (showBDM && type === 'bdm') {

    return visibility;

  }

  if (showStatutoryDeclaration && type === 'statutoryDeclaration') {

    return visibility;

  }

  if (showNewspaperNotifications && type === 'newspaperNotifications') {

    return visibility;

  }

  if (showCostSummary && type === 'costSummary') {

    return visibility;

  }

  if (showDocumentLibrary && type === 'documentLibrary') {

    return visibility;

  }

  return [];

};

export interface TaskPipeResponse {
  index: number;
  visible: boolean;
}

@Pipe({
  name: 'task'
})
export class TaskPipe implements PipeTransform {

  transform(tasks: FormGroup[], type: string, form: FormGroup): TaskPipeResponse[] {

    return taskFilterFn(tasks, type, form);

  }

}
