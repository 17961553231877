import { Pipe, PipeTransform } from '@angular/core';
import { Arrangement } from './classes/arrangement';
import { User } from './classes/user';

@Pipe({
  name: 'arrangementOwner'
})
export class ArrangementOwnerPipe implements PipeTransform {

  transform(arrangements: Arrangement[], userId: number, owner: string): any {

    if (!userId || !Array.isArray(arrangements)) {

      return arrangements;

    }

    if (owner === 'arranged-by-anyone') {

      return arrangements;

    } else {

      const filtered = arrangements.filter(arrangement => {
        
        const arranger: User = arrangement.form.get('arrangementMeeting.arranger')?.value;

        if (arranger) {

          if (arranger.data.id === userId) {

            return true;

          }

        }

        return false;

      });

      return filtered;

    }

  }

}
