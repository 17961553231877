import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { finalize, first, takeUntil, tap } from 'rxjs/operators';
import { User } from 'src/app/shared/classes/user';
import { RegionRole } from 'src/app/shared/models/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PasswordMatcher } from 'src/app/shared/validators/confirmPassword';
import { minLengthArray } from 'src/app/shared/validators/minArrayLength';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit,OnDestroy {

  private unsubscribe$ = new Subject<void>();

  @Input() isModal:boolean = false;

  registerForm = this.formBuild.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    passwordConfirm: ['', Validators.required],
    firstName:['', Validators.required],
    lastName:['', Validators.required],
    phone:[''],
    roles:[[], minLengthArray(1)],
    regions:[[], minLengthArray(1)],
    avatar:[''],
    serviceProviderId:[''],
    emailSignature:[''],
  }, { 
    validators: [PasswordMatcher.match('password', 'passwordConfirm')]
  });

  roles:RegionRole[]=[];
  regions:RegionRole[]=[];
  subscriptions: Subscription[]=[];
  userId:string | null;
  user$: Observable<User> = this.userService.user$;
  roles$: Observable<RegionRole[]> = this.userService.roles$;
  regions$: Observable<RegionRole[]> = this.userService.regions$;

  currentUserSource: BehaviorSubject<User | null>;
  currentUser$!: Observable<User | null>;

  isProcessingUser: boolean;
  isProcessingUserEnabled: boolean;
  isProcessingResetPassword: boolean;

  constructor(
    private siteService: SiteService,
    private formBuild: FormBuilder,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
  ) {

    this.isProcessingUser = false;
    this.isProcessingUserEnabled = false;
    this.isProcessingResetPassword = false;
    
    this.userId = this.activatedRoute.snapshot.paramMap.get('id');
    
    this.currentUserSource = new BehaviorSubject<User | null>(null);
    this.currentUser$ = this.currentUserSource.asObservable();

    if (this.userId) {

      this.registerForm.controls.password.setValidators(null);
      this.registerForm.controls.passwordConfirm.setValidators(null);

      this.registerForm.controls.password.updateValueAndValidity();
      this.registerForm.controls.passwordConfirm.updateValueAndValidity();

      this.userService.allUsers$.subscribe((users:User[]) => {
        
        const user = users.find((user: User) => user.data.id.toString() === this.userId);

        if (user) {

          this.currentUserSource.next(user);

          this.registerForm.patchValue(user.data);

        }

      });
      
    } else {

      this.currentUserSource.next(this.userService.userSourceValue);

    }

  }

  ngOnInit(): void { 

    if(this.isModal){
    
      this.userService.user$.subscribe((user)=>{
        this.userId=user.data.id.toString();
        this.registerForm.patchValue(user.data as any);
      });
    
      ['roles','regions'].map((item)=>{
        this.registerForm.get(item)?.clearValidators();
        this.registerForm.get(item)?.updateValueAndValidity();
      });

    }

  }

  onToggleUserIsEnabled(event: Event, user: User): void {

    const processRequest = () => {

      this.isProcessingUserEnabled = true;

      user.toggleEnableStatus().subscribe({
        next: () => {
  
          this.modalService.generic({
            title: 'Status Updated',
            copy: ['You successfully updated the status of the user.'],
            buttons: [
              { label: 'Close', key: 'close', class: '' },
            ],
          });
  
          this.isProcessingUserEnabled = false;
  
        },
        error: (err) => {
  
          this.modalService.error(err.error.error);
          this.isProcessingUserEnabled = false;
  
        }
      });

    };

    const modal = this.modalService.generic({
      title: 'Confirm Request',
      copy: ['Are you sure you want to change the user\'s status?'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
        { label: 'Confirm', key: 'confirm', class: '' },
      ]
    });

    modal.onClose.subscribe({
      next: key => {
        if (key === 'confirm') {
          processRequest();
        }
      }
    });


  }

  onResetPassword(event: Event, user: User): void {

    const processRequest = () => {

      this.isProcessingResetPassword = true;
      
      this.authenticationService.forgotPassword(user.data.email).subscribe({
        next: (res) => {

          this.modalService.generic({
            title: 'Reset Successful',
            copy: ['Instructions to change the user\'s password have successfully been sent.'],
            buttons: [
              { label: 'Close', key: 'close', class: '' },
            ],
          });

          this.isProcessingResetPassword = false;

        },
        error: (err) => {

          this.modalService.error(err.error.error);
          this.isProcessingResetPassword = false;

        }
      });

    };

    const modal = this.modalService.generic({
      title: 'Confirm Request',
      copy: ['Are you sure you want to reset the user\'s password?'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
        { label: 'Confirm', key: 'confirm', class: '' },
      ]
    });

    modal.onClose.subscribe({
      next: key => {
        if (key === 'confirm') {
          processRequest();
        }
      }
    });

  }

  onFormSubmit(event: Event) {

    if (this.userId) {
      this.updateUser();
    } else {
      this.registerUser();
    }

  }

  generatePassword(): void {

    const value='abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPWRSTUVWXYZ0123456789!@#$%^&*-_=+\\|:;.\<>/?~'
    
    let newPassword = "";
    
    for (var i = 0; i < 12; i++) {
      newPassword += value[Math.floor(Math.random() * value.length)];
    }
    
    this.registerForm.controls.password.setValue(newPassword);
    this.registerForm.controls.passwordConfirm.setValue(newPassword);

  }

  ngOnDestroy(): void {
    
    this.subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });

    this.unsubscribe$.next();
    this.unsubscribe$.complete();

  }

  private registerUser(): void {

    this.isProcessingUser = true;

    const rxjsOptions = {
      next: (res:any) => {

        this.modalService.generic({
          title: 'User Creation Successful',
          copy: [`You successfully created a new account.`],
          buttons: [
            { label: 'Close', key: 'close', class: '' },
          ],
        });

        if(!this.userId) {
          this.registerForm.reset();
        }

        this.isProcessingUser = false;

      },
      error: (err:any) => {

        this.modalService.error(err.error.error);

        this.isProcessingUser = false;

      },
    };

    this.authenticationService.register(this.registerForm.value).subscribe(rxjsOptions);

  }

  private updateUser(): void {

    const processRequest = () => {

      this.isProcessingUser = true;

      const rxjsOptions = {
        next: (res:any) => {
          if(this.isModal){
            window.location.reload();
          }
          this.modalService.generic({
            title: 'Update Successful',
            copy: [`You've successfully updated the account.`],
            buttons: [
              { label: 'Close', key: 'close', class: '' },
            ],
          });

          this.isProcessingUser = false;

        },
        error: (err:any) => {

          this.modalService.error(err.error.error);

          this.isProcessingUser = false;

        },
      };

      this.siteService.addSubscriptionLog(this, 'register.component.ts->updateUser->processRequest->this.currentUser$');
      
      this.currentUser$.pipe(
        finalize(() => this.siteService.setSubscriptionLogFinalised('register.component.ts->updateUser->processRequest->this.currentUser$')),
        takeUntil(this.unsubscribe$),
        first()
      ).subscribe({
        next: user => {

          if (user) {

            const data = this.registerForm.value;

            user.update(data).subscribe(rxjsOptions);

          }
          
        }
      });

    };

    const modal = this.modalService.generic({
      title: 'Confirm Request',
      copy: ['Are you sure you want to modify the user\'s details?'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
        { label: 'Confirm', key: 'confirm', class: '' },
      ]
    });

    modal.onClose.subscribe({
      next: key => {
        if (key === 'confirm') {
          processRequest();
        }
      }
    });

  }

}
