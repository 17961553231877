import { Injectable } from '@angular/core';
import { Subscription, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class UserStatusGQLService extends Subscription {
  document = gql`
    subscription onNotifyUserStatus($usernames: [String]!) {
      onNotifyUserStatus(
        usernames: $usernames
      ) {
        status
        username
      }
    }
  `;
}