import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'arrangementStatus'
})
export class ArrangementStatusPipe implements PipeTransform {

  transform(arrangements: any, statusInput?: string[]): any {

    if (!statusInput || statusInput.length === 0 || !Array.isArray(arrangements)) {

      return arrangements;

    }

    const response = arrangements.filter(arrangement => {
      
      const data = arrangement?.form?.value || arrangement.data;

      let status: string = _.get(data, 'status.value');

      if (status) {
        status = status.toLowerCase();
      } 

      return _.find(statusInput, s => s === status) !== undefined;

    });

    return response;

  }

}
