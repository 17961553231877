import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import { Arrangement } from './classes/arrangement';
import { ArrangementType } from './models/arrangement';

@Pipe({
  name: 'clientTypeName',
  pure: false,
})
export class ClientTypeNamePipe implements PipeTransform {
  transform(text?: string, arrangement?: Arrangement): any {
    const arrangementType = _.get(arrangement, 'form.value.type.value');

    if (arrangementType === ArrangementType.PrePaid) {
      return text
        ?.replace(/Deceased/g, "Prepaid Client")
        ?.replace(/deceased/g, "prepaid client");
    }

    if (arrangementType !== ArrangementType.PrePaid) {
      return text
        ?.replace(/Prepaid Client/g, "Deceased")
        ?.replace(/prepaid client/g, "deceased");
    }

    return text;
  }
}
