import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoginForm } from '../models/authentication/login-form';
import { User } from '../models/user';
import { LoginResponse } from '../models/authentication/login-response';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { SiteService } from './site.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  
  
  hasAuthenticated: boolean;

  user!: Observable<User>;
  token!: Observable<string>;
  isAuthenticated!: Observable<boolean | null>;
  authenticationChecked!: Observable<boolean>;
  
  private tokenSource!: BehaviorSubject<string>;
  private userSource!: BehaviorSubject<User>;
  private isAuthenticatedSource!: BehaviorSubject<boolean | null>;
  private authenticationCheckedSource!: BehaviorSubject<boolean>;

  constructor(
    private siteService: SiteService,
    private http: HttpClient,
    private router: Router,
  ) {

    this.hasAuthenticated = false;

    const localUser = localStorage.getItem('user');
    const localToken = localStorage.getItem('token');

    if (localUser) {
      
      this.userSource = new BehaviorSubject<User>(JSON.parse(localUser));
      this.user = this.userSource.asObservable();
      
    }
    
    if (localToken) {

      this.tokenSource = new BehaviorSubject<string>(localToken);
      this.token = this.tokenSource.asObservable();

    }

    this.authenticationCheckedSource = new BehaviorSubject<boolean>(false);
    this.authenticationChecked = this.authenticationCheckedSource.asObservable();

    this.isAuthenticatedSource = new BehaviorSubject<boolean | null>(null);
    this.isAuthenticated = this.isAuthenticatedSource.asObservable();

    // if (localUser && localToken) {

    //   this.isAuthenticatedSource.next(true);

    // }

  }

  public get currentUserValue(): User | null {
    return (this.userSource) ? this.userSource.value : null;
  }

  public get currentTokenValue(): string | null {
    return (this.tokenSource) ? this.tokenSource.value : null;
  }

  public get isAuthenticatedValue(): boolean | null {
    return (this.isAuthenticatedSource) ? this.isAuthenticatedSource.value : false;
  }

  authoriseUser(user: User, token: string) {

    const decoded = jwt_decode.jwtDecode(token) as any;

    user.uId = decoded.uId;

    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);

    if (!this.userSource) {

      this.userSource = new BehaviorSubject(user);
      this.user = this.userSource.asObservable();

    } else {

      this.userSource.next(user);

    }

    if (!this.tokenSource) {

      this.tokenSource = new BehaviorSubject<string>(token);
      this.token = this.tokenSource.asObservable();

    } else {

      this.tokenSource.next(token);

    }

    this.isAuthenticatedSource.next(true);

  }

  deauthoriseUser(resetRouting = true) {

    localStorage.removeItem('user');
    localStorage.removeItem('token');

    if (this.userSource) {

      this.userSource.next({} as User);

    }

    if (this.tokenSource) {

      this.tokenSource.next('');

    }

    if (this.isAuthenticatedSource) {

      this.isAuthenticatedSource.next(false);
      
    }

    if (resetRouting) {

      this.router.navigate(['/'], { replaceUrl: true })

    }

  }

  login(data: LoginForm): Observable<LoginResponse> {

    const url = environment.api.host + environment.api.paths.api.auth.login;

    this.siteService.addSubscriptionLog(this, 'authentication.service.ts->login->this.http.post<LoginResponse>(url, data)');

    return this.http.post<LoginResponse>(url, data).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('authentication.service.ts->login->this.http.post<LoginResponse>(url, data)')),
      map(res => {
        this.authenticationCheckedSource.next(true);
        this.hasAuthenticated = true;
        return res;
      })
    );

  }

  register(value: User): Observable<LoginResponse> {

    const url = environment.api.host + environment.api.paths.api.auth.register;

    this.siteService.addSubscriptionLog(this, 'authentication.service.ts->register->this.http.post<LoginResponse>(url, value)');

    return this.http.post<LoginResponse>(url, value).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('authentication.service.ts->register->this.http.post<LoginResponse>(url, value)')),
      map(res => {
        this.authenticationCheckedSource.next(true);
        this.hasAuthenticated = true;
        return res;
      })
    );

  }

  forgotPassword(email: string) {
    const url = environment.api.host + environment.api.paths.api.auth.forgottenPassword;

    return this.http.post(url, {email});
  }

  verifyAuthenticationToken(): Observable<LoginResponse> {

    const url = environment.api.host + environment.api.paths.api.user.isAuthenticated;

    this.siteService.addSubscriptionLog(this, 'authentication.service.ts->verifyAuthenticationToken->this.http.get<LoginResponse>(url)');

    return this.http.get<LoginResponse>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('authentication.service.ts->verifyAuthenticationToken->this.http.get<LoginResponse>(url)')),
      map((res: any) => {

        this.authenticationCheckedSource.next(true);
        
        if (res.error) {
          throw res.error;
        } else {
          this.hasAuthenticated = true;
          this.authoriseUser(res.data.user, res.data.token);
          return res;
        }

      }),catchError(err => {

        this.authenticationCheckedSource.next(true);

        this.deauthoriseUser(false);

        throw err;

      })
    );

  }

  resetPassword(token: string | null, data:{}): Observable<any> {
    
    const url = environment.api.host + (token ? environment.api.paths.api.auth.resetPassword: environment.api.paths.api.user.changePassword);

    this.siteService.addSubscriptionLog(this, 'authentication.service.ts->resetPassword->this.http.post<any>(url, { token, ...data })');

    return this.http.post<any>(url, { token, ...data }).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('authentication.service.ts->resetPassword->this.http.post<any>(url, { token, ...data })')),
      map(res => res)
    );

  }

}
