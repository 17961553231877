import { AbstractControl, ValidatorFn } from "@angular/forms";

const minLengthArray = (minLength: number): ValidatorFn => {

  return (control: AbstractControl) => {

    const value = control.value;

    if (Array.isArray(value) && value.length >= minLength) {
      return null;
    } else {
      return { minLengthArray: true };
    }

  };

};

export { minLengthArray };