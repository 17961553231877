import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { PipeModule } from '../../pipe.module';
import { SavePromptComponent } from './save-prompt.component';



@NgModule({
  declarations: [SavePromptComponent],
  imports: [
    CommonModule,
    RippleModule,
    ButtonModule,
    TooltipModule,
    PipeModule,
  ],
  exports: [SavePromptComponent],
})
export class SavePromptModule { }
