import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';
import { GenericServiceProvider } from '../../classes/generic-service-provider';
import { DynamicFormStructure } from '../../dynamic-form-structure';
import { SiteService } from 'src/app/shared/services/site.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends GenericServiceProvider implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    _siteService: SiteService,
    _modalService: ModalService,
  ) {

    super(_siteService, _modalService);

  }

  ngOnInit(): void {

    const dynamicFormStructure = new DynamicFormStructure();

    super.ngOnInit(dynamicFormStructure.getNotificationStructure());

  }

  ngAfterViewInit(): void {
    
    super.ngAfterViewInit();

  }

  ngOnDestroy(): void {
    
    super.ngOnDestroy();

  }

}