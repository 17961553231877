import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { hospitalOrNursingHomeValue } from '../../forms/form-values';
import { FormAddressStructure, FormStructureType, FormStructureValueType } from '../../forms/form-structure';
import { FormGenerator } from '../../forms/form-generator';
import _ from 'lodash';

@Component({
  selector: 'app-account-hospital-and-nursing-home',
  templateUrl: './account-hospital-and-nursing-home.component.html',
  styleUrls: ['./account-hospital-and-nursing-home.component.scss']
})
export class AccountHospitalAndNursingHomeComponent implements OnInit {

  form: FormGroup;
  formValue!: {
    type: { name: string, value: string };
    name: string;
    address: {
      street: string;
      suburb: string;
      state: { name: string, value: string };
      postcode: string;
    }
  };

  isReady: boolean;

  types = hospitalOrNursingHomeValue;

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) {

    this.isReady = false;

    this.form = this.config.data.data;

    if (!this.form.get('type')?.value) {

      this.form.get('type')?.setValue(hospitalOrNursingHomeValue[0]);

    }

    // const formGenerator = new FormGenerator();

    // this.formValue = _.get(this.config.data.data, 'value');

    // if (_.has(this.formValue, 'type.value')) {

    //   const typeOptionValue = _.find(hospitalOrNursingHomeValue, option => option.value === this.formValue.type.value)
  
    //   _.set(this.formValue, 'type', typeOptionValue);
      
    // } else {
      
    //   _.set(this.formValue, 'type', hospitalOrNursingHomeValue[0]);

    // }

    // this.form = formGenerator.generate({
    //   type: { 
    //     type: FormStructureType.FormControl 
    //   },
    //   name: { 
    //     type: FormStructureType.FormControl 
    //   },
    //   address: {
    //     type: FormStructureType.FormGroup, 
    //     value: _.cloneDeep(FormAddressStructure), 
    //     valueAs: FormStructureValueType.Address 
    //   },
    // }, this.formValue);

  }

  ngOnInit(): void {

    this.isReady = true;

  }

}
