import { Pipe, PipeTransform } from '@angular/core';
import { ArrangementDocument } from './models/arrangement';

@Pipe({
  name: 'filterDocuments',
})
export class FilterDocumentsPipe implements PipeTransform {
  transform(
    documents: ArrangementDocument[] | null,
    search: string
  ): ArrangementDocument[] {
    if (!documents) {
      return [];
    }

    const filteredDocuments = documents.filter(
      (document) =>
        document._file &&
        document._file.name.toLowerCase().includes(search.toLowerCase())
    );

    return filteredDocuments;
  }
}
