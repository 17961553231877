import _ from 'lodash';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UnreadMessageCount, UnreadMessageCountResponse, User as UserInterface, AppSyncMessage, UserMessageResponse, UserStatus, UserMessage, AppSyncMessageResponse, AppSyncNotification } from '../models/user';
import { easyFormatter } from '../easyFormatter';
import { HttpClient } from '@angular/common/http';
import { debounceTime, finalize, map, tap } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { SiteService } from '../services/site.service';

export class User {

  data: UserInterface;
  
  isAuthenticatedUser: boolean;
  
  status$!: Observable<any>;
  message$!: Observable<any>;
  notification$!: Observable<any>;

  private statusSource!: BehaviorSubject<any>;
  private messageSource!: BehaviorSubject<any>;
  private notificationSource!: BehaviorSubject<any>;

  private messages: UserMessage[] = [];

  constructor(
    private http: HttpClient,
    private siteService: SiteService,
    user: UserInterface
  ) {

    this.data = user;
    this.isAuthenticatedUser = false;

  }

  public toString = () : string => {
    return this.data.toString();
  }

  get name(): string {
    return this.data.firstName + ' ' + this.data.lastName;
  }

  get thumb(): string {
    return this.data._avatarFile?.link as string;
  }

  get status(): UserStatus {
    return this.statusSource.value;
  }

  set status(newStatus: UserStatus) {
    this.statusSource.next(newStatus);
  }

  hasRole(id: number): boolean {

    if (Array.isArray(this.data.roles)) {

      const index = this.data.roles.findIndex((i) => i === id)
  
      return index > -1;

    } else {

      return false;

    }

  }

  isDeveloper(): boolean {

    return this.hasRole(1);

  }

  isOfficeAdministrator(): boolean {

    return this.hasRole(1) || this.hasRole(6);

  }

  isFinanceAdministrator(): boolean {

    return this.hasRole(1) || this.hasRole(7);

  }

  /**
   * Add message to message display
   * @param message - The message object
   * @returns void if message is null
   */
  addMessage(message: UserMessage) {

    if (!message) {
      
      return;

    }

    if (this.isAuthenticatedUser) {

      this.messageSource.next(message);

    } else {

      this.messages.push(message);

      this.messageSource.next(this.messages);

    }
    
  }

  /**
   * Create BehaviorSubject so we can subscribe to user status updates
   * @param observable - The GraphQL UserStatus Subscription observable
   */
  setStatusObservable(observable: Observable<any>) {

    this.statusSource = new BehaviorSubject<UserStatus>(this.data.status || UserStatus.Offline);
    this.status$ = this.statusSource.asObservable();

    observable.subscribe({
      next: (res) => {
        const newStatus = _.get(res, 'data.onNotifyUserStatus.status', UserStatus.Offline);
        this.statusSource.next(newStatus);
      }
    });

  }

  /**
   * Create BehaviorSubject so we can subscribe to user messages
   * @param observable - The GraphQL NotifyMessageDirect Subscription observable
   */
  setMessageObservable(observable: Observable<any>) {

    this.messageSource = new BehaviorSubject<AppSyncMessage | null>(null);
    this.message$ = this.messageSource.asObservable();

    observable.subscribe({
      next: (res: AppSyncMessageResponse) => {
        const message: AppSyncMessage | null = _.get(res, 'data.onNotifyMessageDirect', null);

        if (message) {
          const displayMessage = this.appSyncResponseToDisplayMessage(message);
          this.addMessage(displayMessage);
        }

      }
    });

  }

  /**
   * Create BehaviorSubject so we can subscribe to user notifications
   * @param observable - The GraphQL NotifyMessageDirect Subscription observable
   */
  setNotificationObservable(observable: Observable<any>) {

    this.notificationSource = new BehaviorSubject<AppSyncNotification | null>(null);
    this.notification$ = this.notificationSource.asObservable();

    observable.subscribe({
      next: (res: any) => {
        const notification = _.get(res, 'data.onNotifyNotification', null);
        this.notificationSource.next(notification);
      }
    });

  }

  /**
   * Do remote request to get user's messages from the DB
   */
  getMessages(): Observable<UserMessage[]> {

    let url = environment.api.host + easyFormatter(environment.api.paths.api.user.getMessagesById, { userId: '' + this.data.id });

    this.siteService.addSubscriptionLog(this, 'user.ts->getMessages->this.http.get<UserMessageResponse>(url)');

    return this.http.get<UserMessageResponse>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('user.ts->getMessages->this.http.get<UserMessageResponse>(url)')),
      map((res: UserMessageResponse) => {

        this.messages = [];

        res.data.forEach(message => this.addMessage(message));

        return res.data;

      })
    );

  }

  getUnreadMessageCount(): Observable<UnreadMessageCount> {

    let url = environment.api.host + environment.api.paths.api.user.getUnreadMessageCount;
    
    this.siteService.addSubscriptionLog(this, 'user.ts->getUnreadMessageCount->this.http.get<UnreadMessageCountResponse>(url)');

    return this.http.get<UnreadMessageCountResponse>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('user.ts->getUnreadMessageCount->this.http.get<UnreadMessageCountResponse>(url)')),
      map((res: UnreadMessageCountResponse) => {

        this.siteService.updateUnreadMessageCount(res.data);

        return res.data;

      })
    );

  }

  updateUnreadCount(message: UserMessage) {

    if (!message || message.readAt) {
      return;
    }

    const key = `u_${message.data.sender.id}`;

    const currentUnreadMessageCount: UnreadMessageCount = this.siteService.getUnreadMessageCount();

    if (!currentUnreadMessageCount.counts[key]) {

      currentUnreadMessageCount.counts[key] = {
        userId: message.data.sender.id,
        count: 0
      };

    }

    currentUnreadMessageCount.totalCount++;
    currentUnreadMessageCount.counts[key].count++;

    this.siteService.updateUnreadMessageCount(currentUnreadMessageCount);

  }

  clearUnreadCount() {

    const key = `u_${this.data.id}`;

    const currentUnreadMessageCount: UnreadMessageCount = this.siteService.getUnreadMessageCount();

    if (currentUnreadMessageCount.counts[key]) {

      currentUnreadMessageCount.totalCount -= currentUnreadMessageCount.counts[key].count;
      currentUnreadMessageCount.counts[key].count = 0;

    }

    this.siteService.updateUnreadMessageCount(currentUnreadMessageCount);

    this.setMessagesToRead().subscribe({
      next: (affectedRows) => {
        // console.log(`Set ${affectedRows} messages to read.`);
      }
    });

  }

  resetUnreadCount() {

    const currentUnreadMessageCount: UnreadMessageCount = this.siteService.getUnreadMessageCount();

    currentUnreadMessageCount.totalCount = 0;
    currentUnreadMessageCount.counts = {};

    this.siteService.updateUnreadMessageCount(currentUnreadMessageCount);

  }

  toggleEnableStatus(): Observable<boolean> {

    return (this.data.isEnabled) ? this.setDisabled() : this.setEnabled();

  }

  setEnabled(): Observable<boolean> {

    return this.update({
      isEnabled: 1
    });

  }

  setDisabled(): Observable<boolean> {

    return this.update({
      isEnabled: 0
    });

  }

  update(updatedData: any): Observable<any> {

    const data = _.assign({}, this.data, updatedData);

    const url = environment.api.host + environment.api.paths.api.user.updateUser;

    this.siteService.addSubscriptionLog(this, 'user.ts->update->this.http.patch(url, data)');

    return this.http.patch(url, data).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('user.ts->update->this.http.patch(url, data)')),
      map(res => {
      
        this.data = data;

        return res;

      })
    );

  }

  /**
   * Convert the response of a real time message (AppSync) to the format retrieved from the DB
   */
  private appSyncResponseToDisplayMessage(appSyncMessage: AppSyncMessage): UserMessage  {

    const userMessage: UserMessage = {
      data: {
        message: appSyncMessage.message,
        sender: { 
          id: appSyncMessage.senderId, 
          email: appSyncMessage.senderEmail,
        },
        sentAt: appSyncMessage.sentAt,
        recipients: [{ 
          id: appSyncMessage.recipientId,
          email: appSyncMessage.recipientEmail
        }],
        groupId:appSyncMessage.groupId
      },
      messageId: appSyncMessage.messageId,
      readAt: null,
      createdAt: appSyncMessage.sentAt,
    };

    return userMessage;

  }

  private setMessagesToRead(): Observable<number> {

    let url = environment.api.host + easyFormatter(environment.api.paths.api.user.patchMessageToRead, { userId: '' + this.data.id });

    this.siteService.addSubscriptionLog(this, 'user.ts->setMessagesToRead->this.http.patch<number>(url, {})');

    return this.http.patch<number>(url, {}).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('user.ts->setMessagesToRead->this.http.patch<number>(url, {})')),
      map((res: any) => {

        return res.data.affectedRows;

      })
    );

  }

}
