import { Pipe, PipeTransform } from '@angular/core';

import { ServiceProvider } from './classes/service-provider';
import { DynamicComponents } from './dynamic/services/dynamic.service';

import _ from 'lodash';

@Pipe({
  name: 'serviceProviderByServiceType'
})
export class ServiceProviderByServiceTypePipe implements PipeTransform {

  transform(serviceProviders: ServiceProvider[], serviceType: DynamicComponents | DynamicComponents[]): ServiceProvider[] {

    if (Array.isArray(serviceProviders)) {
      
      const response = serviceProviders.filter(serviceProvider => {
        
        const data = serviceProvider.form.value;

        const services: any[] = _.get(data, 'services', []);

        const serviceIncludedIndex = services.findIndex(service => {
          if (Array.isArray(serviceType)) {
            return serviceType.includes(service.type.value.toLowerCase());
          }

          return service.type.value.toLowerCase().includes(serviceType);
        });

        if (serviceIncludedIndex > -1) {
          return true;
        } else {
          return false;
        }

      });

      return response;

    } else {
      
      return serviceProviders;

    }

  }

}
