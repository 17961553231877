import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';

import { AccountFirstCallSourceGroupListComponent } from './account-firstcall-sourcegroup-list.component';
import { CardModule } from 'primeng/card';

@NgModule({
  declarations: [
    AccountFirstCallSourceGroupListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    ButtonModule,
    RippleModule,
    InputTextModule,
    CardModule
  ]
})
export class AccountFirstCallSourceGroupListModule { }
