import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RippleModule } from 'primeng/ripple';
import { DropdownModule } from 'primeng/dropdown';

import { AccountHospitalAndNursingHomeComponent } from './account-hospital-and-nursing-home.component';
import { PipeModule } from '../../pipe.module';
import { AddressWithHeadingModule } from '../../forms/components/address-with-heading/address-with-heading.module';

@NgModule({
  declarations: [
    AccountHospitalAndNursingHomeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    DropdownModule,
    ButtonModule,
    RippleModule,
    PipeModule,
    AddressWithHeadingModule,
  ],
  exports: [
    AccountHospitalAndNursingHomeComponent
  ],
})
export class AccountHospitalAndNursingHomeModule { }
