import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountContactComponent } from './account-contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { ContactModule } from '../../forms/components/contact/contact.module';

@NgModule({
  declarations: [
    AccountContactComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RippleModule,
    ButtonModule,
    TooltipModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    ContactModule,
  ]
})
export class AccountContactModule { }
