<div class="flex flex-column">

  <div class="content-container">

    <ng-container *ngIf="type === 'navigate'">
      <p>Changes have been detected within this arrangement. Would you like to save the changes before navigating?</p>
    </ng-container>

    <ng-container *ngIf="type === 'close'">
      <p>Changes have been detected within this arrangement. Would you like to save the changes before closing?</p>
    </ng-container>

  </div>

  <div class="actions-container flex justify-content-end mt-3">
  
    <ng-container *ngIf="type === 'navigate'">
      <button pButton pRipple type="button" label="Navigate without saving" class="p-button-secondary mr-3" [disabled]="saving" [loading]="retrieving" (click)="retrieve($event)"></button>
      <button pButton pRipple type="button" label="Save and then Navigate" class="p-button-primary" [disabled]="retrieving" [loading]="saving" (click)="save($event)"></button>
    </ng-container>
  
    <ng-container *ngIf="type === 'close'">
      <button pButton pRipple type="button" label="Close without saving" class="p-button-secondary mr-3" [disabled]="saving" [loading]="retrieving" (click)="retrieve($event)"></button>
      <button pButton pRipple type="button" label="Save and then Close" class="p-button-primary" [disabled]="retrieving" [loading]="saving" (click)="save($event)"></button>
    </ng-container>

  </div>

</div>
