import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, SkipSelf, ViewChildren } from '@angular/core';
import { ControlContainer, AbstractControl, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { lifeStatusValue, gendersFormValue, relationshipTypesFormValue } from '../../form-values';
import { AddressComponent } from '../address/address.component';
import { NameComponent } from '../name/name.component';
import _, { noop } from 'lodash';
import { Contact } from 'src/app/shared/classes/contact';
import { changeDetection } from 'src/app/shared/change-detection';
import { RelationToDeceased } from 'src/app/shared/models/arrangement';
import { Dropdown } from 'primeng/dropdown';
import { UniqueId } from 'src/app/shared/uniqueId';
import { HttpClient } from '@angular/common/http';
import { Arrangement } from 'src/app/shared/classes/arrangement';
import { ValidationWithValidators } from '../../form-validation-alert';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, pairwise, takeUntil, tap } from 'rxjs/operators';
import { FormGenerator } from '../../form-generator';
import { FormContactStructure } from '../../form-structure';
import { OnChangeEvent, SelectMultiComponent } from '../select-multi/select-multi.component';
import hashIt from 'hash-it';
import { ModalService } from 'src/app/shared/services/modal.service';
import { SiteService } from 'src/app/shared/services/site.service';

interface Command {
  originalEvent: Event;
  item: any;
};

interface ControlState {
  [key: string]: boolean | ControlState;
};

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class ContactComponent implements OnInit, AfterViewInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  @Input() contacts!: BehaviorSubject<Contact[]>;
  @Input() controlName!: string;
  @Input() formGroup!: FormGroup;
  @Input() states!: ControlState;
  @Input() placeholder!: string;
  @Input() relationship!: RelationToDeceased;
  @Input() showFooter: boolean;
  @Input() noArrangement: boolean;
  @Input() hideContactSelector: boolean;

  @Input() showContactVisibility!: boolean;

  @ViewChildren('name') name!: QueryList<NameComponent>;
  @ViewChildren('address') address!: QueryList<AddressComponent>;
  @ViewChildren('mailingAddress') mailingAddress!: QueryList<AddressComponent>;
  @ViewChildren('contactDropDownWrapper') contactDropDownWrapper!: QueryList<ElementRef>;
  @ViewChildren('contactDropDown') contactDropDown!: QueryList<Dropdown>;

  @ViewChildren('relationshipSelectMulti') relationshipSelectMulti!: QueryList<SelectMultiComponent>;

  contacts$!: Observable<Contact[]>;

  inputPlaceholder: string;
  
  isDetailedView: boolean;
  isVisible: boolean;

  genders = gendersFormValue;
  relationsToDeceased = relationshipTypesFormValue;
  lifeStatus = lifeStatusValue;

  formRoot!: AbstractControl;

  parentFormGroupControl!: FormGroup;
  existingContactsForm!: FormGroup;
  contactSelected: Contact | null;

  defaultNameState: ControlState;
  defaultAddressState: ControlState;
  defaultMailingAddressState: ControlState;

  nameState: ControlState;
  addressState: ControlState;
  mailingAddressState: ControlState;

  defaultState: ControlState;
  detailedState: ControlState;
  activeState: ControlState;

  isReady: boolean;

  hideRelationToDeceased: boolean;

  autoRelationships = [
    { relationship: RelationToDeceased.Informant, path: 'keyContacts.informant' },
    { relationship: RelationToDeceased.NextOfKin, path: 'keyContacts.nextOfKin' },
    { relationship: RelationToDeceased.Executor, path: 'keyContacts.executor' },
  ];

  options = [
    {
      id: 'name',
      label: 'Name', 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'gender',
      label: 'Gender' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'dateOfBirth',
      label: 'Date of Birth' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'address',
      label: 'Address' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'mailingAddress',
      label: 'Mailing Address' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'phone',
      label: 'Phone' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'email',
      label: 'Email' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'relationshipToDeceased',
      label: 'Relationship to Deceased' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'title',
      label: 'Title' , 
      icon: 'pi pi-check',
      state: false,
      command: this.menuCommand
    },
    { 
      id: 'lifeStatus',
      label: 'Life Status' , 
      icon: 'pi pi-check',
      state: false,
      command: this.menuCommand
    },
    { 
      id: 'notes',
      label: 'Notes' , 
      icon: 'pi pi-check',
      state: false,
      command: this.menuCommand
    },
  ];

  nameOptions!: any[];
  addressOptions!: any[];
  mailingAddressOptions!: any[];

  formGroupSubscription!: Subscription;
  formGroupRelationToDeceasedSubscription!: Subscription;
  selectedContactFormSubscription!: Subscription;

  subscriptions: Subscription[];

  constructor(
    private siteService: SiteService,
    private http: HttpClient,
    private controlContainer: ControlContainer,
    private modalService: ModalService,
  ) {

    this.subscriptions = [];

    this.showContactVisibility = true;
    this.isVisible = true;

    this.isReady = false;
    this.hideRelationToDeceased = false;

    this.isDetailedView = false;

    this.contactSelected = null;

    this.inputPlaceholder = 'Please select a contact...';
    
    this.showFooter = false;
    this.noArrangement = false;
    this.hideContactSelector = false;

    this.defaultNameState = { 
      title: false, 
      firstName: true, 
      middleName: false, 
      lastName: true, 
      firstNameAtBirth: false, 
      alsoKnownAs: false,
    };

    this.defaultAddressState = { 
      street: true, 
      suburb: true,
      state: true,
      postcode: true,
      country: false,
    };

    this.defaultMailingAddressState = { 
      street: true, 
      suburb: true,
      state: true,
      postcode: true,
      country: false,
    };

    this.defaultState = {
      name: this.defaultNameState,
      gender: false,
      dateOfBirth: false,
      address: false,
      mailingAddress: false,
      phone: true,
      email: true,
      lifeStatus: false,
      notes: false,
    };

    this.detailedState = {
      name: true,
      gender: true,
      dateOfBirth: true,
      address: true,
      mailingAddress: true,
      phone: true,
      email: true,
      lifeStatus: false,
      notes: true,
    };

    this.activeState = this.defaultState;
    this.nameState = this.defaultNameState;
    this.addressState = this.defaultAddressState;
    this.mailingAddressState = this.defaultMailingAddressState;

  }

  ngOnInit(): void {

    this.contacts$ = this.contacts.asObservable();

    this.subscriptions.push(this.contacts$.subscribe({
      next: (contacts) => {
        
        if (this.contactDropDown?.first && this.contactDropDownWrapper?.first) {

          // get height of this.contactDropDown.first html element
          const contactDropDownHeight = this.contactDropDown.first.el.nativeElement.offsetHeight;

          // set height of this.contactDropDownWrapper.first html element
          this.contactDropDownWrapper.first.nativeElement.style.height = contactDropDownHeight + 'px';

        }

        this.hideContactSelector = true;
        this.hideRelationToDeceased = true;

        changeDetection(() => {
          this.hideContactSelector = false;
          this.hideRelationToDeceased = false;
        });

      }
    }));

    const control = this.controlContainer.control?.get(this.controlName);

    if (control && !this.formGroup) {

      this.formGroup = control as FormGroup;

    }

    // Do we have a valid parent form control? (we always should)
    if (control) {

      this.parentFormGroupControl = control as FormGroup;

    }

    // Get the root form group (This is the top most formGroup)
    this.formRoot = (this.controlContainer.control as FormGroup).root;

    // Create form to hold the selected existing contact (the contact we select in the select menu)
    this.existingContactsForm = new FormGroup({
      contact: new FormControl()
    });

    const formGroupContactId = this.parentFormGroupControl.get('id')?.value;
    const arrangementControl = (this.formRoot.get('_arrangement'));

    // If `this.parentFormGroup` has an ID, that means we have an existing contact...
    // We need to find the ID in `this.contacts` array and set it to `this.formGroup`
    if (formGroupContactId) {

      // Find the existing ID within contacts
      const existingContactIndex = _.findIndex(this.contacts.value, contact => contact.id === formGroupContactId);

      // If we have an ID...
      if (existingContactIndex > -1) {
        
        // Get the existing contact (class)
        this.contactSelected = this.contacts.value[existingContactIndex];

        // Set the value of the select menu...
        this.existingContactsForm.get('contact')?.patchValue(this.contactSelected);

        // Add relationship
        this.addRelationshipToDeceased();

        // Swap the contact form
        this.swapContactFormGroup(this.contactSelected);

      }

    } else if (!arrangementControl) {

      /**
       * If we don't have an arrangement control it means we're adding a contact to a service provider or something... 
       * If we do have an arrangement control it means we ignore this because we'll use the 'New Contact' button in the
       * select menu
       */         

      this.nonArrangementContact();

      this.isReady = true;

    } else if (this.hideContactSelector === true) {
      
      // this.onAddNewContact(null);

    }

    // Watch the "existing contact" select menu and swap the form groups as needed
    this.existingContactsForm.get('contact')?.valueChanges.subscribe({
      next: (contact: Contact) => {
        this.swapContactFormGroup(contact);
      }
    });

    // Set the states and update as required...
    if (!this.states) {

      this.activeState = this.defaultState;

    } else {

      this.activeState = _.assign(this.defaultState, this.states);

    }

    this.updateStates();

    // Do we have a custom placeholder?
    if (this.placeholder) {

      this.inputPlaceholder = this.placeholder;

    }

    if (this.isReady === false) {
  
      changeDetection(() => {
        this.isReady = true;
      });

    }

  }

  ngAfterViewInit(): void {

    // Set menu options...
    changeDetection(() => {

      this.nameOptions = this.name.map(n => n.options)[0];
      // this.addressOptions = this.address.map(a => a.options)[0];
      // this.mailingAddressOptions = this.mailingAddress.map(ma => ma.options)[0];

    });

  }

  ngOnDestroy(): void {
      
    if (this.formGroupSubscription) {
      this.formGroupSubscription.unsubscribe();
    }

    if (this.formGroupRelationToDeceasedSubscription) {
      this.formGroupRelationToDeceasedSubscription.unsubscribe();
    }

    if (this.selectedContactFormSubscription) {
      this.selectedContactFormSubscription.unsubscribe();
    }

    if (this.subscriptions.length) {
      this.subscriptions.forEach(subscription => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();

  }

  onRemove(event: Event, contact: Contact | null): void {
    
    if (!contact) {
      return;
    }

    const modal = this.modalService.generic({
      title: 'Confirm Contact Removal',
      copy: ['Are you sure you want to remove this Contact? This action can not be undone.'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
        { label: 'Confirm', key: 'confirm', class: '' },
      ],
    });

    modal.onClose.subscribe({
      next: (key: string) => {

        if (key === 'confirm') {

          const contacts = this.contacts.value;

          const contactIndex = _.findIndex(contacts, c => c.id === contact.id);

          if (contactIndex > -1) {

            this.hideContactSelector = true;

            contacts.splice(contactIndex, 1);

            this.contacts.next(contacts);

            changeDetection(() => {

              const contactControl = this.existingContactsForm.get('contact');

              if (contactControl) {
      
                contactControl.patchValue(null);
      
              }

              this.hideContactSelector = false;

            });
    

          }

        }

      }
    });

  }

  onAddNewContact(event: Event | null): void {

    if (event) {

      event.preventDefault();

    }

    const uniqueId = new UniqueId();

    const newContactId = uniqueId.getUniqueInArray(this.contacts.value, '_meta.id', 'id-');
    const newContactIndex = this.contacts.value.length;

    // Only arrangements will have a control named `_arrangement`. We can use this to figure out if we in
    // an active arrangement or not...
    let arrangement = this.formRoot.get('_arrangement')?.value as Arrangement;

    // So, is this an arrangement?
    if (arrangement) {

      // Check for any "unnamed" contacts...
      const noNamedContact = this.contacts.value.find(c => c.noName) as Contact;

      let newContact: Contact = null as any;

      if (noNamedContact) {

        newContact = noNamedContact;
        
      } else {
        
        newContact = new Contact(this.http, arrangement, { _meta: { id: newContactId, index: newContactIndex }, id: newContactId } as any);
        
        const existingContacts = this.contacts.value;

        existingContacts.push(newContact);

        this.contacts.next(existingContacts);
        
      }
  
      const contactIndex = _.findIndex(this.contacts.value, contact => contact.id === newContact.id);

      if (contactIndex > -1) {

        this.contactSelected = this.contacts.value[contactIndex];

        const additionalContactsFormArray = arrangement.form.get('additionalContacts') as FormArray;
        const contactControl = this.existingContactsForm.get('contact');

        if (contactControl) {

          contactControl.patchValue(this.contactSelected);

        }

        if (this.hideContactSelector === true) {

          this.addValueChangeSubscriptions(newContact);

          this.isReady = true;
    
        }

      } else {

        console.error('When adding an new contact, we couldn\'t find the new index. This is a problem that shouldn\'t occur...');

      }

    } else if (!arrangement && this.noArrangement) {

      this.nonArrangementContact();

      this.isReady = true;

    } else {

      console.error('onAddNewContact: We expected `this.formRoot` to have an `_arrangement` control / value, but it doesn\'t');

    }

  }

  onChangeContact(event: any): void { }

  onRelationToDeceasedChange(e: OnChangeEvent): void {
    const itemValue = e.itemValue;
    const event = e.value;
  }

  onLogRelationship(event: Event): void {
  }

  toggleDetailView(event: Event) {

    this.isDetailedView = !this.isDetailedView;

    if (this.isDetailedView) {

      this.activeState = this.detailedState;

    } else {

      this.activeState = _.assign(this.defaultState, this.states);

    }

    this.updateStates();

  }

  reset(): void {

    if (!this.contactSelected) {
      return;
    }

    const formGenerator = new FormGenerator();

    const tempFormGroup = formGenerator.generate(FormContactStructure, {});

    this.contactSelected = null;
    this.existingContactsForm.get('contact')?.reset(null, { emitEvent: false });
    this.formGroup.reset(tempFormGroup.value, { emitEvent: false });

  }

  /**
   * Used to update the selected contact based on if an arrangement contact has the required relationship
   * eg: If we need an informant and a contact has that role, select that Contact
   * @param relationship: RelationToDeceased
   */
  autoSelectContactWithRelationship(relationship: RelationToDeceased): void {

    this.contacts.value.some(contact => {

      const hasRequiredRelationship = contact.relationships.includes(relationship);

      // const existingSelectedContact: Contact = this.existingContactsForm.get('contact')?.value as Contact;
      const currentId = this.formGroup.get('id')?.value;

      if (hasRequiredRelationship && currentId !== contact.id) {

        this.isReady = false;

        this.contactSelected = contact;
        this.existingContactsForm.get('contact')?.patchValue(contact, { emitEvent: false });
        
        return true;

      }

      return false;

    });

  }

  // Used in marriage component
  public swapRelationshipToDeceased(relationshipToAdd: RelationToDeceased, relationshipToRemove: RelationToDeceased): void {

    const relationToDeceasedFormControl = this.formGroup.get('relationToDeceased') as FormControl;

    if (relationToDeceasedFormControl) {

      const toAddIndex = this.relationsToDeceased.findIndex(relationship => relationship.value === relationshipToAdd);

      const value: any[] = relationToDeceasedFormControl.value;

      const valueToRemoveIndex = value.findIndex(v => v.value === relationshipToRemove);
      const valueToAddIndex = value.findIndex(v => v.value === relationshipToAdd);

      // Remove the relationship if it exists
      if (valueToRemoveIndex > -1) {
        value.splice(valueToRemoveIndex, 1);
      }

      // Add the relationship if we DON'T have it already
      if (valueToAddIndex === -1 && toAddIndex > -1) {
        value.push(this.relationsToDeceased[toAddIndex]);
      }

      relationToDeceasedFormControl.patchValue(value);

    }

  }

  /**
   * Hide / Show individual inputs as required
   */
  private updateStates() {

    _.forEach(this.activeState, (val, key: string) => {

      const optionsIndex = _.findIndex(this.options, option => option.id === key);

      if (optionsIndex > -1) {

        if (val && !_.isBoolean(val)) {

          _.set(this, key + 'State', val);

        }

        this.options[optionsIndex].state = (val !== false);
        this.menuCommand({ originalEvent: (noop as any), item: this.options[optionsIndex]}, false);

      }

    });

  }

  private menuCommand(event: Command, updateState: boolean = true) {
    
    if (updateState) {

      event.item.state = !event.item.state;

    }

    if (event.item.state) {
      event.item.icon = 'pi pi-check'
    } else {
      event.item.icon = 'pi pi-times'
    }

  }

  private addContact(contact: Contact): void {

    const formArray = this.existingContactsForm.get('contacts') as FormArray;

    if (formArray) {

      formArray.push(new FormControl(contact));

    }

  }

  // Used in this.ngOnInit
  private swapContactFormGroup(newlySelectedContact: Contact): void {

    if (!newlySelectedContact) {

      if (this.formGroupSubscription) {
        this.formGroupSubscription.unsubscribe();
      }

      return;

    }

    const arrangement: Arrangement = this.formRoot.get('_arrangement')?.value;

    if (newlySelectedContact.id !== this.contactSelected?.id) {

      this.contactSelected = newlySelectedContact;

    }

    this.formGroup.patchValue(newlySelectedContact.form.value, { emitEvent: false });

    this.addValueChangeSubscriptions(newlySelectedContact);

    this.addRelationshipToDeceased();

    // Refresh the relationship array in the DISPLAY FORM (this.formGroup)
    // console.log('Swapped newlySelectedContact: ', newlySelectedContact.form.get('relationToDeceased')?.value);
    // console.log('Swapped formGroup: ', this.formGroup.get('relationToDeceased')?.value);

    this.updateRelationToDeceasedWithClonedValue(newlySelectedContact.form.get('relationToDeceased')?.value);

    if (!this.isReady) {

      changeDetection(() => {

        this.isReady = true;

        changeDetection(() => { 

          arrangement.refreshExistingValidatorsCss(); // Not sure of this is needed any more... testing required

        });

      });

    } else {

      changeDetection(() => {

        arrangement.refreshExistingValidatorsCss(); // Not sure of this is needed any more... testing required

      });

    }

  }

  /**
   * Add relationship to the selected arrangement Contact if needed
   * Used in this.ngOnInit and this.swapContactFormGroup
   */
  private addRelationshipToDeceased(): void {

    if (this.relationship && this.contactSelected) { 

      const relationshipToDeceasedIndex = _.findIndex(this.relationsToDeceased, relationshipToDeceased => relationshipToDeceased.value === this.relationship)

      const existingRelationshipToDeceasedIndex = this.hasRelationshipToDeceasedArrangement();

      if (relationshipToDeceasedIndex > -1 && existingRelationshipToDeceasedIndex === -1) {

        this.contactSelected.addRelationshipToDeceased(this.relationsToDeceased[relationshipToDeceasedIndex]);

      }

    }

  }

  /**
   * Remove relationship from the selected arrangement Contact if needed
   */
  private removeRelationshipToDeceased(): void {

    if (this.contactSelected && this.relationship) {

      this.contactSelected.removeRelationshipToDeceased(this.relationship);

    }

  }

  /**
   * Arrangement - Has the selected Contact got the required relationship (uses `hasRelationshipToDeceased(formGroup: FormGroup)`)
   */
  private hasRelationshipToDeceasedArrangement(): number {

    const formGroup = this.contactSelected?.form;

    if (formGroup) {

      return this.hasRelationshipToDeceased(formGroup);

    } else {

      return -1;

    }

  }

  /**
   * Non-Arrangement - Has the selected Contact got the required relationship (uses `hasRelationshipToDeceased(formGroup: FormGroup)`)
   */
  private hasRelationshipToDeceasedNonArrangement(): number {

    return this.hasRelationshipToDeceased(this.formGroup);

  }

  /**
   * Has the supplied form got `this.relationship` within the `relationToDeceased` FormArray
   * @param formGroup 
   * @returns -1 if relationship isn't found. > -1 if the relationship is found
   */
  private hasRelationshipToDeceased(formGroup: FormGroup): number {

    if (this.relationsToDeceased && formGroup) { 

      const contactSelectedRelationshipsToDeceased = formGroup.get('relationToDeceased');

      if (contactSelectedRelationshipsToDeceased) {

        const relationshipsToDeceasedValue = contactSelectedRelationshipsToDeceased.value as { name: string; value: RelationToDeceased}[];

        if (relationshipsToDeceasedValue.length) {

          const relationshipToDeceasedIndex = _.findIndex(relationshipsToDeceasedValue, relationsToDeceased => relationsToDeceased.value === this.relationship);
    
          return relationshipToDeceasedIndex;

        }

      }

    }

    return -1;

  }

  private nonArrangementContact(): void {

    const rootContactFormGroup = this.formRoot.get('contact') as FormGroup;

    if (rootContactFormGroup) {

      this.formGroup = rootContactFormGroup;

    } else {

      console.error(`We expected rootContactFormGroup to exist, but it doesn't`);

      return;

    }

    const hasRequiredRelationship = this.hasRelationshipToDeceasedNonArrangement();

    if (this.relationship && hasRequiredRelationship === -1) {

      const uniqueId = new UniqueId();

      const relationshipToDeceasedIndex = _.findIndex(this.relationsToDeceased, relationshipToDeceased => relationshipToDeceased.value === this.relationship)

      const relationshipToDeceased = this.relationsToDeceased[relationshipToDeceasedIndex];

      const relationToDeceasedFormControl = this.formGroup.get('relationToDeceased') as FormControl;

      const id = uniqueId.getUniqueInArray(relationToDeceasedFormControl.value, '_meta.id', 'id-');
      const index = relationToDeceasedFormControl.value.length;

      relationToDeceasedFormControl.setValue([{
        _meta: { id, index },
        name: relationshipToDeceased.name,
        value: relationshipToDeceased.value,
      }]);

    }

  }

  private addValueChangeSubscriptions(newlySelectedContact: Contact): void {

    if (this.formGroupSubscription) {
      this.formGroupSubscription.unsubscribe();
    }

    if (this.formGroupRelationToDeceasedSubscription) {
      this.formGroupRelationToDeceasedSubscription.unsubscribe();
    }

    if (this.selectedContactFormSubscription) {
      this.selectedContactFormSubscription.unsubscribe();
    }

    this.siteService.addSubscriptionLog(this, 'contact.component.ts->addValueChangeSubscriptions->this.selectedContactFormSubscription = newlySelectedContact.form.valueChanges');

    this.selectedContactFormSubscription = newlySelectedContact.form.valueChanges.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('contact.component.ts->addValueChangeSubscriptions->this.selectedContactFormSubscription = newlySelectedContact.form.valueChanges')),
      takeUntil(this.unsubscribe$),
      distinctUntilChanged((x: any, y: any) => {
        return hashIt(x) === hashIt(y);
      })
    ).subscribe({
      next: (value) => {
        this.formGroup.patchValue(value);
      }
    });

    this.siteService.addSubscriptionLog(this, 'contact.component.ts->addValueChangeSubscriptions->this.formGroupSubscription = this.formGroup.valueChanges');

    this.formGroupSubscription = this.formGroup.valueChanges.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('contact.component.ts->addValueChangeSubscriptions->this.formGroupSubscription = this.formGroup.valueChanges')),
      takeUntil(this.unsubscribe$),
      distinctUntilChanged((x: any, y: any) => {
        return hashIt(x) === hashIt(y);
      }),
      pairwise(),
    ).subscribe({
      next: ([a, b]:[any, any]) => {
        newlySelectedContact.form.patchValue(b);
      }
    });

    const relationToDeceasedFormControl = this.formGroup.get('relationToDeceased') as FormControl;

    if (relationToDeceasedFormControl) {
     
      this.subscriptions.push(relationToDeceasedFormControl.valueChanges.subscribe({
        next: (value: any) => {
          this.updateRelationToDeceasedWithClonedValue(value);
        }
      }));

    }

  }

  private updateRelationToDeceasedWithClonedValue(value: { name: string, value: any}[]): void {

    if (this.relationshipSelectMulti?.first && Array.isArray(value)) {

      const newValue = [];

      const clonedOptions = this.relationshipSelectMulti.first.clonedOptions;
      
      // if (!this.contactSelected || !this.contactSelected.id) {
      //   console.log('No contact id for relationship to deceased');
      //   return;
      // }

      // const contact = _.find(this.contacts.value, contact => contact.id === this.contactSelected?.id);

      // if (!contact) {
      //   console.log('No contact with matching id for relationship to deceased');
      //   return;
      // }

      // const relationshipToDeceased = contact.form.get('relationToDeceased')?.value;

      // if (!Array.isArray(relationshipToDeceased)) {
      //   console.log('No relationship to deceased for contact');
      //   return;

      // }

      for (const v of value) {

        const index = _.findIndex(clonedOptions, clonedOption => clonedOption.value === v.value);

        if (index > -1) {

          newValue.push(clonedOptions[index]);

        }

      }

      this.formGroup.get('relationToDeceased')?.patchValue(newValue, { emitEvent: false });
      this.contactSelected?.form.get('relationToDeceased')?.patchValue(newValue, { emitEvent: false });

    }

  }

}
