<div class="flex flex-column">

  <div class="formgrid grid">

    <div class="field col-6">

      <span class="p-float-label mt-5">
        <input id="input-name"  type="text" pInputText class="w-full" (keyup)="onInputNameChange($event)" [(ngModel)]="config.data.data.name"> 
        <label for="input-name">Enter the {{ config.data.title }} name here...</label>
      </span>

    </div>

    <div class="field col-6 flex align-items-end">

      <span class="p-float-label mt-5 flex-auto">
        <input id="input-value" type="text" pInputText class="w-full" (keyup)="onInputValueChange($event)" [(ngModel)]="config.data.data.value" [readonly]="keyIsReadOnly"> 
        <label for="input-value">Enter the {{ config.data.title }} value here...</label>
      </span>

      <button class="flex-initial border-noround-left" type="button" pButton pRipple (click)="onToggleReadOnly($event)" [icon]="(keyIsReadOnly) ? 'pi pi-lock' : 'pi pi-unlock'"></button>

    </div>

    <div class="field col-6 flex">
      <p-multiSelect 
        #sourceGroupMulti
        *ngIf="(sourceGroups$ | async) as group"
        class="flex-auto w-full pt-3" 
        [options]="group | sort:'asc':'name'" 
        [(ngModel)]="config.data.data.group"
        optionLabel="name" 
        placeholder="Select a source group category..."
        appendTo="body"
        (onChange)="onGroupSelect($event)"
      >
        <ng-template pTemplate="empty">
          <div class="empty-add-new-group">
            <span pRipple (click)="onAddNewGroup($event, sourceGroupMulti)" class="block w-full p-2 add-new-group-footer">Add New Source Group</span>
          </div>
        </ng-template>
        
        <ng-template *ngIf="group.length" pTemplate="footer">
          <div class="px-3 py-2 border-top-1 border-right-none border-bottom-none border-left-none border-solid border-300 add-new-group-footer">
            <span pRipple (click)="onAddNewGroup($event, sourceGroupMulti)" class="block w-full">Add New Source Group</span>
          </div>
        </ng-template>
  
      </p-multiSelect>

    </div> 

    <div *ngIf="showAddNewGroupInput" class="field col-6 flex align-items-end">

      <span class="p-float-label w-full pt-3">
        <input placeholder="Enter the First Call source group here..." type="text" pInputText class="w-full" (keyup)="onInputValueChange($event)" [(ngModel)]="config.data.data.newGroup"> 
      </span>

    </div>

  </div>
  <div class="actions-container flex-1 flex justify-content-end">
  
    <button *ngFor="let button of config.data.buttons" pButton pRipple type="button" [label]="button.label" [class]="button.class" [icon]="((button.icon) ? ('pi ' + button.icon) : '')" (click)="ref.close(button.key)"></button>
    
  </div>

</div>