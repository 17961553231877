import _ from 'lodash';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { easyFormatter } from '../easyFormatter';
import { HttpClient } from '@angular/common/http';
import { finalize, map, tap } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { SiteService } from '../services/site.service';
import { AppSyncMessage, AppSyncMessageResponse, Group, UserMessage, UserMessageResponse } from '../models/user';

export class Groups {

  data: Group;
  private messageSource!: BehaviorSubject<any>;
  message$!: Observable<any>;
  private messages: UserMessage[] = [];
  constructor(
    private http: HttpClient,
    private siteService: SiteService,
    group: Group
  ) {
    this.data = group;
  }

  get name(): string {
    return this.data.title;
  }

   /**
   * Add message to message display
   * @param message - The message object
   * @returns void if message is null
   */
    addMessage(message: UserMessage) {

      if (!message) {
        
        return;
  
      }
  
      this.messageSource.next(message);
      
    }

    /**
   * Create BehaviorSubject so we can subscribe to user messages
   * @param observable - The GraphQL NotifyMessageDirect Subscription observable
   */
  setMessageObservable(observable: Observable<any>) {

    this.messageSource = new BehaviorSubject<AppSyncMessage | null>(null);
    this.message$ = this.messageSource.asObservable();

    observable.subscribe({
      next: (res: AppSyncMessageResponse) => {
        const message: AppSyncMessage | null = _.get(res, 'data.onNotifyGroupMessageDirect', null);

        if (message) {

          const displayMessage = this.appSyncResponseToDisplayMessage(message);
          this.addMessage(displayMessage);
          
        }

      }
    });

  }

  /**
   * Convert the response of a real time message (AppSync) to the format retrieved from the DB
   */
   private appSyncResponseToDisplayMessage(appSyncMessage: AppSyncMessage): UserMessage  {

    const userMessage: UserMessage = {
      data: {
        message: appSyncMessage.message,
        sender: { 
          id: appSyncMessage.senderId, 
          email: appSyncMessage.senderEmail,
        },
        sentAt: appSyncMessage.sentAt,
        
        groupId:appSyncMessage.groupId
      },
      messageId: appSyncMessage.messageId,
      readAt: null,
      createdAt: appSyncMessage.sentAt,
    };

    return userMessage;

  }

  /**
   * Do remote request to get user's messages from the DB
   */
   getMessages(): Observable<any> {

    let url = environment.api.host + easyFormatter(environment.api.paths.api.message.groups.getMessageById, { groupId: '' + this.data.id });

    this.siteService.addSubscriptionLog(this, 'group.ts->getMessages->this.http.get');

    return this.http.get(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('group.ts->getMessages->this.http.get')),
      map((res: any) => {

        this.messages = [];

        res.data.forEach((message:any) => this.addMessage(this.appSyncResponseToDisplayMessage(message)));

        return res.data;

      })
    );

  }
}
