import { AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormStructureType, FormStructure, FormSiteContactStructure } from '../../forms/form-structure';
import { FormGenerator } from '../../forms/form-generator';
import { FormContactStructure } from '../../forms/form-structure';
import _ from 'lodash';

import { ContactComponent } from '../../forms/components/contact/contact.component';
import { finalize, first, takeUntil, tap } from 'rxjs/operators';
import { changeDetection } from '../../change-detection';
import { AccountService } from '../../services/account.service';
import { Account } from '../../models/account';
import { UniqueId } from '../../uniqueId';
import { ModalService } from '../../services/modal.service';
import { MessageService } from 'primeng/api';
import { SiteService } from '../../services/site.service';

@Component({
  selector: 'app-account-contact',
  templateUrl: './account-contact.component.html',
  styleUrls: ['./account-contact.component.scss']
})
export class AccountContactComponent implements OnInit, OnDestroy, AfterViewInit {

  private unsubscribe$ = new Subject<void>();

  @ViewChildren('contact') contact!: QueryList<ContactComponent>;

  isReady: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;

  showContact: BehaviorSubject<boolean>;

  form!: FormGroup;
  data!: any;

  constructor(
    private siteService: SiteService,
    private modalService: ModalService,
    private accountService: AccountService,
    private messageService: MessageService,
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) {

    this.isReady = false;
    this.isSubmitted = false;
    this.isSubmitting = false;

    this.showContact = new BehaviorSubject<boolean>(false);

  }

  ngOnInit(): void {

    this.form = new FormGroup({
      isSubmitted: new FormControl(false)
    });

    const data = this.config.data;

    if (!data || _.isEmpty(data)) {

      this.data = {
        paths: []
      };

      const path = {
        _meta: { id: 'id-a', index: 0 }, 
        path: 'deceased.placeOfPassing.address' 
      };
      
      this.data.paths.push(path);

    } else {

      this.data = this.config.data;

    }

    this.generateForm();

    this.addValidators();

    this.isReady = true;

  }

  ngAfterViewInit(): void {

    // hide / show the required contact inputs
    // Note: We only want the address input
    changeDetection(() => {

      const contactOptions = this.contact.first.options;
  
      contactOptions[0].state = false;
      contactOptions[1].state = false;
      contactOptions[2].state = false;
      contactOptions[3].state = true;
      contactOptions[4].state = false;
      contactOptions[5].state = false;
      contactOptions[6].state = false;
      contactOptions[7].state = false;
      contactOptions[8].state = false;
  
      this.showContact.next(true);

    });

  }

  ngOnDestroy(): void {
    
    this.removeValidators();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();

  }

  onSubmit(event: Event): void {

    this.form.get('isSubmitted')?.patchValue(true);

    if (this.form.invalid) {

      return;

    }

    this.isSubmitting = true;

    this.siteService.addSubscriptionLog(this, 'account-contact.component.ts->onSubmit->this.accountService.account$');

    this.accountService.account$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('account-contact.component.ts->onSubmit->this.accountService.account$')),
      takeUntil(this.unsubscribe$),
      first(account => account !== undefined)
    ).subscribe({
      next: (account) => {

        const data = account.data;

        if (!data.contacts) {

          data.contacts = [];

        }

        const contact = this.form.value;

        delete contact.iSubmitted;

        if (!_.has(contact, '_meta')) {

          const uniqueId = new UniqueId();
  
          const id = uniqueId.getUniqueInArray(data.contacts, '_meta.id', 'id-');
          const index = data.contacts.length;
  
          contact._meta = { id, index };

          data.contacts.push(contact);

        } else {

          const existingIndex = _.findIndex(data.contacts, (contact: any) => {
            return contact._meta.id === this.data._meta.id;
          });

          data.contacts[existingIndex] = contact;

        }

        this.accountService.patch(data).subscribe({
          next: (res) => {
            
            this.isSubmitting = false;

            const modal = this.modalService.generic({
              title: 'Contact Saved Successfully',
              copy: [`The '${ contact.name }' contact was saved successfully.`],
              buttons: [
                { label: 'Close', key: 'close', class: '' },
              ]
            });
            
            modal.onClose.subscribe({
              next: (key) => {

                this.ref.close();

              }
            })

          },
          error: (err) => {
            this.isSubmitting = false;
            this.modalService.error(err.error.error);
          }
        });

      }
    })

  }

  // Generate the required form
  private generateForm() {

    const formGenerator = new FormGenerator();

    const formStructure: any =  _.cloneDeep(FormSiteContactStructure);

    formGenerator.generate(formStructure, this.data, this.form);

  }

  // Add validators as needed
  private addValidators(): void {

    const name = this.form.get('name');
    const street = this.form.get('contact.address.street');
    const suburb = this.form.get('contact.address.suburb');
    const state = this.form.get('contact.address.state');
    const postcode = this.form.get('contact.address.postcode');

    if (name) {
      name.addValidators([Validators.required]);
    }

    if (street) {
      street.addValidators([Validators.required]);
    }

    if (suburb) {
      suburb.addValidators([Validators.required]);
    }

    if (state) {
      state.addValidators([Validators.required]);
    }

    if (postcode) {
      postcode.addValidators([Validators.required]);
    }

  }

  // Remove validators as needed
  private removeValidators(): void {

    const name = this.form.get('name');
    const street = this.form.get('contact.address.street');
    const suburb = this.form.get('contact.address.suburb');
    const state = this.form.get('contact.address.state');
    const postcode = this.form.get('contact.address.postcode');

    if (name) {
      name.removeValidators([Validators.required]);
      name.updateValueAndValidity();
    }

    if (street) {
      street.removeValidators([Validators.required]);
      street.updateValueAndValidity();
    }

    if (suburb) {
      suburb.removeValidators([Validators.required]);
      suburb.updateValueAndValidity();
    }

    if (state) {
      state.removeValidators([Validators.required]);
      state.updateValueAndValidity();
    }

    if (postcode) {
      postcode.removeValidators([Validators.required]);
      postcode.updateValueAndValidity();
    }

  }

}
