import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, SkipSelf, ViewChildren } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

import { InputSwitch } from 'primeng/inputswitch';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import _ from 'lodash';

import { ModalService } from 'src/app/shared/services/modal.service';
import { changeDetection } from 'src/app/shared/change-detection';
import { Arrangement } from 'src/app/shared/classes/arrangement';

import { AddressComponent } from '../address/address.component';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'app-address-with-heading',
  templateUrl: './address-with-heading.component.html',
  styleUrls: ['./address-with-heading.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class AddressWithHeadingComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() required!: boolean;
  @Input() headingText!: string;

  @Input() addressControlName!: string;

  @Input() autoFillAddressOptions!: any[];
  @Input() autoFillAddressControlName!: string;

  @Input() autoFillToggleSwitchText!: string;
  @Input() autoFillToggleSwitchControlName!: string;

  @Input() internationalAddressToggleSwitchText!: string;

  @Input() headingCssClass!: string;

  @ViewChildren('address') address!: QueryList<AddressComponent>;
  @ViewChildren('addressUseAutoFill') addressUseAutoFill!: QueryList<InputSwitch>;
  @ViewChildren('addressAutoFill') addressAutoFill!: QueryList<SelectComponent>;

  addressOptions: BehaviorSubject<any[]>;

  form!: FormGroup;
  arrangement!: Arrangement;
  currentFormPath: string;

  isInternationalFormControl = new FormControl(false);

  subscriptions: Subscription[];

  constructor(
    private controlContainer: ControlContainer,
    private modalService: ModalService,
  ) {

    this.addressOptions = new BehaviorSubject<any[]>([]);
    this.currentFormPath = '';
    this.headingCssClass = '';

    this.subscriptions = [];

  }

  ngOnInit(): void {

    if (this.controlContainer.path?.length) {

      this.currentFormPath = this.controlContainer.path.join('.') + '.';

    }
    
    this.currentFormPath += this.addressControlName + '.';

    const formGroupDirective = this.controlContainer.formDirective as FormGroupDirective;

    this.form = formGroupDirective.form;

    const arrangementControl = this.form.get('_arrangement');

    if (arrangementControl) {

      this.arrangement = arrangementControl.value as Arrangement;

    }

    // if (this.autoFillToggleSwitchControlName && this.autoFillAddressControlName) {
    
    //   this.formSubscriptionAutoFill();

    // }

    if (this.internationalAddressToggleSwitchText) {

      this.formSubscriptionToggleInternational();

    }

  }

  ngAfterViewInit(): void {

    if (this.address.length) {

      const addressOptions = this.address.first.options;

      let place = this.form.get(`${ this.currentFormPath }place`)?.value;
      let street = this.form.get(`${ this.currentFormPath }street`)?.value;
      const suburb = this.form.get(`${ this.currentFormPath }suburb`)?.value;
      const state = this.form.get(`${ this.currentFormPath }state`)?.value;
      const postcode = this.form.get(`${ this.currentFormPath }postcode`)?.value;
      const country = this.form.get(`${ this.currentFormPath }country`)?.value;

      if (!place) {
        place = '';
      }

      if (!street) {
        street = '';
      }

      if (country && country.toLowerCase() !== 'australia') {

        addressOptions[4].state = true;

        this.address.first.isInternationalAddress(true); 

      }

      if (place || street) {

        if (place.toString().toLowerCase() !== street.toString().toLowerCase()) {

          addressOptions[5].state = true;

        }

      }

      changeDetection(() => {
        this.addressOptions.next(addressOptions);
      });

    }

    if (this.addressAutoFill.length) {

      changeDetection(() => {
        this.addressAutoFill.first.menuOptions = [
          { label: 'Add New', icon: 'pi pi-plus', command: () => { this.addPlaceOfPassingAutoFillAddress() } },
          { label: 'Edit Existing', icon: 'pi pi-pencil', command: () => { this.editPlaceOfPassingAutoFillAddress() } },
        ]
      });

    }

  }

  ngOnDestroy(): void {

    this.subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });

  }

  // Don;t think we need this any more...
  onToggleInternationalAddress(event: any): void {

    const isInternationalAddress = this.address.first.isInternationalControl?.value;

    if (isInternationalAddress === true) { // Only set to false if the value is explicitly true

      this.address.first.isInternationalAddress(false);
      
    } else if (isInternationalAddress === false) { // Only set to true if the value is explicitly false

      this.address.first.isInternationalAddress(true);

    } else { // All other values are invalid, so we simple set to false (the default value)

      this.address.first.isInternationalAddress(false);

    }

  }

  private addPlaceOfPassingAutoFillAddress(): void {

    this.modalService.addAccountContact();

  }

  private editPlaceOfPassingAutoFillAddress(): void {

    this.modalService.listAccountContact({});

  }

  /**
   * Subscribe to form controls that will handle toggling the auto complete functionality
   */
  private formSubscriptionAutoFill() {

    const formValueChangePaths = [
      `${ this.currentFormPath }address`,
      `${ this.currentFormPath }useAutoFill`,
      `${ this.currentFormPath }autoFill`,
    ];

    this.arrangement.addFormControlSubscription(formValueChangePaths, {
      next: ([address, useAutoFill, autoFill]) => {
        
        const placeOfPassingAddressFormGroup = this.form.get(`${ this.currentFormPath }address`) as FormGroup;
        const autoFillAddress = _.get(autoFill, 'contact.address', null);

        if (useAutoFill) {

          this.address.first.readOnlyInputs(true);

          if (this.addressAutoFill.first) {

            this.addressAutoFill.first.readonly = false;

          }

          if (placeOfPassingAddressFormGroup && autoFillAddress) {

            placeOfPassingAddressFormGroup.patchValue(autoFillAddress);

          }
          
        } else {

          this.address.first.readOnlyInputs(false);

          this.addressAutoFill.first.readonly = true;

        }

      }
    });

  }

  /**
   * Subscribe to form controls that will handle toggling the address between local / international addresses
   */
  private formSubscriptionToggleInternational() {

    if (this.isInternationalFormControl) {

      const isInternationalValue = this.form.get(`${ this.currentFormPath }isInternational`)?.value;
      
      this.isInternationalFormControl.patchValue(isInternationalValue ? true : false);

      this.subscriptions.push(this.isInternationalFormControl.valueChanges.subscribe({
        next: (value: boolean) => {

          if (value) {

            this.address.first.isInternationalAddress(true);

          } else {

            this.address.first.isInternationalAddress(false);

          }

        }
      }));

    }

    // const formValueChangePaths = [
    //   `${ this.currentFormPath }address.isInternational`,
    // ];

    // this.arrangement.addFormControlSubscription(formValueChangePaths, {
    //   next: ([toggle]: [boolean]) => {
        

    //   }
    // });

  }

}
