import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appIsVisible]'
})
export class IsVisibleDirective implements OnInit, OnChanges  {

  @Input() appIsVisible!: boolean;

  @Input() hiddenStyle!: string;
  @Input() visibleStyle!: string;

  @Input() hiddenCssClass!: string;
  @Input() visibleCssClass!: string;

  protected activeHiddenStyle = 'none !important';
  protected activeVisibleStyle = 'flex !important';

  protected activeHiddenCssClass = 'is-hidden-display-none';
  protected activeVisibleCssClass = 'is-visible-display-block';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.hiddenStyle) {
      this.activeHiddenStyle = changes.hiddenStyle.currentValue;
    }

    if (changes.visibleStyle) {
      this.activeVisibleStyle = changes.visibleStyle.currentValue;
    }

    if (changes.hiddenCssClass) {
      this.activeHiddenCssClass = changes.hiddenCssClass.currentValue;
    }

    if (changes.visibleCssClass) {
      this.activeVisibleCssClass = changes.visibleCssClass.currentValue;
    }

    if (changes.appIsVisible.currentValue) {
      // this.renderer.setAttribute(this.el.nativeElement, 'style', `display: ${ this.activeVisibleStyle }`);
      this.renderer.addClass(this.el.nativeElement, this.activeVisibleCssClass);
      this.renderer.removeClass(this.el.nativeElement, this.activeHiddenCssClass);
    } else {
      // this.renderer.setAttribute(this.el.nativeElement, 'style', `display: ${ this.activeHiddenStyle }`);
      this.renderer.addClass(this.el.nativeElement, this.activeHiddenCssClass);
      this.renderer.removeClass(this.el.nativeElement, this.activeVisibleCssClass);
    }

  }

}
