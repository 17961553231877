import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { changeDetection } from '../../change-detection';
import { stringToKey } from '../../string-to-key';

@Component({
  selector: 'app-input-name-value-pair',
  templateUrl: './input-name-value-pair.component.html',
  styleUrls: ['./input-name-value-pair.component.scss']
})
export class InputNameValuePairComponent implements OnInit {

  keyIsReadOnly: boolean;

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) {

    this.keyIsReadOnly = true;

  }

  ngOnInit(): void {

    const inputsAreSynced = this.nameAndValueAreSynced();

    if (inputsAreSynced) {
      this.keyIsReadOnly = true;
    } else {
      this.keyIsReadOnly = false;
    }

  }

  onInputNameChange(event: Event): void {

    if (this.keyIsReadOnly) {

      this.config.data.data.value = stringToKey(this.config.data.data.name);
  
      changeDetection(() => {
  
        const inputsAreSynced = this.nameAndValueAreSynced();
  
        if (inputsAreSynced && this.keyIsReadOnly === false) {
          this.keyIsReadOnly = true;
        }
  
      });

    }

  }

  onInputValueChange(event: Event): void {

    this.config.data.data.value = stringToKey(this.config.data.data.value);

  }

  onToggleReadOnly(event: Event): void {

    this.keyIsReadOnly = !this.keyIsReadOnly;

  }

  private nameAndValueAreSynced() {

    const currentKey = this.config.data.data.value;
    const currentNameAsKey = stringToKey(this.config.data.data.name);

    return currentKey === currentNameAsKey;

  }

}