<ng-container [formGroupName]="controlName">
  
  <div class="formgrid grid">

    <div class="col-12 mt-3">
      <div class="flex p-input-icon-left p-inputgroup">
        <i class="pi pi-search"></i>
        <input #searchTextInput pInputText appPreventAutoComplete formControlName="search" type="text" placeholder="Search google maps for address..." [readonly]="inputsReadOnly | async" (keydown.enter)="$event.preventDefault()" />
        <button type="button" pButton pRipple icon="pi pi-times" (click)="onClearSearch($event)"></button>
      </div>
    </div>
    
    <div class="col-12">
      <p-divider></p-divider>
    </div>

    <ng-container *ngIf="showInputs">
    
      <div [appShowHide]="options[5].state" class="field col-12 md:col-6">
        <label [appIsRequired]="'place'">Place Name:</label>
        <input #textInput #placeTextInput pInputText appPreventAutoComplete formControlName="place" type="text" class="inputfield w-full" [readonly]="inputsReadOnly | async" [ngClass]="{ 'ng-invalid ng-dirty' : placeControl?.invalid && isSubmittedControl?.value }" />
      </div>
    
      <div [appShowHide]="options[0].state" class="field col-12 md:col-6">
        <label [appIsRequired]="'street'">Street:</label>
        <input #textInput #streetTextInput pInputText appPreventAutoComplete formControlName="street" type="text" class="inputfield w-full" [readonly]="inputsReadOnly | async" [ngClass]="{ 'ng-invalid ng-dirty' : streetControl?.invalid && isSubmittedControl?.value }" />
      </div>
      
      <div [appShowHide]="options[1].state" class="field col-12 md:col-6">
        <label [appIsRequired]="'suburb'">Suburb:</label>
        <input #textInput #suburbTextInput pInputText appPreventAutoComplete formControlName="suburb" type="text" class="inputfield w-full" [readonly]="inputsReadOnly | async" [ngClass]="{ 'ng-invalid ng-dirty' : suburbControl?.invalid && isSubmittedControl?.value }" />
      </div>

      <div [appShowHide]="options[2].state" class="field col-12 md:col-6">
        <label [appIsRequired]="'state'">{{ ((isInternational | async) === true) ? 'State / Region:' : 'State:' }}</label>
        <app-select [ngClass]="{ 'hidden': ((isInternational | async) === true) }" controlName="state" [options]="australianStates | sort:'asc':'name'" [filter]="true" [showClear]="true" [readonly]="inputsReadOnly | async" placeHolder="Select state" class="inputfield w-full"></app-select>
        <input #textInput #stateTextInput pInputText appPreventAutoComplete formControlName="state" type="text" class="inputfield w-full" [readonly]="inputsReadOnly | async" [ngClass]="{ 'ng-invalid ng-dirty' : stateControl?.invalid && isSubmittedControl?.value, 'hidden': ((isInternational | async) !== true) }" />
        </div>
      
      <div [appShowHide]="options[3].state" class="field col-12 md:col-6">
        <label [appIsRequired]="'postcode'">Postcode:</label>
        <input #textInput #postCodeTextInput pInputText appPreventAutoComplete formControlName="postcode" type="text" class="inputfield w-full" [readonly]="inputsReadOnly | async" [ngClass]="{ 'ng-invalid ng-dirty' : postcodeControl?.invalid && isSubmittedControl?.value }" />
      </div>
      
      <div [appShowHide]="options[4].state" class="field col-12 md:col-6">
        <label [appIsRequired]="'country'">Country:</label>
        <input #textInput #countryTextInput pInputText appPreventAutoComplete formControlName="country" type="text" class="inputfield w-full" [readonly]="inputsReadOnly | async" [ngClass]="{ 'ng-invalid ng-dirty' : countryControl?.invalid && isSubmittedControl?.value }" />
      </div>

    </ng-container>

  </div>

</ng-container>