import { environment as base } from './environment.base';

base.api.host = 'https://api-staging.fdconductor.com';

base.appSync.graphql.host = 'dyhipe5ojrd4dmmt4dbi2eblm4.appsync-api.ap-southeast-2.amazonaws.com';
base.appSync.graphql.http = 'https://dyhipe5ojrd4dmmt4dbi2eblm4.appsync-api.ap-southeast-2.amazonaws.com/graphql';
base.appSync.graphql.wss = 'wss://dyhipe5ojrd4dmmt4dbi2eblm4.appsync-realtime-api.ap-southeast-2.amazonaws.com/graphql';
base.appSync.apiKey = 'da2-gdhtvh57tfgwvfvm7tckfsqar4';

export const environment = base;
