import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import { finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Account } from '../../models/account';
import { AccountService } from '../../services/account.service';
import { ModalService } from '../../services/modal.service';
import { SiteService } from '../../services/site.service';

@Component({
  selector: 'app-account-religion-list',
  templateUrl: './account-religion-list.component.html',
  styleUrls: ['./account-religion-list.component.scss']
})
export class AccountReligionListComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  account$: Observable<Account>;

  constructor(
    private siteService: SiteService,
    private modalService: ModalService,
    private accountService: AccountService,
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) {

    this.account$ = this.accountService.account$;

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
      
  }

  onEditReligion(event: Event, religion: any): void {
    
    const modal = this.modalService.inputNameValuePair({
      title: 'Religion',
      buttons: [
        { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
        { label: 'Save', key: 'save', class: '' },
      ],
      data: religion,
    });

    modal.onClose.subscribe({
      next: (reason: string) => {
        if (reason && reason.toString().toLowerCase() === 'save') {
          this.updateAccountData();
        }
      }
    });

  }

  onDeleteReligion(event: Event, religion: any): void {
    
    const modal = this.modalService.generic({
      title: 'Remove Item',
      copy: ['Are you sure you want to remove this religion?'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: '' },
        { label: 'Confirm', key: 'confirm', class: 'p-button-danger', icon: 'pi pi-trash' },
      ]
    });

    modal.onClose.subscribe({
      next: (reason: string) => {

        if (reason.toString().toLowerCase() === 'confirm') {
        
          religion.disabled = true;

          this.updateAccountData();

        }

      }
    });

  }

  onRestoreReligion(event: Event, religion: any): void {
    
    const modal = this.modalService.generic({
      title: 'Restore Item',
      copy: ['Are you sure you want to restore this religion?'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: '' },
        { label: 'Confirm', key: 'confirm', class: 'p-button-success', icon: 'pi pi-refresh' },
      ]
    });

    modal.onClose.subscribe({
      next: (reason: string) => {

        if (reason.toString().toLowerCase() === 'confirm') {
        
          religion.disabled = false;

          this.updateAccountData();

        }

      }
    });

  }

  private updateAccountData(): void {

    this.siteService.addSubscriptionLog(this, 'account-religion-list.component.ts->updateAccountData->this.accountService.account$');

    this.accountService.account$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('account-religion-list.component.ts->updateAccountData->this.accountService.account$')),
      takeUntil(this.unsubscribe$),
      first(),
      switchMap(account => this.accountService.patch(account.data)),
    ).subscribe({
      next: res => {
        // console.log(res.data);
      }
    });

  }
  
}
