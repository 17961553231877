<div class="flex flex-column">

  <div class="formgrid grid">

    <div class="field col-6">

      <span class="p-float-label mt-5">
        <input id="input-name"  type="text" pInputText class="w-full" (keyup)="onInputNameChange($event)" [(ngModel)]="config.data.data.name"> 
        <label for="input-name">Enter the {{ config.data.title }} name here...</label>
      </span>

    </div>

    <div class="field col-6 flex align-items-end">

      <span class="p-float-label mt-5 flex-auto">
        <input id="input-value" type="text" pInputText class="w-full" (keyup)="onInputValueChange($event)" [(ngModel)]="config.data.data.value" [readonly]="keyIsReadOnly"> 
        <label for="input-value">Enter the {{ config.data.title }} value here...</label>
      </span>

      <button class="flex-initial border-noround-left" type="button" pButton pRipple (click)="onToggleReadOnly($event)" [icon]="(keyIsReadOnly) ? 'pi pi-lock' : 'pi pi-unlock'"></button>

    </div>
    
  </div>

  <div class="actions-container flex-1 flex justify-content-end">
  
    <button *ngFor="let button of config.data.buttons" pButton pRipple type="button" [label]="button.label" [class]="button.class" [icon]="((button.icon) ? ('pi ' + button.icon) : '')" (click)="ref.close(button.key)"></button>
    
  </div>

</div>