import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RippleModule } from 'primeng/ripple';
import {DropdownModule} from 'primeng/dropdown';

import { AccountFirstCallSource } from './account-firstcall-source.component';
import { PipeModule } from "../../pipe.module";
import { MultiSelectModule } from 'primeng/multiselect';
import { CardModule } from 'primeng/card';


@NgModule({
    declarations: [AccountFirstCallSource],
    exports: [AccountFirstCallSource],
    imports: [
        CommonModule,
        FormsModule,
        InputTextModule,
        InputNumberModule,
        InputTextareaModule,
        ButtonModule,
        RippleModule,
        DropdownModule,
        PipeModule,
        MultiSelectModule,
        CardModule,
    ]
})
export class AccountFirstCallSourceModule { }
