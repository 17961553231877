import { ValidatorFn, FormGroup, ValidationErrors, AbstractControl } from '@angular/forms';

// export function confirmPassword(source: string, compare: string): ValidatorFn {

//   return (formGroup: AbstractControl): ValidatorFn | null => { 

//     let sourceControl = formGroup.get(source);
//     let compareControl = formGroup.get(compare);

//     if (sourceControl && compareControl) {
//       if (sourceControl.value !== compareControl.value) {
//         compareControl.setErrors({ passwordMismatch: true });
//         return null;
//       }
//     }

//     return null;

//   }

// }

export class PasswordMatcher {
  static match(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    };
  }
}
