<ng-container *ngIf="(account$ | async) as account">

  <p-card class="card-info w-full my-3">
    <p class="text-center"><strong>Note:</strong> For optimal performance, please add or edit sources within an <em>empty</em> arrangement. Making these changes in an active arrangement may cause issues.</p>
  </p-card>

  <p-table 
    #sourcesTable 
    [value]="account.data.firstCall.source" 
    [globalFilterFields]="['name']"
    styleClass="mt-5 p-datatable-striped" 
    dataKey="_meta.id" 
    responsiveLayout="scroll"
  >

    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="sourcesTable.filterGlobal($any($event).target.value, 'contains')" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="body" let-source>
      <tr [pSelectableRow]="source">
        <td class="source-text">{{ source.name }}</td>
        <td class="source-actions">
          <button pButton pRipple type="button" icon="pi pi-pencil" (click)="onEditSource($event, source, account.data.firstCall.sourceGroup)" class="p-button-rounded p-button-text"></button>
          <button *ngIf="!source.disabled" pButton pRipple type="button" icon="pi pi-trash" (click)="onDeleteSource($event, source)" class="p-button-rounded p-button-danger p-button-text"></button>
          <button *ngIf="source.disabled" pButton pRipple type="button" icon="pi pi-refresh" (click)="onRestoreSource($event, source)" class="p-button-rounded p-button-success p-button-text"></button>
        </td>
      </tr>
    </ng-template>

  </p-table>

</ng-container>