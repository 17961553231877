import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import { ServiceProvider } from './classes/service-provider';

@Pipe({
  name: 'serviceProvider'
})
export class ServiceProviderPipe implements PipeTransform {

  transform(serviceProviders: ServiceProvider[], input: string): ServiceProvider[] {

    if (Array.isArray(serviceProviders)) {
      
      const response = serviceProviders.filter(serviceProvider => {
        
        const data = serviceProvider.form.value;

        const title: string = _.get(data, 'title', '');
        const tags: any[] = _.get(data, 'tags', []);

        const titleIncluded = (title) ? title.toLowerCase().includes(input.toLowerCase()) : false;
        const tagIncludedIndex = (tags.length) ? _.findIndex(tags, tag => tag.value.toLowerCase().includes(input.toLowerCase())) : -1;

        if (titleIncluded || tagIncludedIndex > -1) {
          return true;
        } else {
          return false;
        }

      });

      return response;

    } else {
      
      return serviceProviders;

    }

  }

}
