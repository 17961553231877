import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import _ from 'lodash';
import { taskStatusType } from './forms/form-enum';
import { taskFilterFn } from './task.pipe';

@Pipe({
  name: 'taskRemoved'
})
export class TaskRemovedPipe implements PipeTransform {

  transform(tasks: FormGroup[], type: string, form: FormGroup): FormGroup[] {

    const visibleTasks: FormGroup[] = [];

    const visibility = taskFilterFn(tasks, type, form);

    visibility.forEach((task, index) => {

      if (task.visible) {

        visibleTasks.push(tasks[index]);

      }

    });

    return visibleTasks;

  }

}
