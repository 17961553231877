import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import * as _ from 'lodash';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { SiteService } from '../services/site.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private siteService: SiteService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate() {
    
    this.siteService.addSubscriptionLog(this, 'authenticated.guard.ts->canActivate->this.authenticationService.isAuthenticated');

    return this.authenticationService.isAuthenticated.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('authenticated.guard.ts->canActivate->this.authenticationService.isAuthenticated')),
      // Skip until authenticated service checking is finish
      filter((authenticated) => authenticated !== null),
      map((authenticated) => {
        return authenticated ? true : this.router.parseUrl('/');
      })
    );
  }
}
