import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import moment from 'moment-timezone';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { SiteService } from 'src/app/shared/services/site.service';

@Component({
  selector: 'app-date-v2',
  templateUrl: './date-v2.component.html',
  styleUrls: ['./date-v2.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class DateV2Component implements OnInit, OnDestroy {
  // Inputs
  @Input() controlName!: string;
  @Input() placeholder: string;
  @Input() yearRange: string;
  @Input() autoPopulate: boolean;
  @Input() showDate: boolean;
  @Input() showTime: boolean;
  @Input() required: boolean;
  @Input() showIcon: boolean;
  @Input() readonly: boolean;

  // Dom related
  @ViewChild('overlayPanel') overlayPanelElement!: OverlayPanel;
  formGroup?: FormGroup;
  externalControl!: FormControl;

  // Internal state
  DATE_FORMAT: string;
  TIME_FORMAT: string;
  DATE_TIME_FORMAT: string;

  minYear?: number;
  maxYear?: number;

  amOrPmOptions: string[];
  hourOptions: { value: number; name: string }[];
  monthOptions: { value: number; name: string }[];

  displayDate: string = '';

  // Subjects
  unsubscribe$ = new Subject();

  constructor(
    private siteService: SiteService,
    private controlContainer: ControlContainer,
    private fb: FormBuilder
  ) {
    this.DATE_FORMAT = 'DD/MM/YYYY';
    this.TIME_FORMAT = 'HH:mm';
    this.DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

    this.placeholder = 'Select Date / Time';
    this.yearRange = '1900:2030';
    this.autoPopulate = false;
    this.showDate = true;
    this.showTime = true;
    this.amOrPmOptions = ['AM', 'PM'];
    this.required = false;
    this.showIcon = false;
    this.readonly = false;

    this.hourOptions = [
      { value: 1, name: '1' },
      { value: 2, name: '2' },
      { value: 3, name: '3' },
      { value: 4, name: '4' },
      { value: 5, name: '5' },
      { value: 6, name: '6' },
      { value: 7, name: '7' },
      { value: 8, name: '8' },
      { value: 9, name: '9' },
      { value: 10, name: '10' },
      { value: 11, name: '11' },
      { value: 12, name: '12' },
    ];

    this.monthOptions = [
      { value: 0, name: 'January' },
      { value: 1, name: 'February' },
      { value: 2, name: 'March' },
      { value: 3, name: 'April' },
      { value: 4, name: 'May' },
      { value: 5, name: 'June' },
      { value: 6, name: 'July' },
      { value: 7, name: 'August' },
      { value: 8, name: 'September' },
      { value: 9, name: 'October' },
      { value: 10, name: 'November' },
      { value: 11, name: 'December' },
    ];
  }

  ngOnInit(): void {
    // Validate inputs
    if (!this.showDate && !this.showTime) {
      throw new Error('date-v2: showDate or showTime must be true!');
    }

    // Initialize
    this.initExternalControl();
    this.initFormGroup();
    this.initFirstValue();
  }

  private initExternalControl() {
    const externalControl = this.controlContainer.control?.get(
      this.controlName
    ) as FormControl;

    this.externalControl = externalControl;

    this.siteService.addSubscriptionLog(this, 'date-v2.component.ts->initExternalControl->this.externalControl.valueChanges');

    this.externalControl.valueChanges
      .pipe(
        finalize(() => this.siteService.setSubscriptionLogFinalised('date-v2.component.ts->initExternalControl->this.externalControl.valueChanges')),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((date) => {
        this.displayDate = moment(date).isValid()
          ? moment(date).format(this.getFormat())
          : '';
      });
  }

  private initFormGroup() {
    this.formGroup = this.fb.group([]);

    // Add date related controls
    if (this.showDate) {
      // Add date control
      this.formGroup.addControl(
        'date',
        this.fb.control('', [
          Validators.required,
          Validators.min(1),
          Validators.max(31),
          this.validateDate(),
        ])
      );

      // Add month control
      this.formGroup.addControl(
        'month',
        this.fb.control('', [
          Validators.required,
          Validators.min(0),
          Validators.max(11),
          this.validateMonth(),
        ])
      );

      const [minYear, maxYear] = this.yearRange.split(':');
      this.minYear = +minYear;
      this.maxYear = +maxYear;

      // Add year control
      this.formGroup.addControl(
        'year',
        this.fb.control('', [
          Validators.required,
          Validators.min(+minYear),
          Validators.max(+maxYear),
        ])
      );
    }

    // Add time related controls
    if (this.showTime) {
      // Add hour control
      this.formGroup.addControl(
        'hour',
        this.fb.control('', [
          Validators.required,
          Validators.min(1),
          Validators.max(12),
        ])
      );

      // Add minute control
      this.formGroup.addControl(
        'minute',
        this.fb.control('', [
          Validators.required,
          Validators.min(0),
          Validators.max(59),
        ])
      );

      // Add am/pm control
      this.formGroup.addControl(
        'amOrPm',
        this.fb.control('', [Validators.required])
      );
    }
  }

  private initFirstValue() {
    let dateToBeSet: Date | undefined;
    let setExternalInputValue: boolean = false;

    if (this.autoPopulate) {
      setExternalInputValue = true;
    }

    // Get value from external control
    if (this.externalControl.value) {
      dateToBeSet = this.externalControl.value;
      setExternalInputValue = true;
    }

    // Get value from current date
    if (!this.externalControl.value) {
      dateToBeSet = new Date();

      dateToBeSet.setUTCHours(0, 0, 0, 0);
    }

    if (dateToBeSet) {
      // Patch date values
      if (this.showDate) {
        this.formGroup!.patchValue({
          date: dateToBeSet.getDate(),
          month: dateToBeSet.getMonth(),
          year: dateToBeSet.getFullYear(),
        });
      }

      // Patch time values
      if (this.showTime) {
        this.formGroup!.patchValue({
          hour: 12,
          minute: 0,
          amOrPm: 'AM',
        });
      }
    }

    if (setExternalInputValue) {
      this.externalControl.patchValue(dateToBeSet);
    }
  }

  onOk() {
    if (this.formGroup?.invalid) {
      return;
    }

    let { date, month, year, minute, hour, amOrPm } = this.formGroup!.value;

    const newDate = moment().startOf('day');

    // Manipulate date related controls only if showDate is true
    if (this.showDate) {
      newDate.set('year', year);
      newDate.set('month', month);
      newDate.set('date', date);
    }

    // Manipulate time related controls only if showTime is true
    if (this.showTime) {
      newDate.set(
        'hour',
        amOrPm === 'AM' ? (hour === 12 ? 0 : hour) : hour < 12 ? hour + 12 : 12
      );
      newDate.set('minute', minute);
    }

    if (newDate.isValid()) {
      this.externalControl.patchValue(newDate.toDate());
      this.overlayPanelElement.hide();
    }
  }

  onCancel() {
    this.overlayPanelElement.hide();
  }

  private validateMonth(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const month = control.value;
      const date = this.formGroup?.get('date')?.value;

      if (Number.isInteger(month) && Number.isInteger(date)) {
        const daysInMonth = moment().set('month', month).daysInMonth();
        if (date > daysInMonth) {
          return {
            invalidateDate: true,
          };
        }
      }

      // Month and date are ok => clear current date error
      this.formGroup?.get('date')?.setErrors(null);

      return null;
    };
  }

  private validateDate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const date = control.value;
      const month = this.formGroup?.get('month')?.value;

      if (Number.isInteger(month) && Number.isInteger(date)) {
        const daysInMonth = moment().set('month', month).daysInMonth();
        if (date > daysInMonth) {
          return {
            invalidateDate: true,
          };
        }
      }

      // Month and date are ok => clear current month error
      this.formGroup?.get('month')?.setErrors(null);

      return null;
    };
  }

  private getFormat() {
    if (this.showDate && this.showTime) {
      return this.DATE_TIME_FORMAT;
    }
    if (this.showDate) {
      return this.DATE_FORMAT;
    }
    if (this.showTime) {
      return this.TIME_FORMAT;
    }

    return '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  
}
