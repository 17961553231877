<div *ngIf="isReady" class="flex flex-column">

  <div [formGroup]="form" class="formgrid grid">
    
    <div class="field col-6 mt-3">
    
      <label>Type:</label>
      <p-dropdown [options]="types | sort:'asc':'name'" formControlName="type" optionLabel="name" class="w-full"></p-dropdown>

    </div>

    <div class="field col-6 mt-3">

      <label for="input-name">Name:</label>
      <input id="input-name" type="text" formControlName="name" pInputText class="w-full">
      
    </div>

    <div class="col-12 mt-3">

      <app-address-with-heading 
        headingText="Address" 
        addressControlName="address"
      ></app-address-with-heading>
      
    </div>
    
  </div>

  <div class="actions-container flex-1 flex justify-content-end">
  
    <button *ngFor="let button of config.data.buttons" pButton pRipple type="button" [label]="button.label" [class]="button.class" [icon]="((button.icon) ? ('pi ' + button.icon) : '')" (click)="ref.close(button.key)"></button>
    
  </div>

</div>