import { Directive, HostListener, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Directive({
  selector: '[appClearReadOnly]'
})
export class ClearReadOnlyDirective implements OnInit, OnDestroy {

  @Input() appClearReadOnly!: any;
  @Input() readonly!: boolean;
  @Input() lineItemCount!: number;
  @Input() menuType!: string;

  constructor(
    private modalService: ModalService
  ) { }

  @HostListener('click', ['$event']) onClick(event: Event){
    if (this.readonly) {

      let title = 'Active Financial Line ' + ((this.lineItemCount > 1) ? 'Items' : 'Item') + ' Found';
      let copy = [
        'You must remove the ' + this.appClearReadOnly + ' line ' + ((this.lineItemCount > 1) ? 'items' : 'item') + ' before you can change the ' + ((this.menuType) ? this.menuType : 'Service Provider') + '.'
      ];

      this.modalService.generic({
        title: title,
        copy: copy,
        buttons: [
          { label: 'Close', key: 'close', class: '' }
        ]
      });
    }
  }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

}
