import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'documentSearch'
})
export class DocumentPipe implements PipeTransform {

  transform(documents: AbstractControl[], input: string): AbstractControl[] {

    if (!Array.isArray(documents) || !input) {
      return documents;
    }

    const filtered = documents.filter(document => {

      const documentValue = document.value;

      const documentName = (documentValue.name) ? documentValue.name.toLowerCase().includes(input.toLowerCase()) : false;

      if (documentName) {
        return true;
      } else {
        return false;
      }

    });

    return filtered;

  }

}
