import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormGenerator } from 'src/app/shared/forms/form-generator';
import { DynamicModel } from '../../dynamic-model';
import { DynamicFormStructure } from '../../dynamic-form-structure';
import { Service } from 'src/app/shared/classes/service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';

import { UniqueId } from 'src/app/shared/uniqueId';
import { stringToKey } from 'src/app/shared/string-to-key';
import { changeDetection } from 'src/app/shared/change-detection';
import { ModalService } from 'src/app/shared/services/modal.service';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { TieredMenu } from 'primeng/tieredmenu';
import { FileUpload } from 'primeng/fileupload';

import csvToJson from 'csvtojson';
import { Parser}  from 'json2csv';
import _ from 'lodash';
import { Table } from 'primeng/table';

import { GenericServiceProvider } from '../../classes/generic-service-provider';
import { SiteService } from 'src/app/shared/services/site.service';

@Component({
  selector: 'app-mortuary',
  templateUrl: './mortuary.component.html',
  styleUrls: ['./mortuary.component.scss']
})
export class MortuaryComponent extends GenericServiceProvider implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    _siteService: SiteService,
    _modalService: ModalService,
  ) {

    super(_siteService, _modalService);

  }

  ngOnInit(): void {

    const dynamicFormStructure = new DynamicFormStructure();

    super.ngOnInit(dynamicFormStructure.getMortuaryStructure());

  }

  ngAfterViewInit(): void {
    
    super.ngAfterViewInit();

  }

  ngOnDestroy(): void {
    
    super.ngOnDestroy();

  }

}