<div class="flex flex-column">

  <div class="content-container">

    <p *ngFor="let message of errorMessages">
      {{ message }}
    </p>
    
  </div>

  <div class="actions-container flex justify-content-end mt-3">
  
    <button pButton pRipple type="button" label="Close" class="p-button-danger" (click)="ref.close()"></button>
    
  </div>

</div>
