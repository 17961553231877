import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import _ from 'lodash';

@Pipe({
  name: 'isNotDeleted'
})
export class IsNotDeletedPipe implements PipeTransform {

  transform(value: AbstractControl | AbstractControl[] | null, log: boolean = false): AbstractControl[] {

    let response: AbstractControl[] = [];
    
    if (value && !Array.isArray(value)) {
      value = [value];
    }
    
    if (Array.isArray(value)) {
      response = value.filter(v => {

        const deleted = _.get(v.value, '_meta.deleted');

        if (deleted) {
          return false;
        } else {
          return true;
        }

      });
    }
    
    if (log) {
      // console.log('isNotDeletedPipe: ', value, response);
    }

    return response;

  }

}
