import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { FormGenerator } from 'src/app/shared/forms/form-generator';
import { FormCompilerCodeStructure, FormCompilerStructure } from 'src/app/shared/forms/form-structure';
import { UniqueId } from 'src/app/shared/uniqueId';
import { allRequiredOrNone } from 'src/app/shared/validators/allRequiredOrNone';

export interface CompilerPackage {
  variables: {
    path: string;
    name: string
  }[];
  code: string;
}

@Component({
  selector: 'app-compiler-compose',
  templateUrl: './compiler-compose.component.html',
  styleUrls: ['./compiler-compose.component.scss']
})
export class CompilerComposeComponent implements OnInit, AfterViewInit {

  @Output() submit: EventEmitter<CompilerPackage> = new EventEmitter<CompilerPackage>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() clear: EventEmitter<void> = new EventEmitter<void>();

  @Input() data: any;

  editorOptions = {
    scrollBeyondLastLine: false,
    automaticLayout: true,
    theme: 'vs', 
    language: 'javascript',
    minimap: {
      enabled: false,
    }
  };

  // form = this.formBuilder.group({
  //   variables: this.formBuilder.array([]),
  //   code: ['\ndone("this is the response") // You MUST call `done()` when complete.\n', Validators.required]
  // });

  form!: FormGroup;

  formReady: boolean;
  submitting: boolean;
  submitted: boolean;

  get variables() {

    return this.form.get('variables') as FormArray;

  }

  constructor(
    private formBuilder: FormBuilder
  ) {

    this.formReady = false;
    this.submitting = false;
    this.submitted = false;

  }

  ngOnInit(): void {

    this.generateForm();

    if (!this.data || _.isEmpty(this.data)) {

      this.form.get('code')!.setValue('\ndone("this is the response") // You MUST call `done()` when complete.\n');

      this.addVariable();
      
    }

  }
  
  ngAfterViewInit(): void { }

  /**
   * Add a new variable to the form
   */
  addVariable(event?: Event): void {

    const variable = this.formBuilder.group({
      _meta: new FormGroup({
        id: new FormControl(''),
        index: new FormControl(''),
      }),
      path: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
    }, {
      validators: [
        allRequiredOrNone(['path', 'name']),
      ]
    });

    const uniqueId = new UniqueId();

    variable.get('_meta.id')?.setValue(uniqueId.getUniqueInArray(this.variables.value, '_meta.id', 'id-'));
    variable.get('_meta.index')?.setValue(this.variables.length);

    this.variables.push(variable);

  }

  /**
   * Remove existing variable from form 
   * @param event 
   * @param index 
   */
  removeVariable(event: Event, index: number): void {

    event.preventDefault();

    this.variables.removeAt(index);

    for (let i = 0; i < this.variables.length; i++) {

      this.variables.at(i).get('_meta.index')?.patchValue(i);

    }

  }

  onSubmit(): void {

    this.submitted = true;

    if (this.form.invalid) {

      return;

    }

    this.submit.emit(this.form.value as CompilerPackage);

  }

  onCancel($event: Event): void {
    this.cancel.emit();
  }

  onClear($event: Event): void {
    this.clear.emit();
  }

  /**
   * Create our form
   */
  private generateForm() {

    const formGenerator = new FormGenerator();

    this.form = formGenerator.generate(FormCompilerCodeStructure, (this.data) ? this.data : {});

    this.formReady = true;

  }

}
