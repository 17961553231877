import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { ModalService } from "src/app/shared/services/modal.service";
import { GenericServiceProvider } from "../../classes/generic-service-provider";
import { DynamicFormStructure } from "../../dynamic-form-structure";
import { SiteService } from "src/app/shared/services/site.service";

@Component({
  selector: 'app-dynamic-coffin',
  templateUrl: './coffin.component.html',
  styleUrls: ['./coffin.component.scss'],
})
export class CoffinComponent extends GenericServiceProvider implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    _siteService: SiteService,
    _modalService: ModalService,
  ) {

    super(_siteService, _modalService);

  }

  ngOnInit(): void {

    const dynamicFormStructure = new DynamicFormStructure();

    super.ngOnInit(dynamicFormStructure.getCoffinStructure());

  }

  ngAfterViewInit(): void {
    
    super.ngAfterViewInit();

  }

  ngOnDestroy(): void {
    
    super.ngOnDestroy();

  }

}