export function placeAndStreetAreDifferent(place: string, street: string): boolean {

    const normalize = (str: string) => str.toLowerCase().replace(/road|rd|street|st|drive|dr|lane|ln/g, '').trim();

    let normalizedPlace = (place) ? normalize(place) : '';
    let normalizedStreet = (street) ? normalize(street): '';

    normalizedPlace = normalizedPlace.replace(/[\/0-9\s]+/g, '');
    normalizedStreet = normalizedStreet.replace(/[\/0-9\s]+/g, '');

    if (normalizedPlace) {
        normalizedPlace = normalizedPlace.toLowerCase();
    }

    if (normalizedStreet) {
        normalizedStreet = normalizedStreet.toLowerCase();
    }
    
    return normalizedPlace !== normalizedStreet;

}