import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class StorageCordovaService extends StorageService {

  constructor() {
    super();
  }

  create(table: string, rows: any[]) {
    throw new Error('Method not implemented.');
  }

  findByPk(table: string, id: number) {
    throw new Error('Method not implemented.');
  }

  findAll(table: string, options: any) {
    throw new Error('Method not implemented.');
  }

  findOne(table: string, options: any) {
    throw new Error('Method not implemented.');
  }

}
