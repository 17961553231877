import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

import { InputSignatureComponent } from './input-signature.component';

@NgModule({
  declarations: [
    InputSignatureComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    RippleModule,
  ],
  exports: [
    InputSignatureComponent
  ]
})
export class InputSignatureModule { }
