import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class AsyncHandlerService {
  private asyncHandlers: Map<string, Function>;

  constructor() {
    this.asyncHandlers = new Map();
  }

  addHandler(handler: Function) {
    const id = uuidv4();

    this.asyncHandlers.set(id, handler);

    return id;
  }

  hasHandler(id: string) {
    return this.asyncHandlers.has(id);
  }

  async executeHandler(id: string) {
    const handler = this.asyncHandlers.get(id);

    if (!handler) {
      console.warn(`Can't find the handler with id: ${id}`);

      return false;
    }

    await handler();

    this.asyncHandlers.delete(id);

    return true;
  }
}
