import { Pipe, PipeTransform } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import _ from 'lodash';

@Pipe({
  name: 'preparationTransfer'
})
export class PreparationTransferPipe implements PipeTransform {

  transform(transfersFormArray: FormArray): FormGroup | null {
    
    const transferIndex = _.findIndex(transfersFormArray.controls as FormGroup[], (transfer: FormGroup) => {

      const transferValue = transfer.value;
      
      if (_.get(transferValue, 'isPreparation') === true) {

        return true;

      }

      return false

    });

    return (transferIndex > -1) ? transfersFormArray.at(transferIndex) as FormGroup : null;

  }

}
