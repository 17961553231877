import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { easyFormatter } from "../easyFormatter";
import { Address, ArrangementResponse, Contact as ContactModel, ContactResponse, DateTime, Email, EmailType, Gender, Name, Phone, PhoneType, RelationToDeceased } from "../models/arrangement";
import { Arrangement } from "./arrangement";
import _ from 'lodash';
import { FormControl, FormGroup } from "@angular/forms";
import { FormGenerator } from "../forms/form-generator";
import { FormContactStructure } from "../forms/form-structure";
import { changeDetection } from "../change-detection";
import { UniqueId } from "../uniqueId";
import { relationshipTypesFormValue } from "../forms/form-values";
import { placeAndStreetAreDifferent } from '../place-and-street-are-different';

// export class Contact implements ContactModel {
export class Contact {

  private noNameLabel = 'Unnamed Contact';

  relationshipOptions = _.cloneDeep(relationshipTypesFormValue);

  noName!: boolean;

  arrangement: Arrangement | null;

  form!: FormGroup;

  _meta?: any;

  id!: string;

  isSaved: boolean;

  relationshipToDeceasedIsReady: boolean;

  get name(): string {
    return this.getName();
  }

  get relationships(): string[] {
    return this.getRelationshipsAsStringArray();
  }

  get email(): string {
    return this.getEmail();
  }

  get phone(): string {
    return this.getPhone();
  }

  get address(): string {
    return this.getAddress();
  }

  get mailingAddress(): string {
    return this.getMailingAddress();
  }

  constructor(
    private http: HttpClient | null,
    arrangement: Arrangement | null,
    data: ContactModel,
    isSaved: boolean = false,
  ) {

    this.arrangement = arrangement;

    this.isSaved = isSaved;

    this.relationshipToDeceasedIsReady = true;

    if (data) {

      this.postDataAsContact(data);

    }

    this.getName();

  }

  /**
   * Add a new relationship.
   * 
   * Note: We use `this.relationshipToDeceasedIsReady` because if we have multiple instances of the same contact on the screen
   * updating the relationship in instance A doesn't correctly update the display in instance B. Using `this.relationshipToDeceasedIsReady`
   * fixes this (even though I doubt this is a good practice)    
   */
  public addRelationshipToDeceased(relationshipToDeceased: { name: string, value: RelationToDeceased }): void {

    this.relationshipToDeceasedIsReady = false;
    
    const uniqueId = new UniqueId();

    const relationToDeceasedFormControl = this.form.get('relationToDeceased') as FormControl;

    if (relationToDeceasedFormControl) {

      const relationToDeceasedControlValue = relationToDeceasedFormControl.value;

      const id = uniqueId.getUniqueInArray(relationToDeceasedControlValue, '_meta.id', 'id-');
      const index = relationToDeceasedControlValue.length;

      relationToDeceasedControlValue.push({
        _meta: { id, index },
        name: relationshipToDeceased.name,
        value: relationshipToDeceased.value,
      });

      relationToDeceasedFormControl.patchValue(relationToDeceasedControlValue, { emitEvent: false });

    }

    this.populateRelationshipInputs();

    changeDetection(() => {
      this.relationshipToDeceasedIsReady = true;
    });

  }

  /**
   * Remove a relationship
   * 
   * Refer to note of `this.addRelationshipToDeceased()` for explanation regarding use of `this.relationshipToDeceasedIsReady`
   */
  public removeRelationshipToDeceased(relationsToDeceased: RelationToDeceased ): void {

    this.relationshipToDeceasedIsReady = false;

    const relationshipToDeceasedFormControl = this.form.get('relationToDeceased');

    if (relationshipToDeceasedFormControl) {

      const relationshipToDeceasedControlValue = relationshipToDeceasedFormControl.value;

      const relationshipToDeceasedIndex = _.findIndex(relationshipToDeceasedControlValue, (relationshipToDeceased: any) => relationshipToDeceased.value === relationsToDeceased);

      if (relationshipToDeceasedIndex > -1) {

        relationshipToDeceasedControlValue.splice(relationshipToDeceasedIndex, 1);
  
        relationshipToDeceasedFormControl.patchValue(relationshipToDeceasedControlValue);

        this.depopulateRelationshipInputs([relationshipToDeceasedControlValue[relationshipToDeceasedIndex]]);

      }

    }

    changeDetection(() => {
      this.relationshipToDeceasedIsReady = true;
    });

  };

  public removeAllRelationshipsToDeceased(): void {

    this.relationshipToDeceasedIsReady = false;

    const relationshipToDeceasedFormControl = this.form.get('relationToDeceased') as FormControl;

    if (relationshipToDeceasedFormControl) {

      const relationshipToDeceasedFormControlValue = relationshipToDeceasedFormControl.value;

      if (Array.isArray(relationshipToDeceasedFormControlValue)) {

        this.depopulateRelationshipInputs(relationshipToDeceasedFormControlValue);

      }

      relationshipToDeceasedFormControl.reset([], { emitEvent: false });
            
    }

    changeDetection(() => {
      this.relationshipToDeceasedIsReady = true;
    });

  }

  public populateRelationshipInputs(): void {
  
    const existingRelationships: { name: string, value: RelationToDeceased }[] = this.form.get('relationToDeceased')?.value;

    if (Array.isArray(existingRelationships)) {

      for (const existingRelationship of existingRelationships) {

        if (existingRelationship.value === RelationToDeceased.Informant) {

          if (this.arrangement?.form) {

            FormGenerator.createFormControl(
              this.arrangement.form, 
              '', 
              'keyContacts', 
              'keyContacts', 
              new FormGroup({}),
            );
            
            FormGenerator.createFormControl(
              this.arrangement.form, 
              'keyContacts', 
              'keyContacts.informant', 
              'informant', 
              new FormGroup({}),
            );

            this.arrangement.form.get('keyContacts.informant')?.patchValue(this.form.value);

          }

        } else if (existingRelationship.value === RelationToDeceased.NextOfKin) {

          if (this.arrangement?.form) {

            FormGenerator.createFormControl(
              this.arrangement.form, 
              '', 
              'keyContacts', 
              'keyContacts', 
              new FormGroup({})
            );

            FormGenerator.createFormControl(
              this.arrangement.form, 
              'keyContacts', 
              'keyContacts.nextOfKin', 
              'nextOfKin', 
              new FormGroup({})
            );

            this.arrangement.form.get('keyContacts.nextOfKin')?.patchValue(this.form.value);

          }

        } else if (existingRelationship.value === RelationToDeceased.Executor) {

          if (this.arrangement?.form) {

            FormGenerator.createFormControl(
              this.arrangement.form, 
              '', 
              'keyContacts', 
              'keyContacts', 
              new FormGroup({})
            );
            
            FormGenerator.createFormControl(
              this.arrangement.form, 
              'keyContacts', 
              'keyContacts.executor', 
              'executor', 
              new FormGroup({})
            );

            this.arrangement.form.get('keyContacts.executor')?.patchValue(this.form.value);
            
          }

        }

        // TODO: Look for children and add them to 'family.children'
        // TODO: Look for parents and add them to 'family.parents'
        // TODO: Look for spouses and add them to 'marriage.marriages'

      }

    }

  }

  public depopulateRelationshipInputs(relationships: { name: string, value: RelationToDeceased }[]): void {

    const formGenerator = new FormGenerator();

    if (Array.isArray(relationships)) {

      for (const existingRelationship of relationships) {

        const formGroup = formGenerator.generate(FormContactStructure, {});

        if (existingRelationship.value === RelationToDeceased.Informant) {

          this.arrangement?.form.get('keyContacts.informant')?.reset(formGroup, { emitEvent: false });

        } else if (existingRelationship.value === RelationToDeceased.NextOfKin) {

          this.arrangement?.form.get('keyContacts.nextOfKin')?.reset(formGroup, { emitEvent: false });

        } else if (existingRelationship.value === RelationToDeceased.Executor) {

          this.arrangement?.form.get('keyContacts.executor')?.reset(formGroup, { emitEvent: false });

        }

        // TODO: Look for children and add them to 'family.children'
        // TODO: Look for parents and add them to 'family.parents'
        // TODO: Look for spouses and add them to 'marriage.marriages'

      }

    }

  }

  private postDataAsContact(data: ContactModel) {

    const formStructure = _.cloneDeep(FormContactStructure);

    const formGenerator = new FormGenerator();

    const depreciatedEmail = _.get(data, 'email', []) as any[];
    const depreciatedPhone = _.get(data, 'phone', []) as any[];

    // If the Arrangement has the old email format (FormArray), convert it to a FormGroup
    if (depreciatedEmail.length) {

      const primary = _.find(depreciatedEmail, email => email.type.value === EmailType.Primary);
      const secondary = _.find(depreciatedEmail, email => email.type.value !== EmailType.Primary);

      if (primary) {
        _.set(data, 'email.primary', primary.email);
      }

      if (secondary) {
        _.set(data, 'email.secondary', primary.email);
      }

    }

    // If the Arrangement has the old phone format (FormArray), convert it to a FormGroup
    if (depreciatedPhone.length) {

      const primary = _.find(depreciatedPhone, phone => phone.type.value === PhoneType.Primary);
      const secondary = _.find(depreciatedPhone, phone => phone.type.value !== PhoneType.Primary);

      if (primary) {
        _.set(data, 'phone.primary', primary.phone);
      }

      if (secondary) {
        _.set(data, 'phone.secondary', primary.phone);
      }

    }

    if (!this.form) {
      this.form = new FormGroup({});
    }

    formGenerator.generate(formStructure, data, this.form);

    this.id = data.id;

    if (data._meta) {

      this._meta = data._meta;

    }

    if (this.isSaved) {

      this.form.markAllAsTouched();

    }

  }

  private getName(): string {

    const formValue = this.form.value;

    let fullName = this.noNameLabel;

    let firstName = _.get(formValue, 'name.first');
    let lastName = _.get(formValue, 'name.last');

    if (firstName || lastName) {

      fullName = ((firstName) ? firstName : '') + ' ' + ((lastName) ? lastName : '');

      this.noName = false;

    }

    return _.trim(fullName);

  }

  private getRelationshipsAsStringArray(): string[] {

    const formValue = this.form.value;

    const relationships: any[] = _.get(formValue, 'relationToDeceased', []);

    if (relationships === null) {
      return [];
    }

    if (!relationships.map) {
      console.error('The contact relationship isn\'t formatted correctly');
      return [];
    }

    return relationships.map(relationship => relationship.name);

  }

  private getEmail(): string {

    const formValue = this.form.value;

    const primary = _.get(formValue, 'email.primary');
    const secondary = _.get(formValue, 'email.secondary');

    return (primary) ? primary : secondary;

  }

  private getPhone(): string {

    const formValue = this.form.value;

    const primary = _.get(formValue, 'phone.primary');
    const secondary = _.get(formValue, 'phone.secondary');

    return (primary) ? primary : secondary;

  }

  private getAddressForm(addressFormValue: any): string {
    const address: string[] = [];

    const pushToAddress = (addressPart: string) => {
      if (addressPart) {
        address.push(addressPart)
      }
    }
  
    let place = '';
    let street = '';

    if (_.hasIn(addressFormValue, 'place') && addressFormValue.place) {
      place = addressFormValue.place;
    }

    if (_.hasIn(addressFormValue, 'street') && addressFormValue.street) {
      street = addressFormValue.street;
    }

    if (placeAndStreetAreDifferent(place, street) === true) {
      pushToAddress(place);
      pushToAddress(street);
    } else {
      pushToAddress(street);
    }

    if (_.hasIn(addressFormValue, 'suburb') && addressFormValue.suburb) {
      pushToAddress(addressFormValue.suburb);
    }

    if (_.hasIn(addressFormValue, 'state.value') && addressFormValue.state.value) {
      pushToAddress(addressFormValue.state.value);
    }

    if (_.hasIn(addressFormValue, 'postcode') && addressFormValue.postcode) {
      pushToAddress(addressFormValue.postcode);
    }

    if (_.hasIn(addressFormValue, 'country') && addressFormValue.country) {
      if (address.length) {
        pushToAddress(addressFormValue.country);
      }
    }

    return (address.length) ? address.join(', ') : 'N/A';  
  }

  private getAddress(): string {
    const formValue = this.form.value;

    const address = this.getAddressForm(formValue.address)

    return address;
  }

  private getMailingAddress(): string {
    const formValue = this.form.value;

    const address = this.getAddressForm(formValue.mailingAddress)

    return address;
  }

}
