import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { arraysToObjects } from '../array-fix/functions/array-to-object';
import { objectsToArrays } from '../array-fix/functions/object-to-array';
import { ServiceProvider } from '../classes/service-provider';
import { DynamicService } from '../dynamic/services/dynamic.service';
import { easyFormatter } from '../easyFormatter';
import { ServiceProvider as ServiceProviderModel, ServiceProviderRequest, ServiceProviderResponse } from '../models/service-provider';
import _ from 'lodash';
import { Service } from '../classes/service';
import { UserService } from './user.service';
import { SiteService } from './site.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderService {

  allServiceProviders$!: Observable<ServiceProvider[]>;
  globalServiceProviders$!: Observable<ServiceProvider[]>;

  private allServiceProvidersSource!: BehaviorSubject<ServiceProvider[]>;
  public globalServiceProvidersSource!: BehaviorSubject<ServiceProvider[]>;

  get allServiceProvidersSourceValue() {
    return this.allServiceProvidersSource.value;
  }

  constructor(
    private siteService: SiteService,
    private http: HttpClient,
    private dynamicService: DynamicService,
    private userService: UserService,
  ) {
    this.allServiceProvidersSource = new BehaviorSubject<ServiceProvider[]>([]);
    this.allServiceProviders$ = this.allServiceProvidersSource.asObservable();

    this.globalServiceProvidersSource = new BehaviorSubject<ServiceProvider[]>([]);
    this.globalServiceProviders$ = this.globalServiceProvidersSource.asObservable();
  }

  getAll(): Observable<ServiceProvider[]> {

    const url = environment.api.host + environment.api.paths.api.serviceProvider.get;

    this.siteService.addSubscriptionLog(this, 'service-provider.service.ts->getAll->this.http.get<ServiceProviderResponse>(url)');

    return this.http.get<ServiceProviderResponse>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('service-provider.service.ts->getAll->this.http.get<ServiceProviderResponse>(url)')),
      map(res => {

        const serviceProviders = (res.data as ServiceProviderModel[]).map(d => {
          
          const serviceProvider = new ServiceProvider(
            this.siteService,
            this.http,
            this.dynamicService,
            this.userService,
            objectsToArrays(d)
          );

          serviceProvider.isPartial = true;

          return serviceProvider;
          
        });

        this.allServiceProvidersSource.next(serviceProviders);

        this.globalServiceProvidersSource.next(
          serviceProviders.filter(service => service.isGlobal)
        );

        return serviceProviders;

      })
    );

  }

  create(): ServiceProvider {

    let serviceProviders = this.allServiceProvidersSource.value;

    const serviceProvider = new ServiceProvider(
      this.siteService,
      this.http,
      this.dynamicService,
      this.userService,
    );

    serviceProviders.push(serviceProvider);

    this.allServiceProvidersSource.next(serviceProviders);

    return serviceProvider;

  }

  refreshSource(): Promise<ServiceProvider[]> {

    return this.getAll().toPromise();

  }

  getServiceById(serviceProviderId: number, serviceId: string): Service | null {

    const serviceProvider = _.find(this.allServiceProvidersSourceValue, serviceProvider => serviceProviderId === serviceProvider.id);

    if (serviceProvider) {

      const service: Service | null = serviceProvider.getServiceById(serviceId);

      return service

    }

    return null;

  }

  get(id: number): Observable<ServiceProviderModel> {

    const url = easyFormatter(environment.api.host + environment.api.paths.api.serviceProvider.getById, { serviceId: id.toString() }) ;

    this.siteService.addSubscriptionLog(this, 'service-provider.service.ts->get->this.http.get<ServiceProviderResponse>(url)');

    return this.http.get<ServiceProviderResponse>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('service-provider.service.ts->get->this.http.get<ServiceProviderResponse>(url)')),
      map(res => {

        const serviceProvider = res.data as ServiceProviderModel;

        return serviceProvider;

      })
    );

  }

  post(data: any): Observable<ServiceProvider> {

    const url = environment.api.host + environment.api.paths.api.serviceProvider.post;

    const arrayToObject = arraysToObjects(data);

    this.siteService.addSubscriptionLog(this, 'service-provider.service.ts->post->this.http.post<ServiceProviderRequest>(url, arrayToObject)');

    return this.http.post<ServiceProviderRequest>(url, arrayToObject).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('service-provider.service.ts->post->this.http.post<ServiceProviderRequest>(url, arrayToObject)')),
      map(res => {

        const serviceProvider = res.data as ServiceProvider;

        return serviceProvider;

      })
    );

  }

  patch(id: number, data: any): Observable<ServiceProvider> {

    const url = easyFormatter(environment.api.host + environment.api.paths.api.serviceProvider.patch, { id: id.toString() });

    const arrayToObject = arraysToObjects(data);

    this.siteService.addSubscriptionLog(this, 'service-provider.service.ts->patch->this.http.patch<ServiceProviderRequest>(url, arrayToObject)');

    return this.http.patch<ServiceProviderRequest>(url, arrayToObject).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('service-provider.service.ts->patch->this.http.patch<ServiceProviderRequest>(url, arrayToObject)')),
      map(res => {

        const serviceProvider = res.data as ServiceProvider;

        return serviceProvider;

      })
    );

  }

  delete(id: number): Observable<boolean> {

    const url = easyFormatter(environment.api.host + environment.api.paths.api.serviceProvider.delete, { id: id.toString() });

    this.siteService.addSubscriptionLog(this, 'service-provider.service.ts->delete->this.http.delete<ServiceProviderRequest>(url)');

    return this.http.delete<ServiceProviderRequest>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('service-provider.service.ts->delete->this.http.delete<ServiceProviderRequest>(url)')),
      map(res => {

        const bool = res.data as boolean;

        return bool;

      })
    );

  }

}
