<div class="flex flex-column">

  <div class="content-container">

    <p *ngFor="let message of config.data.copy" [innerHTML]="message | safe:'html'"></p>

    <ul *ngIf="config.data.list && config.data.list.length">
      <li *ngFor="let html of config.data.list" [innerHTML]="html | safe:'html'"></li>
    </ul>
    
  </div>

  <div class="actions-container flex justify-content-end mt-3">
  
    <button *ngFor="let button of config.data.buttons" pButton pRipple type="button" [label]="button.label" [class]="button.class" [icon]="((button.icon) ? ('pi ' + button.icon) : '')" (click)="onClick(button)" [loading]="button.loading"></button>
    
  </div>

</div>
