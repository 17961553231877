import { FormGroup, FormArray, FormControl } from '@angular/forms';

export function findInvalidFormControls(form: FormGroup | FormArray): string[] {

  let invalidControls: string[] = [];

  const recursiveFn = (form: FormGroup | FormArray, path: string) => {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      const currentPath = path ? `${path}.${field}` : field;

      if (control instanceof FormControl) {
        if (control.invalid) {
          invalidControls.push(currentPath);
        }
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        recursiveFn(control, currentPath);
      }
    });
  };

  recursiveFn(form, '');
  
  return invalidControls;

}