import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameComponent } from './name.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { DropdownModule } from 'primeng/dropdown';
import { SelectModule } from '../select/select.module';
import { FormDirectivesModule } from '../../directives/form-directives.module';
import { PipeModule } from 'src/app/shared/pipe.module';

@NgModule({
  declarations: [
    NameComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    RippleModule,
    InputTextModule,
    SelectModule,
    FormDirectivesModule,
    PipeModule,
  ],
  exports: [NameComponent]
})
export class NameModule { }
