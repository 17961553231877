import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegisterRoutingModule } from './register-routing.module';
import { RegisterComponent } from './register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { StyleClassModule } from 'primeng/styleclass';
import { MultiSelectModule } from 'primeng/multiselect';
import { UsersListComponent } from './users-list/users-list.component';
import { MessageModule } from 'primeng/message';
import { PipeModule } from 'src/app/shared/pipe.module';
import { AvatarModule } from 'primeng/avatar';
import { AvatarModule as AppAvatar } from '../../shared/features/avatar/avatar.module';
import {SkeletonModule} from 'primeng/skeleton';
import { ActivateAccountModalComponent } from './activate-account-modal/activate-account-modal.component';


@NgModule({
  declarations: [
    RegisterComponent,
    UsersListComponent,
    ActivateAccountModalComponent
  ],
  imports: [
    CommonModule,
    RegisterRoutingModule,
    ReactiveFormsModule,
    StyleClassModule,
    InputTextModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule,
    DropdownModule,
    InputTextareaModule,
    PasswordModule,
    MultiSelectModule,
    MessageModule,
    PipeModule,
    AvatarModule,
    AppAvatar,
    FormsModule,
    SkeletonModule
  ],
  exports:[RegisterComponent]
})
export class RegisterModule { }
