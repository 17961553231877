<ng-container *ngIf="(account$ | async) as account">

  <p-table 
    #charitiesTable 
    [value]="account.data.charities" 
    [globalFilterFields]="['name']"
    styleClass="mt-5 p-datatable-striped" 
    dataKey="_meta.id" 
    responsiveLayout="scroll"
  >

    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="charitiesTable.filterGlobal($any($event).target.value, 'contains')" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="body" let-charity>
      <tr [pSelectableRow]="charity">
        <td class="charity-text">{{ charity.name }}</td>
        <td class="charity-actions">
          <button pButton pRipple type="button" icon="pi pi-pencil" (click)="onEditCharity($event, charity)" class="p-button-rounded p-button-text"></button>
          <button *ngIf="!charity.disabled" pButton pRipple type="button" icon="pi pi-trash" (click)="onDeleteCharity($event, charity)" class="p-button-rounded p-button-danger p-button-text"></button>
          <button *ngIf="charity.disabled" pButton pRipple type="button" icon="pi pi-refresh" (click)="onRestoreCharity($event, charity)" class="p-button-rounded p-button-success p-button-text"></button>
        </td>
      </tr>
    </ng-template>

  </p-table>

</ng-container>