import { Injectable } from '@angular/core';
import { Subscription, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class ArrangementUpdatedGQLService extends Subscription {
  document = gql`
    subscription onNotifyArrangementUpdated {
      onNotifyArrangementUpdated {
        arrangementId
      }
    }
  `;
}
