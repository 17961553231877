import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-input-text-input',
  templateUrl: './input-text-input.component.html',
  styleUrls: ['./input-text-input.component.scss']
})
export class InputTextInputComponent implements OnInit {

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
  }

}
