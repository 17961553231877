import _ from "lodash";

const objectsToArrays = function(objectToArray: any, isOriginInstance: boolean = true) {

  const reIndexArray = (arr: any[]) => {

    const preLength = arr.length;

    arr = arr.filter(a => true);

    const postLength = arr.length;

    if (preLength !== postLength) {
      
      arr = arr.map((a, i) => {
        if (a._meta) {
          a._meta.index = i;
        }
        return a;
      });

    }

    return arr;

  };

  let data = _.cloneDeep(objectToArray);

  if (_.isPlainObject(data)) {

    let isArrayLikeObject = false;

    for (const key in data) {

      if (data.hasOwnProperty(key)) {

        const pathHastMeta = _.has(data[key], '_meta');

        if (pathHastMeta) { // Convert back to array

          isArrayLikeObject = true;

          if (_.has(data[key], '_meta.value')) {

            data[data[key]._meta.index] = data[key]._meta.value;

          } else {
            
            data[data[key]._meta.index] = data[key];
            
          }
          
          data[data[key]._meta.index] = objectsToArrays(data[key], false);
          
          delete data[key];

        } else {

          data[key] = objectsToArrays(data[key], false);

        }

      }

    }

    // Bugfix: Convert object to array
    if (isArrayLikeObject) {

      let array: any[] = [];

      for (const key in data) {

        if (data.hasOwnProperty(key)) {

          array[+key] = data[key];

        }

      }

      data = array;

    }

    if (_.isArray(data)) {

      data = reIndexArray(data);

    }

    return data;

  } else {

    if (_.isArray(data)) {

      data = reIndexArray(data);

    }

    return data;

  }

};

export { objectsToArrays };