import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CompilerPackage } from '../../features/compiler/compiler-compose/compiler-compose.component';

@Component({
  selector: 'app-compiler',
  templateUrl: './compiler.component.html',
  styleUrls: ['./compiler.component.scss']
})
export class CompilerComponent implements OnInit {

  compiler: any;

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) {
    this.compiler = null;
  }

  ngOnInit(): void {
    this.compiler = this.config.data;
  }

  onCompilerSubmit(data: CompilerPackage): void {
    this.ref.close(data);
  }

  onCompilerCancel(event: any): void {
    this.ref.close(null);
  }
  
  onCompilerClear(event: any): void {
    this.ref.close(-1);
  }

}
