import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountHospitalAndNursingHomeListComponent } from './account-hospital-and-nursing-home-list.component';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';


@NgModule({
  declarations: [AccountHospitalAndNursingHomeListComponent],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    RippleModule,
    InputTextModule,
  ],
  exports: [AccountHospitalAndNursingHomeListComponent],
})
export class AccountHospitalAndNursingHomeListModule { }
