import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { FormCompilerStructure, FormStructure, FormStructureType, FormStructureValueType } from "./form-structure";
import moment from "moment";
import _ from "lodash";
import { UniqueId } from "../uniqueId";
import { environment } from "src/environments/environment";
import { ConvertToDate } from "../dates";
import { debug } from "console";

class FormGenerator {

  constructor() { }

  static addToFormArray(formArray: FormArray, formGroup: FormGroup, data?: any): void {

    const uniqueId = new UniqueId();

    const id: string = uniqueId.getUniqueInArray(formArray.value, '_meta.id', 'id-');
    const index: number = formArray.value.length;

    // Only add `_meta` if the formGroup doesn't have it already
    if (formGroup.get('_meta') === null) {

      formGroup.addControl('_meta', new FormGroup({
        id: new FormControl(),
        index: new FormControl(),
        deleted: new FormControl(),
      }));

    }

    if (data && data._meta !== undefined) {

      console.error(`You provided a _meta property when adding a new item to a FormArray. That isn't the correct procedure for using FormGenerator.addToFormArray(). The _meta data will be added automatically.`);

    }

    if (!data) {

      formGroup.patchValue({ _meta: { id, index } });

      formArray.push(formGroup);

    } else {

      formGroup.patchValue(_.assign(data, { _meta: { id, index } }));

      formArray.push(formGroup);

    }

  }

  static createFormControl(target: FormGroup, basePath: string, targetPath: string, name: string, control: FormControl | FormGroup | FormArray): void {

    if (!target.get(targetPath)) {
      const baseFormGroup = ((basePath) ? target.get(basePath) : target) as FormGroup;
      if (baseFormGroup) {
        baseFormGroup.addControl(name, control);
      } else {
        console.error(`We don't have a targetPath of '${targetPath}'. When we tried to add the new control '${basePath}' we couldn't get it.`);
      }
    } else {
      // console.warn(`Control '${name}' for path '${targetPath}' already exists.`);
    }

  }

  generate(structure: FormStructure, data: { [key: string]: any }, form?: FormGroup): FormGroup {

    if (!form) {

      form = new FormGroup({});

    }

    for (const key in structure) {

      if (structure.hasOwnProperty(key)) {

        const structureItem = structure[key];
        const path = key;

        if (structureItem.type === FormStructureType.FormControl) {

          let controlValue = null;

          if (_.has(data, path)) {

            controlValue = _.get(data, path);
          
          } else if (structureItem.value !== undefined) {

            controlValue = structureItem.value;

          }

          if (controlValue && structureItem.valueAs) {

            controlValue = this.parseFormStructureValueAs(controlValue, structureItem.valueAs);

          }

          // Check if form already has the control
          if (form.get(key)) {

            // If it does, patch the value
            form.get(key)?.patchValue(controlValue, { emitEvent: false });

          } else {

            // If it doesn't, add the control
            form.addControl(key, new FormControl(controlValue), { emitEvent: false });

          }

        } else if (structureItem.type === FormStructureType.FormArray) {


          // TODO:
          // 1.a Check if we already have a form array
          // 1.b If we do, set it as the destination form array
          // 1.c If we don't, create a new form array 

          // 2.a Start looping through the data
          // 2.b Check if we have a form group at the form array index
          // 2.c If we do, use it as the source for the generate function
          // 2.d If we don't, create a new form group and use it as the source for the generate function

          // 3.a If we created the form group in step 2.d, add it to the form array

          // 4.a If we created the form array in step 1.c, add it to the form

          // 1.a, 1.b, 1.c
          let mustAddFormArray = true;
          let destinationFormArray = new FormArray([]);

          if (form.get(key) instanceof FormArray) {
            
            destinationFormArray = form.get(key) as FormArray;

            mustAddFormArray = false;

          }

          // 2.a
          if (_.has(data, path)) {

            let dataArrayValue = _.get(data, path);

            // Convert the value to an array if it isn't already
            if (!_.isArray(dataArrayValue)) {

              dataArrayValue = [dataArrayValue];

            }

            for (const dataArrayValueIndex in dataArrayValue) {

              const dataValue = dataArrayValue[dataArrayValueIndex];

              let mustAddFormGroup = true;

              let formGroup;

              // 2.b
              if (destinationFormArray.get(dataArrayValueIndex)) {

                // 2.c
                formGroup = destinationFormArray.get(dataArrayValueIndex) as FormGroup;

                mustAddFormGroup = false;

              } else {

                // 2.d
                formGroup = new FormGroup({});

              }

              this.generate(structureItem.value as FormStructure, dataValue, formGroup);

              if (mustAddFormGroup) {

                // 3.a
                destinationFormArray.push(formGroup);

              }

            }

          }

          // 4.a
          if (mustAddFormArray) {

            form.addControl(key, destinationFormArray);

          }

        } else if (structureItem.type === FormStructureType.FormGroup) {

          const formGroupData = (_.has(data, path)) ? data[path] : {};

          // I don't think this is needed anymore but lets keep it for now
          if (formGroupData && formGroupData.compiler) {
            
            _.assign(structureItem.value, _.cloneDeep(FormCompilerStructure));

          }

          // Check if form already has the control
          if (form.get(key)) {

            const destinationFormGroup = form.get(key) as FormGroup;

            let keysA = Object.keys(destinationFormGroup.value);
            let keysB = new Set(Object.keys(formGroupData));
            
            if (keysB.size > 0) {

              // Find keys in 'a' that are not in 'b'
              let missingKeys = keysA.filter(key => !keysB.has(key));
              
              for (const missingKey of missingKeys) {
                
                const v = destinationFormGroup.get(missingKey)?.value;

                let keys: string[] = [];
                let isArray: boolean = false;
                let isDate: boolean = false;

                if (v) {

                  keys = Object.keys(v);
                  isArray = Array.isArray(v);
                  isDate = v instanceof Date;

                }

                if (v && keys.length === 0 && isArray === false && isDate === false) {

                  if (typeof v !== 'object') {

                    console.error('We have a missing key and the value is empty. Should remove the control or set it to null?');
  
                    // I think setting the value to null is better than removing the control
                    if (destinationFormGroup.get(missingKey)?.value) {
                      destinationFormGroup.get(missingKey)?.patchValue(null, { emitEvent: false });
                    }
    
                    // destinationFormGroup.removeControl(missingKey);

                  }

                }

              }

            }

            this.generate(structureItem.value as FormStructure, formGroupData, destinationFormGroup);

          } else {

            const formGroup = this.generate(structureItem.value as FormStructure, formGroupData);
    
            form.addControl(key, formGroup);

          }

        } else {

          console.error(`The supplied FormStructureType (${structureItem.type}) isn't supported by generateForm()`);

        }

      }

    }

    return form;

  }

  private parseFormStructureValueAs(value: any, type: FormStructureValueType): any {

    if (type === FormStructureValueType.Date) {

      if (!value) {

        // console.warn('Value should have been a date, but the value wasn\'t set.', value);

        return value;

      }

      const date = ConvertToDate(value);

      return date;

    } else {

      console.error(`The supplied FormStructureValueType (${type}) isn't supported by parseFormStructureValueAs()`);

    }

  }

}

export { FormGenerator }