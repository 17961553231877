import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

export class UniqueId {

  characterList: string[];

  indexes: number[];

  constructor() {

    this.characterList = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    this.indexes = [-1];

  }

  test() {

    let ids = [];

    for (let i = 0; i < 1000; i++) {

      ids.push(this.generateId());
      
    }

    // console.log('UniqueId.text(): ', ids);

  }

  testArray() {

    const array = [
      { _meta: { id: 'key-a', index: 0}, val: 'one' },
      { _meta: { id: 'key-b', index: 1}, val: 'two' },
      { _meta: { id: 'key-c', index: 2}, val: 'three' },
      { _meta: { id: 'key-d', index: 3}, val: 'four' },
      { _meta: { id: 'key-e', index: 4}, val: 'five' },
      { _meta: { id: 'key-f', index: 5}, val: 'six' },
      { _meta: { id: 'key-g', index: 6}, val: 'seven' },
      { _meta: { id: 'key-h', index: 7}, val: 'eight' },
      { _meta: { id: 'key-i', index: 8}, val: 'nine' },
    ];

    const id = this.getUniqueInArray(array, '_meta.id', 'key-');

    // console.log('UniqueId.testArray(): ', id);

  }

  testObject() {

    const object = {
      a: { _meta: { id: 'key-a', index: 0}, val: 'one' },
      b: { _meta: { id: 'key-b', index: 1}, val: 'two' },
      c: { _meta: { id: 'key-c', index: 2}, val: 'three' },
      d: { _meta: { id: 'key-d', index: 3}, val: 'four' },
      e: { _meta: { id: 'key-e', index: 4}, val: 'five' },
      f: { _meta: { id: 'key-f', index: 5}, val: 'six' },
    };

    const id = this.getUniqueInObject(object, '_meta.id', 'key-');

    // console.log('UniqueId.testObject(): ', id);

  }

  get(prefix?: string) {

    return (prefix) ? prefix + this.generateId() : this.generateId();

  }

  getUniqueInArray(array: any[], path: string, prefix: string): string {

    let uniqueStr: string = '';
    const mapped = _.map(array, a => _.get(a, path));

    do {

      // uniqueStr = this.generateId();
      uniqueStr = this.uuidv4();

    } while(_.includes(mapped, prefix + uniqueStr));

    return prefix + uniqueStr;

  }

  getUniqueInObject(obj: any, path: string, prefix: string): string {

    let uniqueStr: string = '';
    const mapped = _.map(obj, a => _.get(a, path));

    do {

      // uniqueStr = this.generateId();
      uniqueStr = this.uuidv4();

    } while(_.includes(mapped, prefix + uniqueStr));

    return prefix + uniqueStr;

  }

  uuidv4() {

    return uuidv4();

  }

  private generateId(): string {

    const characterListLastIndex = this.characterList.length - 1;
    const indexesLastIndex = this.indexes[this.indexes.length - 1];

    if(indexesLastIndex >= characterListLastIndex) {

      let index = this.indexes.length;
      let iterateNextIndex = false;

      let maxIndexCount = 0;

      while(index--) {
        
        if (this.indexes[index] >= characterListLastIndex) {

          iterateNextIndex = true;

          maxIndexCount++;

          this.indexes[index] = 0;

        } else {

          if (iterateNextIndex) {

            this.indexes[index]++;

            iterateNextIndex = false;

          }

        }

      }

      if (maxIndexCount === this.indexes.length) {

        this.indexes.push(0);

      }

    } else {

      this.indexes[this.indexes.length - 1]++;

    }

    const array = this.indexes.map(index => {
      return this.characterList[index];
    });

    const str = array.join('');

    return str;

  }

}