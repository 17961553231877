import { Pipe, PipeTransform } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { eventTransferType } from './forms/form-enum';
import _ from 'lodash';

@Pipe({
  name: 'eventTransfer'
})
export class EventTransferPipe implements PipeTransform {

  transform(transfersFormArray: FormArray, eventId: string, type: eventTransferType): FormGroup | null {
    
    const transferIndex = _.findIndex(transfersFormArray.controls as FormGroup[], (transfer: FormGroup) => {

      const transferValue = transfer.value;
      
      if (_.get(transferValue, 'event.id') === eventId && _.get(transferValue, 'event.type') === type) {

        return true;

      }

      return false

    });

    return (transferIndex > -1) ? transfersFormArray.at(transferIndex) as FormGroup : null;

  }

}
