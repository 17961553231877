import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BdmNswAuthenticateComponent } from './bdm-nsw-authenticate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    BdmNswAuthenticateComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    PasswordModule,
    DropdownModule,
    ButtonModule,
  ],
  exports: [
    BdmNswAuthenticateComponent
  ]
})
export class BdmNswAuthenticateModule { }
