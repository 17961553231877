import _ from "lodash";
import moment, { Moment } from "moment";
import { environment } from "src/environments/environment";

export function ConvertToDate(v: any): Date | null {

  let date: Date | null = null;

  const dateFormat = environment.dbDateFormat;

  if (moment.isDate(v)) {

    date = v;
    
  } else if (moment.isMoment(v)) {

    date = v.toDate();
    
  } else {

    const d = moment(v, [dateFormat, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'], true);

    if (d.isValid()) {

      date = d.toDate();

    }

  }

  return date;

}

export function ConvertToMoment(v: any): Moment | null {

  let date: Moment | null = null;

  const dateFormat = environment.dbDateFormat;

  if (moment.isDate(v)) {

    date = moment(v);
    
  } else if (moment.isMoment(v)) {

    date = v;
    
  } else {

    const d = moment(v, [dateFormat, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'], true);

    if (d.isValid()) {

      date = d;

    }

  }

  return date;

}

export function ConvertToDateString(v: any): string | null {

  const dateFormat = environment.dbDateFormat;

  let dateString: string | null = null;

  if (moment.isDate(v)) {

    dateString = moment(v).format(dateFormat);
    
  } else if (moment.isMoment(v)) {

    dateString = v.format(dateFormat);
    
  } else {

    const date = moment(v, dateFormat, true);

    if (date.isValid()) {

      dateString = v;

    }

  }

  return dateString;

}