import { FormGroup } from "@angular/forms";
import _ from "lodash";
import { AddressToStringPipe } from "./address-to-string.pipe";

export function hasValidAddress(address: any): boolean {
  
  let addressData = {};

  if (address instanceof FormGroup) {
    addressData = address.value;
  } else {
    addressData = _.cloneDeep(address);
  }

  const addressToStringPipe = new AddressToStringPipe();

  const addressString = addressToStringPipe.transform(addressData);

  return addressString !== 'N/A' && addressString !== '';

}