import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GroupResponse, RegionRole, User, UserResponse, UserStatus } from '../models/user';
import { UserStatusGQLService } from './graphql/user-status-gql.service';
import { MessageGQLService } from './graphql/message-gql.service';
import { AuthenticationService } from './authentication.service';
import { User as UserClass } from '../classes/user';
import _ from 'lodash';
import { SiteService } from './site.service';
import { Notification } from '../models/user';
import { Groups } from '../classes/group';
import { UserNotificationGqlService } from './graphql/user-notification-gql.service';
import { MessageGroupsGQLService } from './graphql/message-groups-gql';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  allUsers$!: Observable<UserClass[]>;
  users$!: Observable<UserClass[]>;
  groups$!: Observable<Groups[]>;
  user$!: Observable<UserClass>;
  notifications$!: Observable<Notification[]>;
  notificationCount$!: Observable<number>;
  regions$!: Observable<(RegionRole)[]>;
  roles$!: Observable<(RegionRole)[]>;

  private allUsersSource!: BehaviorSubject<UserClass[]>;
  private usersSource!: BehaviorSubject<UserClass[]>;
  public notificationsSource: BehaviorSubject<(Notification)[]>;
  public notificationsCountSource: BehaviorSubject<number>;
  public regionsSource: BehaviorSubject<(RegionRole)[]>;
  public rolesSource: BehaviorSubject<(RegionRole)[]>;
  private userSource!: BehaviorSubject<UserClass>;
  private groupSource!: BehaviorSubject<Groups[]>;


  get allUsersSourceValue() {
    return this.allUsersSource.value;
  }

  get usersSourceValue() {
    return this.usersSource.value;
  }

  get userSourceValue() {
    return this.userSource.value;
  }

  get groupSourceValue() {
    return this.groupSource.value;
  }

  constructor(
    private http: HttpClient,
    private userStatusGQLService: UserStatusGQLService,
    private messageGQLService: MessageGQLService,
    private messageGroupsGQLService: MessageGroupsGQLService,
    private notificationGqlService: UserNotificationGqlService,
    private authenticationService: AuthenticationService,
    private siteService: SiteService,
  ) {

    this.allUsersSource = new BehaviorSubject<UserClass[]>([]);
    this.usersSource = new BehaviorSubject<UserClass[]>([]);
    this.groupSource = new BehaviorSubject<Groups[]>([]);
    this.notificationsSource = new BehaviorSubject<Notification[]>([]);
    this.notificationsCountSource = new BehaviorSubject(0);
    this.regionsSource = new BehaviorSubject<RegionRole[]>([]);
    this.rolesSource = new BehaviorSubject<RegionRole[]>([]);
    
    this.allUsers$ = this.allUsersSource.asObservable();
    this.users$ = this.usersSource.asObservable();
    this.groups$ = this.groupSource.asObservable();
    this.notifications$ = this.notificationsSource.asObservable();
    this.notificationCount$ = this.notificationsCountSource.asObservable();
    this.regions$ = this.regionsSource.asObservable();
    this.roles$ = this.rolesSource.asObservable();

  }

  set(user: User) {

    const u = new UserClass(this.http, this.siteService, user);

    u.isAuthenticatedUser = true; // For now, this MUST be before the set message subscription call

    u.setStatusObservable(this.userStatusGQLService.subscribe({ usernames: [u.data.email] }));
    u.setMessageObservable(this.messageGQLService.subscribe({ recipientIds: [u.data.id] }));
    u.setNotificationObservable(this.notificationGqlService.subscribe({ recipientId: u.data.id }));

    // u.getUnreadMessageCount().subscribe(unreadMessageCount => {
      // console.log(unreadMessageCount);
    // });

    if (!this.userSource) {

      this.userSource = new BehaviorSubject<UserClass>(u);
      this.user$ = this.userSource.asObservable();

    } else {

      this.userSource.next(u);

    }

  }

  getRegions():Observable<RegionRole[]>{
    const url = environment.api.host + environment.api.paths.api.user.getRegions;

    this.siteService.addSubscriptionLog(this, 'user.service.ts->getRegions->this.http.get<{regions:RegionRole[]}>(url)');

    return this.http.get<{regions:RegionRole[]}>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('user.service.ts->getRegions->this.http.get<{regions:RegionRole[]}>(url)')),
      map(res => {

        const regions = res.regions;

        this.regionsSource.next(regions);

        return regions;

      })
    );
  }

  getRoles():Observable<RegionRole[]>{
    const url = environment.api.host + environment.api.paths.api.user.getRoles;

    this.siteService.addSubscriptionLog(this, 'user.service.ts->getRoles->this.http.get<{roles:RegionRole[]}>(url)');

    return this.http.get<{roles:RegionRole[]}>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('user.service.ts->getRoles->this.http.get<{roles:RegionRole[]}>(url)')),
      map(res => {

        const roles = res.roles;

        this.rolesSource.next(roles);

        return roles;

      })
    );
  }

  retrieveAll(): Observable<UserClass[]> {

    const url = environment.api.host + environment.api.paths.api.user.getAll;

    this.siteService.addSubscriptionLog(this, 'user.service.ts->retrieveAll->this.http.get<UserResponse>(url)');

    return this.http.get<UserResponse>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('user.service.ts->retrieveAll->this.http.get<UserResponse>(url)')),
      map(res => {

        const users = this.generateUsers(res);

        this.usersSource.next(users);

        const t = [this.userSourceValue, ...users];

        this.allUsersSource.next(t);

        return users;

      })
    );

  }

  retrieveAllGroups(): Observable<Groups[]> {

    const url = environment.api.host + environment.api.paths.api.message.groups.getAll;

    this.siteService.addSubscriptionLog(this, 'user.service.ts->retrieveAllGroups->this.http.get<GroupResponse>(url)');

    return this.http.get<GroupResponse>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('user.service.ts->retrieveAllGroups->this.http.get<GroupResponse>(url)')),
      map(res => {

        const groups = this.generateGroups(res);

        this.groupSource.next(groups);

        return groups;

      })
    );

  }

  getByEmail(email: string): UserClass | undefined {

    return _.find(this.usersSource.value, user => user.data.email.toLowerCase() === email.toLowerCase());

  }

  getById(id: number): UserClass | undefined {

    const user = _.find(this.allUsersSource.value, user => user.data.id === id);

    return user;

  }

  setStatus(status: UserStatus, user?: User) {

    const url = environment.api.host + environment.api.paths.api.user.patchStatus;
    const authenticatedUser = this.authenticationService.currentUserValue;

    if (!user && authenticatedUser) {

      user = authenticatedUser;

    }

    if (!user || !user.email) {

      return;

    }

    if (user.status === status) {

      return;

    }

    user.status = status;

    const data = {
      username: user.email,
      status,
    };

    this.siteService.addSubscriptionLog(this, 'user.service.ts->setStatus->this.http.patch<any>(url, data)');

    this.http.patch<any>(url, data).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('user.service.ts->setStatus->this.http.patch<any>(url, data)')),
      map(res => res)
    ).subscribe();

  }

  setNotifications(user: User){
    const url = environment.api.host + environment.api.paths.api.notification.getAll;

     this.http.get<any>(url).subscribe((res)=>{
      this.notificationsSource.next(res);
      this.notificationsCountSource.next(res.filter((notification:Notification)=>notification.status===0).length)
     });
  }

  sendMessage(recipientUser: UserClass, message: string) {

    const url = environment.api.host + environment.api.paths.api.user.patchMessage;

    const data = { friend: recipientUser.data.email, message };

    this.siteService.addSubscriptionLog(this, 'user.service.ts->sendMessage->this.http.patch<any>(url, data)');

    this.http.patch<any>(url, data).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('user.service.ts->sendMessage->this.http.patch<any>(url, data)')),
      map(res => res)
    ).subscribe();

  }

  sendGroupMessage(group: Groups, message: string) {

    const url = environment.api.host + environment.api.paths.api.message.groups.patchMessage;

    const data = { groupid: group.data.id, message };

    this.siteService.addSubscriptionLog(this, 'user.service.ts->sendGroupMessage->this.http.patch<any>(url, data)');

    this.http.patch<any>(url, data).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('user.service.ts->sendGroupMessage->this.http.patch<any>(url, data)')),
      map(res => res)
    ).subscribe();
    
  }

  getNextArrangerViaCaptainsPick(): Observable<UserClass> {

    return new Observable(subscriber => {

      const randomIndex = _.random(0, this.usersSourceValue.length - 1);

      const user = this.usersSourceValue[randomIndex];

      subscriber.next(user);
      subscriber.complete();

    });

  }

  /**
   * This instantiates each user's User class.
   */
  private generateUsers(response: UserResponse): UserClass[] {

    const authenticatedUser = this.userSource.value;

    const otherUsers = response.data.filter(user => authenticatedUser && authenticatedUser.data.id !== user.id);

    const otherUsersEmail = otherUsers.map(u => u.email).filter(email => !!email);
    const otherUsersId = otherUsers.map(u => u.id);

    // @TODO: Due to limitation of AppSync filterGroup operation. Only 10 items at a time
    const otherUsersEmailChunk = _.chunk(otherUsersEmail, 10);
    const otherUsersIdChunk = _.chunk(otherUsersId, 10);

    // @TODO: Then merge them into 1 stream
    const otherUsersStatus$ = merge(
      ...otherUsersEmailChunk.map(emails => this.userStatusGQLService.subscribe({usernames: emails}))
    );

    const otherUsersMessage$ = merge(
      ...otherUsersIdChunk.map(ids => this.messageGQLService.subscribe({recipientIds: ids}))
    );

    return otherUsers.map(user => {

        const u = new UserClass(this.http, this.siteService, user);

        this.siteService.addSubscriptionLog(this, 'user.service.ts->generateUsers->u.setStatusObservable');

        // @TODO: Then filter out incoming data base on specific user
        u.setStatusObservable(
          otherUsersStatus$.pipe(
            finalize(() => this.siteService.setSubscriptionLogFinalised('user.service.ts->generateUsers->u.setStatusObservable')),
            filter(res => {
              return _.get(res, 'data.onNotifyUserStatus.username') === u.data.email;
            })
          )
        );
       
        this.siteService.addSubscriptionLog(this, 'user.service.ts->generateUsers->u.setMessageObservable');

        u.setMessageObservable(
          otherUsersMessage$.pipe(
            finalize(() => this.siteService.setSubscriptionLogFinalised('user.service.ts->generateUsers->u.setMessageObservable')),
            filter(res => {
              return _.get(res, 'data.onNotifyMessageDirect.recipientId') === u.data.id;
            })
          )
        );

        return u;

      });

  }

  /**
   * This instantiates each user's User class.
   */
  private generateGroups(response: GroupResponse): Groups[] {

    return response.data
      .map(group => {

        const u = new Groups(this.http, this.siteService, group);
        u.setMessageObservable(this.messageGroupsGQLService.subscribe({ groupId: u.data.id }));

        return u;

      });

  }

  updateUser(user:{}){
    const url = environment.api.host + environment.api.paths.api.user.profile;
    return this.http.patch(url,user);
  }

  uploadFile(fileData:{}){
    const url = environment.api.host + environment.api.paths.api.user.uploadFile;
    return this.http.post(url,fileData);
  }

  uploadAvatar(fileData:{}){
    const url = environment.api.host + environment.api.paths.api.user.uploadAvatar;
    return this.http.post(url,fileData);
  }

}
