import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';

import { AccountArrangementSourceListComponent } from './account-arrangement-source-list.component';
import { CardModule } from 'primeng/card';

@NgModule({
  declarations: [
    AccountArrangementSourceListComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    RippleModule,
    InputTextModule,
    CardModule,
  ]
})
export class AccountArrangementSourceListModule { }
