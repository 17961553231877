import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Arrangement } from '../../classes/arrangement';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-save-prompt',
  templateUrl: './save-prompt.component.html',
  styleUrls: ['./save-prompt.component.scss']
})
export class SavePromptComponent implements OnInit {

  arrangement: Arrangement;
  type: string;

  isDeveloper: boolean;

  retrieving: boolean;
  saving: boolean;

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
    private userService: UserService,
  ) {

    this.arrangement = this.config.data.arrangement;
    this.type = this.config.data.type;

    this.retrieving = false;
    this.saving = false;

    this.isDeveloper = this.userService.userSourceValue.isDeveloper();

  }

  ngOnInit(): void { }

  retrieve(event: Event): void {
    
    this.retrieving = true;

    this.arrangement.retrieve().subscribe(() => {

      this.retrieving = false;

      this.ref.close('retrieve');

    });

  }

  save(event: Event): void {
    
    this.saving = true;

    this.arrangement.save().subscribe(() => {

      this.saving = false;

      this.ref.close('save');

    });

  }

}
