import { Directive, Input, Renderer2, ElementRef, OnInit, SimpleChanges, OnChanges } from '@angular/core';

@Directive({
  selector: '[appShowHide]'
})
export class ShowHideDirective  implements OnInit, OnChanges {

  @Input('appShowHide') isVisible!: boolean;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.updateDisplay(this.isVisible);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isVisible']) {
      this.updateDisplay(this.isVisible);
    }
  }

  private updateDisplay(value: boolean) {
    const displayStyle = value ? 'block' : 'none';
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', displayStyle);
  }

}




