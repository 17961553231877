import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountEventTypeListComponent } from './account-event-type-list.component';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';

@NgModule({
  declarations: [
    AccountEventTypeListComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    RippleModule,
    InputTextModule,
  ],
  exports: [
    AccountEventTypeListComponent
  ],
})
export class AccountEventTypeListModule { }
