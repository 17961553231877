
import { DeviceService } from './services/device.service';
import { StorageBrowserService } from './services/storage-browser.service';
import { StorageCordovaService } from './services/storage-cordova.service';
import { StorageElectronService } from './services/storage-electron.service';

export async function StorageFactory(deviceService: DeviceService) {

  const devicePlatform = () => {

    return new Promise((resolve, reject) => {

      deviceService.devicePlatform.subscribe({
        next: (platform) => {
          resolve(platform);
        },
        error: () => {
          reject();
        }
      });

    });

  }

  const platform = await devicePlatform();

  if (platform === 'electron') {

    return new StorageElectronService();

  } else if (platform === 'browser') {

    return new StorageBrowserService();

  } else {

    return new StorageCordovaService();

  }

}