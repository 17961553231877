import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss'],
})
export class ImageCropperModalComponent implements OnInit {
  @ViewChild("container") container?: ElementRef<HTMLDivElement>;
  
  imageChangedEvent: any = '';
  croppedBase64Image: any = '';

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {}

  imageCropped(event: ImageCroppedEvent) {
    this.croppedBase64Image = event.base64;
  }

  imageLoaded(image?: LoadedImage) {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onOk() {
    this.ref.close(this.croppedBase64Image || this.config.data?.imageBase64);
  }
}
