import { FileModel } from "./file";

export enum UserStatus {
  Offline = 'offline',
  Online = 'online',
  Away = 'away',
  DoNotDisturb = 'dnd',
}

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isEnabled: number;
  phone?: string;
  _avatarFile?: FileModel;
  serviceProviderId: number | null;
  emailSignature: string | null;
  role?: string;
  roles?:number[];
  regions?:number[];
  status: UserStatus | null,
  createdAt: Date;
  updatedAt: Date;
  isActivated:number;
  uId?:string;
}

export interface Group {
  id: number;
  type: string;
  typeId: number;
  title: string;
  createdAt: Date;
  users: User[];
}

export interface UserMessage {
  data: {
    message: string;
    sender: { id: number; email: string };
    sentAt: string;
    groupId?:number;
    recipients?: { id: number; email: string }[];
  };
  messageId: number;
  readAt: string | null;
  createdAt: string;
}

export interface UserMessageResponse {
  data: UserMessage[];
}

export interface AppSyncMessageResponse {
  data: {
    onNotifyMessageDirect: AppSyncMessage
  }
}

export interface AppSyncMessage {
	sentAt: string;
	message: string;
	messageId: number;
	recipientId: number;
	recipientEmail: string;
  groupId?:number;
	senderId: number;
	senderEmail: string;
}

export interface AppSyncNotification {
  popup?: boolean;
	notification: string;
	recipientId: number;
	senderId: number;
}

export interface Notification {
  id: number;
  recipientId: number;
  senderId: number;
  status: number;
  notification: string;
  createdAt: Date;
  updatedAt?: any;
  _user: User
}

export interface RegionRole {
  id: number;
  name:string;
  key:string;
  createdAt: Date;
  updatedAt?: any;
}

export interface UserResponse {
  data: User[];
}

export interface GroupResponse {
  data: Group[];
}

export interface UnreadMessageCountResponse {
  data: UnreadMessageCount;
}

export interface UnreadMessageCount {
  totalCount: number;
  counts: {
    [key: string]: {
      userId: number;
      count: number;
    }
  };
}