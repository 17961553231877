import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import moment from 'moment';

@Pipe({
  name: 'arrangement'
})
export class ArrangementPipe implements PipeTransform {

  transform(arrangements: any[], input: string): any[] {

    if (Array.isArray(arrangements) && input) {

      let maxMatchCount = 0;
      
      const searchedArrangements = arrangements.map(arrangement => {
        
        const data = arrangement?.form?.value || arrangement.data;

        const deceasedDateOfBirth: string = _.get(data, 'deceased.dateOfBirth', '');
        const deceasedDateOfPassing: string = _.get(data, 'deceased.dateOfPassing', '');
        const deceasedDateOfPassingFrom: string = _.get(data, 'deceased.dateOfPassingFrom', '');
        const deceasedDateOfPassingTo: string = _.get(data, 'deceased.dateOfPassingTo', '');

        const deceasedDateOfBirthYYYYMMDD: string | null = (deceasedDateOfBirth) ? moment(deceasedDateOfBirth).format('YYYY-MM-DD') : null;
        const deceasedDateOfPassingYYYYMMDD: string | null = (deceasedDateOfPassing) ? moment(deceasedDateOfPassing).format('YYYY-MM-DD') : null;
        const deceasedDateOfPassingFromYYYYMMDD: string | null = (deceasedDateOfPassingFrom) ? moment(deceasedDateOfPassingFrom).format('YYYY-MM-DD') : null;
        const deceasedDateOfPassingToYYYYMMDD: string | null = (deceasedDateOfPassingTo) ? moment(deceasedDateOfPassingTo).format('YYYY-MM-DD') : null;

        const deceasedDateOfBirthDDMMYYYY: string | null = (deceasedDateOfBirth) ? moment(deceasedDateOfBirth).format('DD/MM/YYYY') : null;
        const deceasedDateOfPassingDDMMYYYY: string | null = (deceasedDateOfPassing) ? moment(deceasedDateOfPassing).format('DD/MM/YYYY') : null;
        const deceasedDateOfPassingFromDDMMYYYY: string | null = (deceasedDateOfPassingFrom) ? moment(deceasedDateOfPassingFrom).format('DD/MM/YYYY') : null;
        const deceasedDateOfPassingToDDMMYYYY: string | null = (deceasedDateOfPassingTo) ? moment(deceasedDateOfPassingTo).format('DD/MM/YYYY') : null;

        const deceasedFirstName: string = _.get(data, 'deceased.name.first', '');
        const deceasedLastName: string = _.get(data, 'deceased.name.last', '');
        const arrangerName: string = _.get(data, 'arrangementMeeting.arranger.name', '');
        const firstName: string = _.get(data, 'firstName', '');
        const lastName: string = _.get(data, 'lastName', '');
        const name: string = _.get(data, 'name', '');

        const splitInput = input.split(' ');

        let matchCount = 0;

        for (const sInput of splitInput) {

          if (sInput) {

            if (arrangerName && arrangerName.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }

            if (deceasedFirstName && deceasedFirstName.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }
  
            if (deceasedLastName && deceasedLastName.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }
  
            if (firstName && firstName.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }
  
            if (lastName && lastName.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }
  
            if (name && name.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }
  
            if (deceasedDateOfBirthYYYYMMDD && deceasedDateOfBirthYYYYMMDD.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }
  
            if (deceasedDateOfPassingYYYYMMDD && deceasedDateOfPassingYYYYMMDD.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }
  
            if (deceasedDateOfPassingFromYYYYMMDD && deceasedDateOfPassingFromYYYYMMDD.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }
  
            if (deceasedDateOfPassingToYYYYMMDD && deceasedDateOfPassingToYYYYMMDD.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }
  
            if (deceasedDateOfBirthDDMMYYYY && deceasedDateOfBirthDDMMYYYY.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }
  
            if (deceasedDateOfPassingDDMMYYYY && deceasedDateOfPassingDDMMYYYY.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }
  
            if (deceasedDateOfPassingFromDDMMYYYY && deceasedDateOfPassingFromDDMMYYYY.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }
  
            if (deceasedDateOfPassingToDDMMYYYY && deceasedDateOfPassingToDDMMYYYY.toLowerCase().includes(sInput.toLowerCase())) {
              matchCount++;
            }

          }

        }

        if (matchCount >= maxMatchCount) {
          maxMatchCount = matchCount;
        }

        return { matchCount, arrangement };

      });

      if (maxMatchCount === 0) {
        return [];
      }
      
      const filteredArrangements = searchedArrangements.filter(searchedArrangement => searchedArrangement.matchCount === maxMatchCount);
      
      const response = filteredArrangements.map(filteredArrangement => filteredArrangement.arrangement);

      return response;

    } else {
      
      return arrangements;

    }

  }

}
