<div class="surface-card p-4 shadow-2 border-round ">

  <div *ngIf="(user$ | async) as authenticatedUser" class="flex align-items-center mb-2">
    <app-avatar [user]="authenticatedUser" size="large" class="mr-3"></app-avatar>
    <span class="text-xl font-medium text-900">{{ userId ? 'Edit Existing User' : 'Create new User' }}</span>
  </div>

  <div class="pt-3">

    <form [formGroup]="registerForm" (ngSubmit)="onFormSubmit($event)">

      <div class="grid">

        <div class="field col-12 md:col-6">
          <label for="fname" class="block text-900 font-medium mb-2 dynamic-required">First Name</label>
          <input type="text" pInputText formControlName="firstName" id="fname" [ngClass]="{ 'ng-invalid ng-dirty' : registerForm.get('firstName')?.invalid }" class="w-full">
        </div>

        <div class="field col-12 md:col-6">
          <label for="lname" class="block text-900 font-medium mb-2 dynamic-required">Last Name</label>
          <input type="text" pInputText formControlName="lastName" id="lname" class="w-full">
        </div>

        <div class="field col-12 md:col-6">
          <label for="email" class="block text-900 font-medium mb-2 dynamic-required">Email</label>
          <input type="text" pInputText formControlName="email" id="email" [ngClass]="{ 'ng-invalid ng-dirty' : registerForm.get('email')?.invalid }" class="w-full">
        </div>

        <div class="field col-12 md:col-6">
          <label for="phone" class="block text-900 font-medium mb-2">Phone</label>
          <input type="text" pInputText formControlName="phone" id="phone" class="w-full">
        </div>

        <div class="field col-12 md:col-6" *ngIf="!userId|| isModal">
          <div class="flex justify-content-between">
            <label for="password" class="block text-900 font-medium mb-2 dynamic-required">Password</label>
            <span class="generate-random" (click)="generatePassword()">Generate Random</span>
          </div>
          <p-password type="password" [feedback]="true" [toggleMask]="true" formControlName="password" id="password"
            class="mb-3"
            [ngClass]="{ 'ng-invalid ng-dirty' : registerForm.get('password')?.invalid || registerForm.get('passwordConfirm')?.invalid }"
            class="w-full"></p-password>
        </div>

        <div class="field col-12 md:col-6" *ngIf="!userId || isModal">
          <label for="passwordConfirm" class="block text-900 font-medium mb-2 dynamic-required">Confirm Password</label>
          <p-password type="password" [feedback]="false" [toggleMask]="true" formControlName="passwordConfirm"
            id="passwordConfirm" class="mb-3"
            [ngClass]="{ 'ng-invalid ng-dirty' : registerForm.get('passwordConfirm')?.invalid }"
            class="w-full"></p-password>
        </div>
        <ng-container *ngIf="!isModal">

          <div *ngIf="(regions$ | async) as regions" class="field col-12 md:col-6">
            <label class="block text-900 font-medium mb-2 dynamic-required">Regions</label>
            <p-multiSelect *ngIf="regions.length" [options]="regions" optionValue="id" formControlName="regions"
              defaultLabel="Select Regions" optionLabel="name" display="chip"></p-multiSelect>
            <p-skeleton *ngIf="!regions.length" height="3rem"></p-skeleton>
          </div>

          <div *ngIf="(roles$ | async) as roles" class="field col-12 md:col-6">
            <label class="block text-900 font-medium mb-2 dynamic-required">Roles</label>
            <p-multiSelect *ngIf="roles.length" [options]="roles" optionValue="id" formControlName="roles"
              defaultLabel="Select Roles" optionLabel="name" display="chip"></p-multiSelect>
            <p-skeleton *ngIf="!roles.length" height="3rem"></p-skeleton>
          </div>
        </ng-container>

        <div class="button-actions col-12 flex justify-content-end">

          <button type="button" *ngIf="!isModal && (currentUser$ | async) as currentUser" (click)="onToggleUserIsEnabled($event, currentUser)" pButton pRipple
            [label]="(currentUser.data.isEnabled === 1) ? 'Disable User' : 'Enable User'"
            [icon]="(currentUser.data.isEnabled === 1) ? 'pi pi-trash' : 'pi pi-check'"
            [ngClass]="{ 'p-button-danger' : currentUser.data.isEnabled === 1,'p-button-success' : currentUser.data.isEnabled !== 1 }"
            [loading]="isProcessingUserEnabled"></button>
          <button type="button" *ngIf="!isModal && (currentUser$ | async) as currentUser" (click)="onResetPassword($event, currentUser)" pButton pRipple
            label="Reset Password" icon="pi pi-lock" class="p-button-secondary"
            [loading]="isProcessingResetPassword"></button>
          <button type="submit" pButton pRipple label="Submit" icon="pi pi-save" [disabled]="registerForm.invalid"
            [loading]="isProcessingUser"></button>

        </div>

      </div>

    </form>

  </div>

</div>