import { Injectable } from '@angular/core';
import { Subscription, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class MessageGroupsGQLService extends Subscription {
  document = gql`
    subscription onNotifyGroupMessageDirect($groupId: Int!) {
      onNotifyMessageDirect(
        groupId: $groupId
      ) {
        sentAt
        message
        messageId
        groupId
        senderId
        senderEmail
      }
    }
  `;
}
