<ng-container [formGroup]="formGroup">

  <div class="formgrid grid">
    
    <div *ngIf="options[0].state || options[0].required" class="field col-12 md:col-6">
      <label [appIsRequired]="'title'">Title:</label>
      <app-select #input controlName="title" [options]="titles | sort:'asc':'name'" [filter]="true" [showClear]="true" placeHolder="Select title" class="inputfield w-full"></app-select>
    </div>
    
    <div *ngIf="options[1].state || options[1].required" class="field col-12 md:col-6">
      <label [appIsRequired]="'first'">First Name:</label>
      <input #input pInputText formControlName="first" type="text" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty' : firstNameControl?.invalid && isSubmittedControl?.value }" />
    </div>

    <div *ngIf="options[2].state || options[2].required" class="field col-12 md:col-6">
      <label [appIsRequired]="'middle'">Middle Name:</label>
      <input #input pInputText formControlName="middle" type="text" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty' : middleNameControl?.invalid && isSubmittedControl?.value }" />
    </div>
    
    <div *ngIf="options[3].state || options[3].required" class="field col-12 md:col-6">
      <label [appIsRequired]="'last'">Last Name:</label>
      <input #input pInputText formControlName="last" type="text" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty' : lastNameControl?.invalid && isSubmittedControl?.value }" />
    </div>
    
    <div *ngIf="options[4].state || options[4].required" class="field col-12 md:col-6">
      <label [appIsRequired]="'lastNameAtBirth'">Last Name at Birth:</label>
      <input #input pInputText formControlName="lastNameAtBirth" type="text" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty' : lastNameAtBirthControl?.invalid && isSubmittedControl?.value }" />
    </div>
    
    <div *ngIf="options[5].state || options[5].required" class="field col-12 md:col-6">
      <label [appIsRequired]="'aka'">Also known as:</label>
      <input #input pInputText formControlName="aka" type="text" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty' : akaControl?.invalid && isSubmittedControl?.value }" />
    </div>

  </div>

</ng-container>
