import { ApplicationRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, SkipSelf, ViewChildren } from '@angular/core';
import { AbstractControl, ControlContainer, Validators } from '@angular/forms';
import { ModalService } from 'src/app/shared/services/modal.service';
import _, { noop } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { addressStateFormValue } from '../../form-values';
import { changeDetection } from 'src/app/shared/change-detection';
import { Arrangement } from 'src/app/shared/classes/arrangement';
import { placeAndStreetAreDifferent } from 'src/app/shared/place-and-street-are-different';

interface Command {
  originalEvent: Event;
  item: any;
};

interface ControlState {
  [key: string]: boolean | ControlState;
};

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class AddressComponent implements OnInit, OnDestroy {

  @ViewChildren('textInput') inputs!: QueryList<ElementRef>;

  @ViewChildren('searchTextInput') searchTextInput!: QueryList<ElementRef>;
  @ViewChildren('streetTextInput') streetTextInput!: QueryList<ElementRef>;
  @ViewChildren('suburbTextInput') suburbTextInput!: QueryList<ElementRef>;
  @ViewChildren('stateTextInput') stateTextInput!: QueryList<ElementRef>;
  @ViewChildren('postCodeTextInput') postCodeTextInput!: QueryList<ElementRef>;
  @ViewChildren('countryTextInput') countryTextInput!: QueryList<ElementRef>;
  @ViewChildren('placeTextInput') placeTextInput!: QueryList<ElementRef>;

  @Input() controlName!: string;
  @Input() states!: ControlState;
  @Input() readonly!: boolean;
  
  showInputs: boolean;
  isInternational: BehaviorSubject<boolean>;
  inputsReadOnly: BehaviorSubject<boolean | null>;

  formRoot!: AbstractControl;
  formGroup!: AbstractControl;

  australianStates = addressStateFormValue;

  isInternationalSubscription!: Subscription;
  placeSubscription!: Subscription;

  autocomplete!: google.maps.places.Autocomplete;
  autocompleteOptions: google.maps.places.AutocompleteOptions = {
    componentRestrictions: {country: 'au'},
    fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,
  };

  options = [
    { 
      id: 'street',
      label: 'Street', 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'suburb',
      label: 'Suburb' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'state',
      label: 'State' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'postcode',
      label: 'Postcode' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'country',
      label: 'Country' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'place',
      label: 'Place' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
  ];

  defaultState: ControlState;

  get isSubmittedControl(): AbstractControl | null {
    return this.formRoot.get('isSubmitted');
  }

  get isInternationalControl(): AbstractControl | null {
    return this.formGroup.get('isInternational');
  }

  get streetControl(): AbstractControl | null {
    return this.formGroup.get('street');
  }

  get suburbControl(): AbstractControl | null {
    return this.formGroup.get('suburb');
  }

  get stateControl(): AbstractControl | null {
    return this.formGroup.get('state');
  }

  get postcodeControl(): AbstractControl | null {
    return this.formGroup.get('postcode');
  }

  get countryControl(): AbstractControl | null {
    return this.formGroup.get('country');
  }

  get placeControl(): AbstractControl | null {
    return this.formGroup.get('place');
  }

  constructor(
    private appRef: ApplicationRef,
    private controlContainer: ControlContainer,
    private modalService: ModalService
  ) {

    this.showInputs = true;
    this.isInternational = new BehaviorSubject<boolean>(false);
    this.inputsReadOnly = new BehaviorSubject<boolean | null>(null);

    this.defaultState = { 
      street: true, 
      suburb: true,
      state: true,
      postcode: true,
      country: false,
      place: false,
    };

  }

  ngOnInit(): void {

    const control = this.controlContainer.control?.get(this.controlName);

    if (control) {

      this.formGroup = control;
      this.formRoot = this.formGroup.root;

    }

    if (!this.states) {

      this.states = this.defaultState;

    } else {

      this.states = _.assign(this.defaultState, this.states);

    }

    _.forEach(this.states, (val, key: string) => {

      const optionsIndex = _.findIndex(this.options, option => option.id === key);

      if (optionsIndex > -1) {

        this.options[optionsIndex].state = (val !== false);
        this.menuCommand({ originalEvent: (noop as any), item: this.options[optionsIndex]}, false);

      }

    });

    this.processPlaceVisibility();

    if (this.placeControl) {

      this.placeSubscription = this.placeControl.valueChanges.subscribe({
        next: (value) => {
          this.processPlaceVisibility();
        }
      });

    }

    if (this.isInternationalControl) {

      this.isInternationalSubscription = this.isInternationalControl.valueChanges.subscribe({
        next: (value) => {
          this.isInternational.next(value);
          
          if (this.autocomplete) {
            this.autocomplete.setOptions({
              ...this.autocompleteOptions,
              componentRestrictions: value ? undefined : {country: 'au'},
            });

          }
        }
      });

    }

    if (this.readonly) {

      this.inputsReadOnly.next(true);

    }

    changeDetection(() => {
      this.isInternationalControl?.patchValue(false);
    });

  }

  ngAfterViewInit(): void {

    const input = this.searchTextInput.first.nativeElement;

    this.autocomplete = new google.maps.places.Autocomplete(input, this.autocompleteOptions);

    this.autocomplete.addListener("place_changed", () => {
  
      const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();

      const subPremise = _.find(place.address_components, addressComponent => {
        return _.includes(addressComponent.types, 'subpremise');
      });

      const streetNumber = _.find(place.address_components, addressComponent => {
        return _.includes(addressComponent.types, 'street_number');
      });

      const street = _.find(place.address_components, addressComponent => {
        return _.includes(addressComponent.types, 'route');
      });

      const suburb = _.find(place.address_components, addressComponent => {
        return _.includes(addressComponent.types, 'locality');
      });

      const state = _.find(place.address_components, addressComponent => {
        return _.includes(addressComponent.types, 'administrative_area_level_1');
      });

      const country = _.find(place.address_components, addressComponent => {
        return _.includes(addressComponent.types, 'country');
      });

      const postcode = _.find(place.address_components, addressComponent => {
        return _.includes(addressComponent.types, 'postal_code');
      });

      const placeControl = this.formGroup.get('place');
      const streetControl = this.formGroup.get('street');
      const suburbControl = this.formGroup.get('suburb');
      const stateControl = this.formGroup.get('state');
      const postcodeControl = this.formGroup.get('postcode');
      const countryControl = this.formGroup.get('country');
      const isInternationalControl = this.formGroup.get('isInternational');

      this.formGroup.reset();

      if (place.name) {

        placeControl?.patchValue(place.name);

      }

      if (streetControl) {

        let streetValue = '';

        if (subPremise) {

          streetValue += subPremise.long_name + ' / ';

        }

        if (streetNumber) {

          streetValue += streetNumber.long_name + ' ';

        }

        if (street) {

          streetValue += street.short_name;

        }

        if (streetValue) {

          streetControl.patchValue(streetValue);

        }

        /**
         * Only show the location name if the street name isn't in the string.
         * eg: Don't show if the name is 'taminga rd' and the street is 'taminga rd'
         * eg: Show the name if the name is 'liverpool hospital' and street is 'bathurst st'
         * 
         * This is a simple way to hide duplicated data in the name + street inputs but probably 
         * won't work 100% of the time... But it's a good starting point
         */

        /**
         * UPDATED 26th Mar 2024 - I've created a shared function to handle this logic. It's still not perfect, but it's better
         */
        if (place.name && streetValue && placeAndStreetAreDifferent(place.name, streetValue)) {

          this.options[5].state = true;
          this.menuCommand({ originalEvent: (noop as any), item: this.options[5]}, false);

          this.appRef.tick();
          
        } else {
        
          this.options[5].state = false;
          this.menuCommand({ originalEvent: (noop as any), item: this.options[5]}, false);

          this.appRef.tick();

        }

      }

      if (suburbControl) {

        let suburbValue = '';

        if (suburb) {

          suburbValue += suburb.long_name;

        }

        if (suburbValue) {

          suburbControl.patchValue(suburbValue);

        }

      }

      if (stateControl) {

        let stateValue: any = null;

        if (state) {

          // Get Australian state object (if we can)
          const stateIndex = _.findIndex(this.australianStates, australianState => {
            return australianState.value.toLowerCase() === state.short_name.toLowerCase();
          });

          if (stateIndex > -1) {

            stateValue = this.australianStates[stateIndex];

          }

          // If we don't find one, set the string value
          if (!stateValue) {

            stateValue = state.short_name;

            if (isInternationalControl) {

              isInternationalControl.patchValue(true);

            }

          }

        }

        if (stateValue) {

          stateControl.patchValue(stateValue);

        }

      }

      if (postcodeControl) {

        let postcodeValue = '';

        if (postcode) {

          postcodeValue += postcode.long_name;

        }

        if (postcodeValue) {

          postcodeControl.patchValue(postcodeValue);

        }

      }

      if (countryControl) {

        let countryValue = '';

        if (country) {

          countryValue = country.long_name;

          if (isInternationalControl) {

            if (country.short_name.toLowerCase() === 'au') {
  
              isInternationalControl.patchValue(false);
              
            } else {

              isInternationalControl.patchValue(true);

            }

          }

        }

        if (countryValue) {

          countryControl.patchValue(countryValue);

        }

      }

      this.appRef.tick();

    });

  }

  ngOnDestroy(): void {
   
    if (this.isInternationalSubscription) {

      this.isInternationalSubscription.unsubscribe();

    }

  }

  onClearSearch(event: Event): void {

    const searchControl = this.formGroup.get('search');

    if (searchControl) {

      searchControl.patchValue('');

    }

  }

  /**
   * A method that can be called from the parent component to focus on the 
   * first available input.
   * 
   * Note: We do this so that when removing an item from a FormArray we 
   * don't get the ExpressionChangedAfterItHasBeenCheckedError (ng-untouched) error
   * 
   * Note: We use setTimeout() because we're bad at coding.
   */
  public setFocus() {

    setTimeout(() => {

      const inputs = this.inputs.toArray();

      if (inputs.length) {

        inputs[0].nativeElement.focus();

      }

    }, 0);

  }

  /**
   * Method that can be called from the parent component to disable the inputs as needed
   */
  public readOnlyInputs(status: boolean) {

    if (status) {

      this.inputsReadOnly.next(true);

    } else {

      this.inputsReadOnly.next(null);

    }

  }

  /**
   * Set if the address is an international address
   * @param status: boolean
   */
  public isInternationalAddress(status: boolean) {

    if (status) {

      changeDetection(() => {

        this.isInternationalControl?.patchValue(true);

        if (this.stateControl) {

          const currentStateValue = _.get(this.stateControl.value, 'value', null);

          if (currentStateValue) {

            this.stateControl.patchValue(currentStateValue);

          }

        }

        this.options[4].state = true;

      });
      
    } else {
      
      changeDetection(() => {

        this.isInternationalControl?.patchValue(false);

        this.countryControl?.patchValue('Australia');

        if (this.stateControl) {

          const stateIndex = _.findIndex(this.australianStates, australianState => australianState.value.toLowerCase() === (this.stateControl?.value && this.stateControl?.value.toLowerCase()));

          if (stateIndex > -1) {

            this.stateControl.patchValue(this.australianStates[stateIndex]);

          } else {

            this.stateControl.reset();

          }

        }

        const countryControl = this.formGroup.get('country');

        if (countryControl && countryControl.hasValidator(Validators.required) === false) {

          this.options[4].state = false;

        }

      });

    }

  }

  private menuCommand(event: Command, updateState: boolean = true) {
    
    if (updateState) {

      event.item.state = !event.item.state;

    }

    if (event.item.state) {
      event.item.icon = 'pi pi-check'
    } else {
      event.item.icon = 'pi pi-times'
    }

  }

  private processPlaceVisibility(): void {

    if (
      this.streetControl && 
      this.placeControl && 
      this.streetControl.value && 
      this.placeControl.value
    ) {

      if (!this.placeControl.value.includes(this.streetControl.value)) {

        this.options[5].state = true;
        this.menuCommand({ originalEvent: (noop as any), item: this.options[5]}, false);
        
      }

    } else if (this.placeControl && !this.placeControl.value) {

      this.options[5].state = false;
      this.menuCommand({ originalEvent: (noop as any), item: this.options[5]}, false);

    }

  }

}
