import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(haystacks: any[], needles: { path: string; expectedValue: any }[]): any[] {
    
    if (!Array.isArray(haystacks)) {
      return haystacks;
    }

    const filtered = haystacks.filter(haystack => {

      let truthy = true;

      needles.map(needle => {

        const haystackValue = _.get(haystack, needle.path);

        if (haystackValue !== needle.expectedValue) {

          truthy = false;

        }

      });

      return truthy;

    });

    return filtered;

  }

}
