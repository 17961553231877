import { Injectable } from '@angular/core';
import { Subscription, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class ArrangementLockedGQLService extends Subscription {
  document = gql`
    subscription onNotifyArrangementLocked {
      onNotifyArrangementLocked {
        arrangementId
        userId
        uId
      }
    }
  `;
}
