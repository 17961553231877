import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject, fromEvent } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { SiteService } from './site.service';

declare var device: any;
declare var cordova: { platformId: string; version: string; };

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private deviceIsReadySource = new ReplaySubject<any>(1);
  deviceIsReady = this.deviceIsReadySource.asObservable();

  private devicePlatformSource = new ReplaySubject<string>(1);
  devicePlatform = this.devicePlatformSource.asObservable();

  private deviceOnPauseSource = new ReplaySubject(1);
  deviceOnPause = this.deviceOnPauseSource.asObservable();

  private deviceOnResumeSource = new ReplaySubject(1);
  deviceOnResume = this.deviceOnResumeSource.asObservable();

  private deviceOnOnlineSource = new ReplaySubject<string>(1);
  deviceOnOnline = this.deviceOnOnlineSource.asObservable();

  private deviceOnOfflineSource = new ReplaySubject<string>(1);
  deviceOnOffline = this.deviceOnOfflineSource.asObservable();

  constructor(
    private siteService: SiteService,
    private zone: NgZone,
  ) {

    const deviceReady$ = fromEvent(document, 'deviceready');
    const pause$ = fromEvent(document, 'pause');
    const resume$ = fromEvent(document, 'resume');
    const offline$ = fromEvent(document, 'offline');
    const online$ = fromEvent(document, 'online');

    this.siteService.addSubscriptionLog(this, 'device.service.ts->constructor->deviceReady$');

    deviceReady$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('device.service.ts->constructor->deviceReady$')),
      map(event => event)
    ).subscribe({
      next: event => {
        this.zone.run(() => {
          this.deviceIsReadySource.next(device);
          this.devicePlatformSource.next(cordova.platformId);
        });
      }
    });

    this.siteService.addSubscriptionLog(this, 'device.service.ts->constructor->pause$');

    pause$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('device.service.ts->constructor->pause$')),
      map(event => event)
    ).subscribe({
      next: event => {
        this.zone.run(() => {
          this.deviceOnPauseSource.next();
        });
      }
    });

    this.siteService.addSubscriptionLog(this, 'device.service.ts->constructor->resume$');

    resume$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('device.service.ts->constructor->resume$')),
      map(event => event)
    ).subscribe({
      next: event => {
        this.zone.run(() => {
          this.deviceOnResumeSource.next();
        });
      }
    });

    this.siteService.addSubscriptionLog(this, 'device.service.ts->constructor->offline$');

    offline$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('device.service.ts->constructor->offline$')),
      map(event => event)
    ).subscribe({
      next: event => {
        this.zone.run(() => {
          this.deviceOnOfflineSource.next('offline');
        });
      }
    });

    this.siteService.addSubscriptionLog(this, 'device.service.ts->constructor->online$');

    online$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('device.service.ts->constructor->online$')),
      map(event => event)
    ).subscribe({
      next: event => {
        this.zone.run(() => {
          this.deviceOnOnlineSource.next('online');
        });
      }
    });

  }

  onReady() {
    this.deviceIsReadySource.next();
  }

  onPause() {
    this.deviceOnPauseSource.next();
  }

  onResume() {
    this.deviceOnResumeSource.next();
  }
  
  onOffline(state: string) {
    this.deviceOnOfflineSource.next(state);
  }

  onOnline(state: string) {
    this.deviceOnOnlineSource.next(state);
  }

}
