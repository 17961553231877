<div class="formgrid grid">

  <ng-container *ngFor="let phone of formGroup.controls; let i = index; let last = last">

    <ng-container [formGroup]="$any(phone)">

      <div class="field col-6">
        <label [appIsRequired]="'phone'">Phone:</label>
        <input #phoneInput pInputText formControlName="phone" type="text" class="inputfield w-full" />
      </div>
  
      <div class="field col-4">
        <label [appIsRequired]="'type'">Type:</label>
        <app-select controlName="type" [options]="options | sort:'asc':'name'" [filter]="false" [showClear]="false" [showOptions]="false" placeHolder="Select Type" class="inputfield w-full"></app-select>
      </div>

      <div class="field col-2 button-container">
        <button type="button" pButton pRipple [disabled]="formGroup.controls.length === 1" (click)="remove(i)" icon="pi pi-minus" class="p-button-outlined p-button-rounded p-button-danger"></button>
        <button *ngIf="last" type="button" pButton pRipple (click)="add()" icon="pi pi-plus" class="p-button-outlined p-button-rounded"></button>
      </div>

    </ng-container>

  </ng-container>

</div>
