import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  errorMessages: string[] = [];

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) { 

    // console.log('Error Modal: ', this.config);

    if (_.has(this.config, 'data.error.error.message')) {
      if (Array.isArray(this.config.data.error.error.message)) {
        this.errorMessages = this.config.data.error.error.message;
      } else {
        this.errorMessages.push(this.config.data.error.error.message);
      }
    } else if (_.has(this.config, 'data.error.message')) {
      if (Array.isArray(this.config.data.error.message)) {
        this.errorMessages = this.config.data.error.message;
      } else {
        this.errorMessages.push(this.config.data.error.message);
      }
    } else if (_.has(this.config, 'data.message')) {
      if (Array.isArray(this.config.data.message)) {
        this.errorMessages = this.config.data.message;
      } else {
        this.errorMessages.push(this.config.data.message);
      }
    } else {
      this.errorMessages.push('An unknown error has occurred.');
    }

  }

  ngOnInit(): void { }

}
