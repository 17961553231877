import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-activate-account-modal',
  templateUrl: './activate-account-modal.component.html',
  styleUrls: ['./activate-account-modal.component.scss']
})
export class ActivateAccountModalComponent implements OnInit {

  constructor(public ref: DynamicDialogRef, ) { }

  ngOnInit(): void {
  }

}
