import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

const allRequiredOrNone = function(keys: string[]): ValidatorFn {

  return (abstractControl: AbstractControl): ValidationErrors | null => {

    let hasValue = false;

    for (const key of keys) {

      const v = abstractControl.get(key)?.value;

      if (v !== '') {
        hasValue = true;
      }

    }

    if (!hasValue) {

      return null;

    } else {

      let missingValue = false;

      for (const key of keys) {

        const v = abstractControl.get(key)?.value;

        if (v === '' || v === null) {

          missingValue = true;

          abstractControl.get(key)?.setErrors({ allRequiredOrNone: true });

        }

      }

      if (missingValue) {

        return { allRequiredOrNone: true };

      } else {

        return null;

      }

    }

  };

}

export { allRequiredOrNone };