import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { InputSwitchModule } from 'primeng/inputswitch';

import { AddressModule } from '../address/address.module';

import { AddressWithHeadingComponent } from './address-with-heading.component';
import { SelectModule } from '../select/select.module';
import { PipeModule } from 'src/app/shared/pipe.module';

@NgModule({
  declarations: [
    AddressWithHeadingComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    MenuModule,
    InputSwitchModule,
    AddressModule,
    SelectModule,
    PipeModule,
  ],
  exports: [
    AddressWithHeadingComponent
  ],
})
export class AddressWithHeadingModule { }
