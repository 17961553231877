<div class="grid">
  
    <div class="col-12 p-3 pt-0">
      <div style="border-radius: 12px" class="shadow-2 surface-card">
        <div class="flex p-4 flex-row align-items-center justify-content-between">
          <div class="flex align-items-center">
            <p-avatar icon="pi pi-book" [style]="{'background-color':'#2196F3', 'color': '#ffffff'}" class="mr-3" size="xlarge" shape="circle"></p-avatar>
            <div>
              <div class="text-xl font-medium text-900 mb-2">Users</div>
              <div class="font-medium text-500 mb-0 text-sm">A list of all the users you have access to.</div>
            </div>
          </div>
        </div>
        <div class="p-3 pt-0">
          <div class="p-input-icon-left w-full">
            <i class="pi pi-search"></i>
            <input type="text" [(ngModel)]="searchInput" pInputText class="border-1 surface-border w-full" placeholder="Search" />
          </div>
        </div>
      </div>
    </div>
  
    <ng-container *ngIf="$any( $any(users | async) | arrangement:searchInput) as users">
      <ng-container *ngIf="users.length; else zeroUsers">
        <div class="col-12 p-3" *ngFor="let user of users">
            <div class="shadow-2 surface-card p-4 border-2 border-transparent" style="border-radius: 10px">
              <div class="flex flex-row align-items-center justify-content-between">
                <div class="flex align-items-center">
                  <p-avatar [style]="{'background-color':'#2196F3', 'color': '#ffffff'}" label="{{ user.data.firstName.charAt(0) | uppercase }}{{ user.data.lastName.charAt(0) | uppercase }}" styleClass="mr-3" size="large" shape="circle"></p-avatar>
                  <div>
                    <span class="text-900 text-xl mb-2 font-medium">{{ user.data.firstName }} {{ user.data.lastNam }}</span>
                    <p class="m-0 text-600 font-medium text-sm">{{ user.data.email }}</p>
                  </div>
                </div>
                <div class="flex">
                  <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded " [routerLink]="['/dashboard/users/update',user.data.id]"></button>
                </div>
              </div>
            </div>
          </div>          
      </ng-container>
      <ng-template #zeroUsers>
        <p-message *ngIf="searchInput" class="px-3" severity="info" text="We didn't find any users that matched your search criteria." styleClass="p-mr-2"></p-message>
        <p-message *ngIf="!searchInput" class="px-3" severity="info" text="We didn't find any users for your account." styleClass="p-mr-2"></p-message>
      </ng-template>
    </ng-container>
  
  </div>