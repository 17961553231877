import { Component, OnDestroy, OnInit } from '@angular/core';

import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { Observable, Subject } from 'rxjs';
import { switchMap, first, tap, finalize, takeUntil } from 'rxjs/operators';

import { AccountService } from '../../services/account.service';
import { ModalService } from '../../services/modal.service';

import { Account } from '../../models/account';
import { SiteService } from '../../services/site.service';
import { UniqueId } from '../../uniqueId';
import _ from 'lodash';

@Component({
  selector: 'app-account-arrangement-source-list',
  templateUrl: './account-arrangement-source-list.component.html',
  styleUrls: ['./account-arrangement-source-list.component.scss']
})
export class AccountArrangementSourceListComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  account$: Observable<Account>;

  constructor(
    private siteService: SiteService,
    private modalService: ModalService,
    private accountService: AccountService,
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) {

    this.account$ = this.accountService.account$;

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
      
  }

  private findSourceGroups(sourceId: any, sourceGroups: any): string[] {
    const allSourceGroups = [];
    if (!sourceGroups) {
      return [];
    }

    for (const group  of sourceGroups) {
      const isFound = group.value?.find((source:any) => {return sourceId === source.value });
      if (isFound) {
        allSourceGroups.push(group);
      }
    }
    return allSourceGroups;
  }

  onEditSource(event: Event, source: any, sourceGroups: any): void {
    
    const groupsForSource = this.findSourceGroups(source._meta.id, sourceGroups);

    const firstCallSourceValue = {
      name: source.name,
      value: source.value,
      group: groupsForSource,
      newGroup: '',
    }

    const modal = this.modalService.addAccountFirstCallSource({
      title: 'First Call Source',
      buttons: [
        { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
        { label: 'Save', key: 'save', class: '' },
      ],
      data: firstCallSourceValue,
    });

    modal.onClose.subscribe({
      next: (reason: string) => {
        if (reason && reason.toString().toLowerCase() === 'save') {
          this.editAccountData(firstCallSourceValue, source);
        }
      }
    });

  }

  onDeleteSource(event: Event, contact: any): void {
    
    const modal = this.modalService.generic({
      title: 'Remove Item',
      copy: ['Are you sure you want to remove this source?'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: '' },
        { label: 'Confirm', key: 'confirm', class: 'p-button-danger', icon: 'pi pi-trash' },
      ]
    });

    modal.onClose.subscribe({
      next: (reason: string) => {

        if (reason.toString().toLowerCase() === 'confirm') {
        
          contact.disabled = true;

          this.updateAccountData();

        }

      }
    });

  }

  onRestoreSource(event: Event, contact: any): void {
    
    const modal = this.modalService.generic({
      title: 'Restore Item',
      copy: ['Are you sure you want to restore this contact?'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: '' },
        { label: 'Confirm', key: 'confirm', class: 'p-button-success', icon: 'pi pi-refresh' },
      ]
    });

    modal.onClose.subscribe({
      next: (reason: string) => {

        if (reason.toString().toLowerCase() === 'confirm') {
        
          contact.disabled = false;

          this.updateAccountData();

        }

      }
    });

  }

  private updateAccountData(): void {

    this.siteService.addSubscriptionLog(this, 'account-arrangement-source-list.component.ts->updateAccountData->this.accountService.account$');

    this.accountService.account$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('account-arrangement-source-list.component.ts->updateAccountData->this.accountService.account$')),
      takeUntil(this.unsubscribe$),
      first(),
      switchMap(account => this.accountService.patch(account.data)),
    ).subscribe({
      next: res => {
        // console.log(res.data);
      }
    });

  }

  private editAccountData(firstCallSourceValue: any, source: any): void {

    this.siteService.addSubscriptionLog(this, 'account-arrangement-source-list.component.ts->editAccountData->this.accountService.account$');

    this.accountService.account$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('account-arrangement-source-list.component.ts->updateAccountData->this.accountService.account$')),
      takeUntil(this.unsubscribe$),
      first(account => account !== undefined)
    ).subscribe({
      next: (account) => {
        const uniqueId = new UniqueId();

        // Update name of source
        source.name = firstCallSourceValue.name;
        source.value = firstCallSourceValue.value;

        // Check if there is update in sourceGroup for a specific source then update account.data.firstCall.sourceGroup entries
        const sourceGroupsForSource: any[] = this.findSourceGroups(source._meta.id, account.data.firstCall.sourceGroup);
        if (!_.isEqual(firstCallSourceValue.group, sourceGroupsForSource)) {
          // for each sourceGroup that exists on accountData
          account.data.firstCall.sourceGroup.forEach((sourceGroup: any, sourceGroupIndex: number) => {

            // If account source group has been included in editing
            const sourceGroupIncluded = firstCallSourceValue.group.find((element: any) => {return element._meta.id === sourceGroup._meta.id});
            
            if (sourceGroupIncluded) {

              const checkSourceExists = sourceGroup.value?.find((item: any)=> {return item.value === source._meta.id});
              // If edited source doesn't exist inside the current account sourceGroup , then add new entry
              if (!checkSourceExists) {
                
                if (!sourceGroup.value) {
                  sourceGroup.value = [];
                }

                const id = uniqueId.getUniqueInArray(sourceGroup.value, '_meta.id', 'id-');
                const index = sourceGroup.value.length;

                account.data.firstCall.sourceGroup[sourceGroupIndex].value.push({
                  _meta: { id, index },
                  value: source._meta.id,
                }); 
              }
            } // else: current source group was removed from edited entry so need to update the sourceGroup sources list
            else {
              const sourceexists = sourceGroup.value?.findIndex((element: any) => {return element.value === source._meta.id});
              if (sourceexists >= 0) {
                
                if (sourceGroup.value.length > 1) {
                  account.data.firstCall.sourceGroup[sourceGroupIndex].value.splice(sourceexists, 1);
                } else {
                  account.data.firstCall.sourceGroup[sourceGroupIndex].value = null;
                }
                
              }
            }

            
          });
          
        }

        // If new group has been added
        if (firstCallSourceValue.newGroup) {

          if (!account.data.firstCall.sourceGroup) {
            account.data.firstCall.sourceGroup = [];
          }

          const group_id = uniqueId.getUniqueInArray(account.data.firstCall.sourceGroup, '_meta.id', 'id-');
          const group_index = account.data.firstCall.sourceGroup.length;

          const new_s_id = uniqueId.getUniqueInArray([], '_meta.id', 'id-');
          const new_s_index = 0;

          account.data.firstCall.sourceGroup.push({
            _meta: { id: group_id, index: group_index },
            name: firstCallSourceValue.newGroup,
            value: [
              {
                _meta: { id: new_s_id, index: new_s_index },
                value: source._meta.id,
              }
            ],
          });
        }
        
        this.accountService.patch(account.data).subscribe({
          next: (res) => {
            //console.log(res.data);
          }
        });
      }
    });
  }
}
