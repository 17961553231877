import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { SelectModule } from '../select/select.module';
import { NameModule } from '../name/name.module';
import { AddressModule } from '../address/address.module';
import { EmailModule } from '../email/email.module';
import { PhoneModule } from '../phone/phone.module';
import { MenuModule } from 'primeng/menu';
import { DateModule } from '../date/date.module';
import { DateV2Module } from '../date-v2/date-v2.module';
import { IsVisibleModule } from '../is-visible/is-visible.module';
import { SelectMultiModule } from '../select-multi/select-multi.module';
import { PipeModule } from 'src/app/shared/pipe.module';
import { DropdownModule } from 'primeng/dropdown';
import { FormDirectivesModule } from '../../directives/form-directives.module';
import { AddressWithHeadingModule } from '../address-with-heading/address-with-heading.module';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  declarations: [
    ContactComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    RippleModule,
    InputTextModule,
    DateModule,
    DateV2Module,
    SelectModule,
    MenuModule,
    NameModule,
    AddressModule,
    EmailModule,
    PhoneModule,
    IsVisibleModule,
    SelectMultiModule,
    DropdownModule,
    PipeModule,
    FormDirectivesModule,
    AddressWithHeadingModule,
    InputSwitchModule,
  ],
  exports: [
    ContactComponent
  ],
})
export class ContactModule { }
