import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../classes/user';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  private largeStyle = { 'width': '48px', 'height': '48px' };
  private xLargeStyle = { 'width': '68px', 'height': '68px' };

  @Input() user!: User;
  @Input() size!: string;
  @Input() showStatus!: boolean;
  @Input() isGroup:boolean = false;
  @Input() groupImage!:string

  avatarStyle: { [key: string]: string | number } = this.xLargeStyle;

  constructor() { }

  ngOnInit(): void {

    if (!this.size) {
      this.size = 'xlarge';
    }

    if (this.size === 'xlarge') {
      this.avatarStyle = this.xLargeStyle;
    } else if (this.size === 'large') {
      this.avatarStyle = this.largeStyle;
    } else {
      console.error(`The supplied avatar size (${this.size}) isn't supported.`);
    }

    if (this.showStatus === undefined) {
      this.showStatus = true;
    }

  }

}
