import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, SkipSelf, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup } from '@angular/forms';
import _, { noop } from 'lodash';
import { MenuItem } from 'primeng/api';
import { changeDetection } from 'src/app/shared/change-detection';
import { Gender, NameTitle } from 'src/app/shared/models/arrangement';

interface Command {
  originalEvent: Event;
  item: any;
};

export interface ControlState {
  [key: string]: boolean | ControlState;
};

export interface MenuItemExtended extends MenuItem {
  state: any;
  required?: boolean;
};

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class NameComponent implements OnInit, AfterViewInit {

  @Input() controlName!: string;
  @Input() states!: ControlState;

  @ViewChildren('input') input!: QueryList<ElementRef<HTMLInputElement>>;

  formRoot!: AbstractControl;
  formGroup!: FormGroup;

  titles = [
    { name: NameTitle.Mr, value:NameTitle.Mr },
    { name: NameTitle.Ms, value:NameTitle.Ms },
    { name: NameTitle.Mrs, value:NameTitle.Mrs },
    { name: NameTitle.Miss, value:NameTitle.Miss },
    { name: NameTitle.Dr, value:NameTitle.Dr },
  ];

  defaultState: ControlState;

  options: MenuItemExtended[] = [
    { 
      id: 'title',
      label: 'Title', 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'firstName',
      label: 'First Name' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'middleName',
      label: 'Middle Name' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'lastName',
      label: 'Last Name' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'firstNameAtBirth',
      label: 'First Name at Birth' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
    { 
      id: 'alsoKnownAs',
      label: 'Also known as' , 
      icon: 'pi pi-check',
      state: true,
      command: this.menuCommand
    },
  ];

  get isSubmittedControl(): AbstractControl | null {
    return this.formRoot.get('isSubmitted');
  }

  get titleControl(): AbstractControl | null {
    return this.formGroup.get('title');
  }

  get firstNameControl(): AbstractControl | null {
    return this.formGroup.get('first');
  }

  get middleNameControl(): AbstractControl | null {
    return this.formGroup.get('middle');
  }

  get lastNameControl(): AbstractControl | null {
    return this.formGroup.get('last');
  }

  get lastNameAtBirthControl(): AbstractControl | null {
    return this.formGroup.get('lastNameAtBirth');
  }

  get akaControl(): AbstractControl | null {
    return this.formGroup.get('aka');
  }

  constructor(
    private controlContainer: ControlContainer
  ) {

    this.defaultState = { 
      title: false, 
      firstName: true, 
      middleName: false, 
      lastName: true, 
      firstNameAtBirth: false, 
      alsoKnownAs: false,
    };

  }

  ngOnInit(): void {

    const control = this.controlContainer.control?.get(this.controlName);

    if (control) {

      this.formGroup = control as FormGroup;
      this.formRoot = this.formGroup.root;

    }

    if (!this.states) {

      this.states = this.defaultState;

    } else {

      this.states = _.assign(this.defaultState, this.states);

    }

    _.forEach(this.states, (val, key: string) => {

      const optionsIndex = _.findIndex(this.options, option => option.id === key);

      if (optionsIndex > -1) {

        this.options[optionsIndex].state = (val !== false);
        this.menuCommand({ originalEvent: (noop as any), item: this.options[optionsIndex]}, false);

      }

    });

  }

  ngAfterViewInit(): void {

  }

  updatedOptions(options: any[]): void {

    changeDetection(() => {
      this.options = options;
    });

  }

  private menuCommand(event: Command, updateState: boolean = true) {
    
    if (updateState) {

      event.item.state = !event.item.state;

    }

    if (event.item.state) {
      event.item.icon = 'pi pi-check'
    } else {
      event.item.icon = 'pi pi-times'
    }

  }

  
}
