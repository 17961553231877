<div class="flex flex-column">

  <div class="formgrid grid">

    <div class="field col-12">

      <span class="p-float-label">
        <input id="input" type="text" pInputText class="w-full" [(ngModel)]="config.data.data.input" />
        <label for="input">Enter the {{ config.data.title }} here...</label>
      </span>

    </div>
    
  </div>

  <div class="actions-container flex-1 flex justify-content-end">
  
    <button *ngFor="let button of config.data.buttons" pButton pRipple type="button" [label]="button.label" [class]="button.class" [icon]="((button.icon) ? ('pi ' + button.icon) : '')" (click)="ref.close(button.key)"></button>
    
  </div>

</div>