import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from './shared/guards/authenticated.guard';
import { NotAuthenticatedGuard } from './shared/guards/not-authenticated.guard';

const routes: Routes = [
  // Non authenticated routes
  {
    path: '',
    canActivate: [NotAuthenticatedGuard],
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forgotten-password',
    canActivate: [NotAuthenticatedGuard],
    loadChildren: () => import('./pages/forgotten-password/forgotten-password.module').then((m) => m.ForgottenPasswordModule),
  },
  {
    path: 'reset-password',
    canActivate: [NotAuthenticatedGuard],
    loadChildren: () => import('./pages/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'confirm-email',
    loadChildren: () => import('./pages/confirm-email/confirm-email.module').then((m) => m.ConfirmEmailModule),
  },

  // Authenticated routes
  {
    path: 'dashboard',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'invoice',
    canActivate: [],
    loadChildren: () => import('./pages/invoice/invoice.module').then((m) => m.InvoiceModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
