<p-overlayPanel #overlayPanel>
  <div *ngIf="formGroup" [formGroup]="formGroup" style="width: 350px">
    <div *ngIf="showDate" class="flex gap-3 p-fluid">
      <div class="w-3">
        <label for="date">Date</label>
        <input autofocus type="number" placeholder="-" pInputText inputId="date" formControlName="date" [min]="1"
          [max]="31" />
      </div>
      <div class="w-5">
        <label for="month">Month</label>

        <p-dropdown placeholder="-" class="mt-2" inputId="month" [options]="monthOptions" optionLabel="name"
          optionValue="value" formControlName="month"></p-dropdown>
      </div>
      <div class="w-4">
        <label for="year">Year</label>
        <input type="number" placeholder="-" pInputText inputId="year" formControlName="year" [min]="minYear!"
          [max]="maxYear!" />

      </div>
    </div>

    <div *ngIf="showTime" class="flex gap-3 p-fluid mt-3">
      <div class="w-3">
        <label for="hour">Hour</label>

        <p-dropdown placeholder="-" class="mt-2" inputId="hour" [options]="hourOptions" optionLabel="name"
          optionValue="value" formControlName="hour"></p-dropdown>
      </div>
      <div class="w-3">
        <label for="minute">Minute</label>

        <input type="number" placeholder="-" pInputText inputId="minute" formControlName="minute" [min]="0"
          [max]="59" />

      </div>
      <div class="w-6 flex align-items-end w-1">

        <p-dropdown placeholder="-" class="flex-grow-1" placeholder="AM/PM" [options]="amOrPmOptions"
          formControlName="amOrPm"></p-dropdown>
      </div>
    </div>

    <div class="mt-3 flex gap-3 p-fluid justify-content-end">
      <p-button (onClick)="onCancel()" styleClass="p-button-secondary" label="Cancel"></p-button>
      <p-button (onClick)="onOk()" label="OK" [disabled]="formGroup.invalid"></p-button>
    </div>
  </div>

</p-overlayPanel>

<div class="input-wrapper" (click)="overlayPanel.toggle($event)" [ngClass]="{ 'is-read-only': ((readonly) ? true : false) }">

  <input type="text" class="w-1" [placeholder]="placeholder" pInputText [readonly]="true" [(ngModel)]="displayDate" (focus)="overlayPanel.toggle($event)" [ngModelOptions]="{standalone: true}" />
  
  <button class="btn-options" type="button" pButton icon="pi pi-calendar" (click)="overlayPanel.toggle($event)"></button>

</div>
