import { Directive, ElementRef, Input, OnInit, OnDestroy, OnChanges, Renderer2, SkipSelf, Optional, Host, Injector } from '@angular/core';
import { AbstractControl, ControlContainer, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddressComponent } from '../components/address/address.component';

@Directive({
  selector: '[appIsRequired]',
  providers: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class IsRequiredDirective implements OnInit, OnDestroy, OnChanges {

  @Input() appIsRequired!: string;

  requiredClass = environment.forms.dynamicRequiredClass;

  subscriptions: Subscription[];

  addressHostComponent: AddressComponent | undefined;

  control!: AbstractControl | null | undefined;

  constructor(
    private controlContainer: ControlContainer,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private injector: Injector,
  ) {

    try {
      this.addressHostComponent = this.injector.get<AddressComponent>(AddressComponent as any);
    } catch (e) {
      this.addressHostComponent = undefined; // Handle the case where no AddressComponent is available
    }

    this.subscriptions = [];

  }

  ngOnChanges(changes: any): void {

    this.addRequiredClass();

  }

  ngOnInit(): void {

    if (!this.appIsRequired) {

      console.error(`No path supplied to 'appIsRequired'.`);

      return;

    }

    this.control = this.controlContainer.control?.get(this.appIsRequired);

    if (!this.control) {

      const fullPath = this.controlContainer.path + '.' + this.appIsRequired;

      console.error(`is-required: ${fullPath} wasn't found.`);

      return;

    }

    this.addRequiredClass();

  }

  ngOnDestroy(): void {

  }

  private addRequiredClass(): void {

    if (this.control) {

      let fullControlPath = this.controlContainer.path?.join('.');

      if (fullControlPath) {

        fullControlPath += '.';

      }

      fullControlPath += this.appIsRequired;

      this.renderer.addClass(this.elementRef.nativeElement, `formControlPath_${fullControlPath}`);

      if (this.control.hasValidator(Validators.required)) {
  
        if (this.addressHostComponent) {
          this.showAddressInput();
        }

        const hasClass = this.elementRef.nativeElement.classList.contains(this.requiredClass);
  
        if (!hasClass) {

          this.renderer.addClass(this.elementRef.nativeElement, this.requiredClass);
  
        }
  
      } else {
  
        this.renderer.removeClass(this.elementRef.nativeElement, this.requiredClass);
  
      }

    }

  }

  // Address inputs will have a path ending in something like `street`, `suburb`, `state`, etc.
  // We can use this to determine which address input to show.
  private showAddressInput(): void {

    if (this.addressHostComponent) {

      if (this.appIsRequired === 'street') {
  
        this.addressHostComponent.options[0].state = true;
  
      } else if (this.appIsRequired === 'suburb') {
  
        this.addressHostComponent.options[1].state = true;
  
      } else if (this.appIsRequired === 'state') {
  
        this.addressHostComponent.options[2].state = true;
  
      } else if (this.appIsRequired === 'postcode') {
  
        this.addressHostComponent.options[3].state = true;

      } else if (this.appIsRequired === 'country') {
  
        this.addressHostComponent.options[4].state = true;
  
      } else if (this.appIsRequired === 'place') {
  
        this.addressHostComponent.options[5].state = true;
  
      }

    }

  }

}
