import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { changeDetection } from '../../change-detection';
import { stringToKey } from '../../string-to-key';
import { AccountService } from '../../services/account.service';
import { Account } from '../../models/account';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'app-account-firstcall-source`',
  templateUrl: './account-firstcall-source.component.html',
  styleUrls: ['./account-firstcall-source.component.scss']
})
export class AccountFirstCallSource implements OnInit {

  keyIsReadOnly: boolean;

  account$: Observable<Account> = this.accountService.account$;
  
  sourceGroups$!: Observable<any>;

  showAddNewGroupInput: boolean;

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
    private accountService: AccountService
  ) {

    this.keyIsReadOnly = true;
    this.showAddNewGroupInput = false;

  }

  ngOnInit(): void {

    const inputsAreSynced = this.nameAndValueAreSynced();

    if (inputsAreSynced) {
      this.keyIsReadOnly = true;
    } else {
      this.keyIsReadOnly = false;
    }

    this.sourceGroups$ = this.account$.pipe(map(accountData => {
      const currentSourceGroup = accountData?.data?.firstCall?.sourceGroup;
      if (!currentSourceGroup) {
        return [];
      } else {
        return currentSourceGroup;
      }
    }));
    
  }

  onInputNameChange(event: Event): void {

    if (this.keyIsReadOnly) {

      this.config.data.data.value = stringToKey(this.config.data.data.name);
  
      changeDetection(() => {
  
        const inputsAreSynced = this.nameAndValueAreSynced();
  
        if (inputsAreSynced && this.keyIsReadOnly === false) {
          this.keyIsReadOnly = true;
        }
  
      });

    }

  }

  onInputValueChange(event: Event): void {

    this.config.data.data.value = stringToKey(this.config.data.data.value);

  }

  onToggleReadOnly(event: Event): void {

    this.keyIsReadOnly = !this.keyIsReadOnly;

  }

  onAddNewGroup(event: Event, accountMulti: MultiSelect): void {
    this.showAddNewGroupInput = true;
    accountMulti.valuesAsString = "Add New First Call Source Group...";
  }

  private nameAndValueAreSynced() {

    const currentKey = this.config.data.data.value;
    const currentNameAsKey = stringToKey(this.config.data.data.name);

    return currentKey === currentNameAsKey;

  }

  onGroupSelect(event: Event): void {
    this.showAddNewGroupInput = false;
    this.config.data.data.newGroup = "";
  }

}