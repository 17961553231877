import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateV2Component } from './date-v2.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RippleModule } from 'primeng/ripple';
import { CalendarModule } from 'primeng/calendar';
import { FocusTrapModule } from 'primeng/focustrap';
import { CardModule } from 'primeng/card';

@NgModule({
  declarations: [DateV2Component],
  imports: [
    InputTextModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    RippleModule,
    DropdownModule,
    OverlayPanelModule,
    DropdownModule,
    ButtonModule,
    CalendarModule,
    FocusTrapModule,
    CardModule,
  ],
  exports: [DateV2Component],
})
export class DateV2Module {}
