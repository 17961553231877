import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ignoreNull'
})
export class IgnoreNullPipe implements PipeTransform {

  transform(value: any): any {

    let responseValue = value;

    if (Array.isArray(value)) {
      responseValue = value.filter(v => v !== null);
    }

    return responseValue;

  }

}
