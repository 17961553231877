import { Injectable } from '@angular/core';
import { Subscription, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class NewAppVersionGQLService extends Subscription {
  document = gql`
    subscription onNotifyNewAppVersion {
      onNotifyNewAppVersion {
        version
        changelog
      }
    }
  `;
}
