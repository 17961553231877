import { environment as base } from './environment.base';

base.webAppUrl = 'https://staging.fdconductor.com';
base.api.host = 'https://api-staging.fdconductor.com';

base.appSync.graphql.host = 'dyhipe5ojrd4dmmt4dbi2eblm4.appsync-api.ap-southeast-2.amazonaws.com';
base.appSync.graphql.http = 'https://dyhipe5ojrd4dmmt4dbi2eblm4.appsync-api.ap-southeast-2.amazonaws.com/graphql';
base.appSync.graphql.wss = 'wss://dyhipe5ojrd4dmmt4dbi2eblm4.appsync-realtime-api.ap-southeast-2.amazonaws.com/graphql';
base.appSync.apiKey = 'da2-gdhtvh57tfgwvfvm7tckfsqar4';
base.stripe.publicKey = 'pk_test_51QRPxOLdUfXmfwmQ5TRSuyllbKIjanPccw6XfF3gsE4SAsSdBmppf4nU4fpA4KbrwoZ98bT501xuBBodPhqeVIaM00QMo6N9YS';

base.email.sendAsync = true;

export const environment = base;
