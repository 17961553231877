import { Pipe, PipeTransform } from '@angular/core';

import { ServiceProvider } from './classes/service-provider';
import { DynamicComponents } from './dynamic/services/dynamic.service';

import _ from 'lodash';

@Pipe({
  name: 'serviceProviderByServiceType'
})
export class ServiceProviderByServiceTypePipe implements PipeTransform {

  transform(serviceProviders: ServiceProvider[], serviceType: DynamicComponents): ServiceProvider[] {

    if (Array.isArray(serviceProviders)) {
      
      const response = serviceProviders.filter(serviceProvider => {
        
        const data = serviceProvider.form.value;

        const services: any[] = _.get(data, 'services', []);

        const serviceIncludedIndex = (services.length) ? _.findIndex(services, service => {
          return service.type.value.toLowerCase().includes(serviceType.toLowerCase());
        }) : false;

        if (serviceIncludedIndex > -1) {
          return true;
        } else {
          return false;
        }

      });

      return response;

    } else {
      
      return serviceProviders;

    }

  }

}
