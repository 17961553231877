import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment-timezone';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import SignaturePad from 'signature_pad';
import { changeDetection } from '../../change-detection';
import _ from 'lodash';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-input-signature',
  templateUrl: './input-signature.component.html',
  styleUrls: ['./input-signature.component.scss']
})
export class InputSignatureComponent implements OnInit, AfterViewInit {
  
  @ViewChildren('signaturePad') signaturePadElement!: QueryList<ElementRef>;

  signaturePad!: SignaturePad;

  isLocked: boolean;
  signatureHasPoints: boolean;

  formGroup!: FormGroup;

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
    private modalService: ModalService,
  ) {

    this.isLocked = false;
    this.signatureHasPoints = false;

  }

  ngOnInit(): void {

    this.formGroup = this.config.data.data;

    if (_.get(this.formGroup.value, 'data')) {

      this.lock();

    }

  }

  ngAfterViewInit(): void {
    
    const canvasElement = this.signaturePadElement.first;

    if (canvasElement) {

      this.signaturePad = new SignaturePad(canvasElement.nativeElement);

      this.resize(canvasElement.nativeElement);
      this.updateFormControlValue();
      
      changeDetection(() => {
        this.loadExistingSignature();
      });

    }
  
  }

  lock(event?: Event) {

    this.isLocked = true;

    const isLockedControl = this.config.data.data.get('isLocked') as FormControl;

    if (isLockedControl) {

      isLockedControl.patchValue(this.isLocked);

    }

  }

  unLock(event?: Event) {

    this.isLocked = false;

    const isLockedControl = this.config.data.data.get('isLocked') as FormControl;

    if (isLockedControl) {

      isLockedControl.patchValue(this.isLocked);

    }

  }

  onUndo(event: Event) {

    const data = this.signaturePad.toData();

    if (data) {

      data.pop(); // remove the last dot or line

      this.signaturePad.fromData(data);

      this.patchDataFormControl();

    }

  }

  onClear(event: Event) {

    const modal = this.modalService.generic({
      title: 'Clear Existing Signature',
      copy: ['Are you sure you want to clear the current signature?'],
      buttons: [
        { label: 'No', key: 'no', class: '' },
        { label: 'Yes', key: 'yes', class: 'p-button-danger'}
      ],
    });

    modal.onClose.subscribe({
      next: (key: string) => {

        if (key === 'yes') {

          this.signaturePad.clear();

          this.formGroup.get('data')?.patchValue(null);
          this.formGroup.get('data')?.markAsDirty();
          this.formGroup.get('date')?.patchValue(null);
          this.formGroup.get('points')?.patchValue(null);

          this.unLock();

        }

      }
    });

  }

  onSave(event: Event) {

    this.lock();

    this.ref.close('save');

  }

  onClose(event: Event) {
    
    const isSignatureDirty = this.formGroup.get('data')?.dirty;

    if (!isSignatureDirty) {

      return this.ref.close();

    }

    const modal = this.modalService.generic({
      title: 'Unsaved Signature',
      copy: ['Are you sure you want to discard the current change?'],
      buttons: [
        { label: 'No', key: 'no', class: '' },
        { label: 'Yes', key: 'yes', class: 'p-button-danger'}
      ],
    });

    modal.onClose.subscribe({
      next: (key: string) => {

        if (key === 'yes') {

          return this.ref.close();

        }

      }
    });

  }

  private resize(canvas: any) {
   
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);

    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);

    this.signaturePad.clear();

  }

  private updateFormControlValue() {

    this.signaturePad.addEventListener("afterUpdateStroke", () => {
      
      this.patchDataFormControl();

    });

  }

  private loadExistingSignature() {

    const existingSignatureData = this.config.data.data.get('data')?.value;
    let existingSignaturePoints = this.config.data.data.get('points')?.value;

    // This is a placeholder for clearing any existing signatures that use points
    existingSignaturePoints = null;

    if (existingSignaturePoints) {

      this.signaturePad.fromData(existingSignaturePoints);
      this.signatureHasPoints = true;
      
    } else if (existingSignatureData) {
      
      this.signaturePad.fromDataURL(existingSignatureData);
      this.signatureHasPoints = false;

    }

  }

  private patchDataFormControl() {

    const formDataControl = this.config.data.data.get('data') as FormControl;
    const formPointsControl = this.config.data.data.get('points') as FormControl;
    const formDateControl = this.config.data.data.get('date') as FormControl;

    if (formDataControl) {
      formDataControl.patchValue(this.signaturePad.toDataURL('image/svg+xml'));

      formDataControl.markAsDirty();
    }

    if (formPointsControl) {

      // formPointsControl.patchValue(this.signaturePad.toData());
      formPointsControl.patchValue(null);

    }

    if (formDateControl) {

      formDateControl.patchValue(moment().format('YYYY-MM-DD HH:mm:ss'));

    }

  }

}
