export abstract class StorageService {

  constructor() {
    // ('StorageService');
  }

  abstract create(table: string, rows: any[]): any;

  abstract findByPk(table: string, id: number): any;

  abstract findAll(table: string, options: any): any;

  abstract findOne(table: string, options: any): any;

}
