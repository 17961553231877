import { AfterViewInit, Component, Input, OnInit, SkipSelf, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import * as _ from 'lodash';
import { MenuItem } from 'primeng/api';

interface CalendarEvent {
  month: number;
  year: number;
}

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class DateComponent implements OnInit, AfterViewInit {

  @Input() controlName!: string;
  @Input() yearRange: string;

  @Input() autoPopulate: boolean;
  @Input() required: boolean;
  @Input() showButtonBar: boolean;
  @Input() showIcon: boolean;
  @Input() showTime: boolean;
  @Input() showWeek: boolean;
  @Input() timeOnly: boolean;

  readOnlyInput: boolean;

  @ViewChild('calendarElement') calendarElement!: Calendar;

  months: { label: string, value: number }[];
  years: { label: string, value: number }[];

  menuOptions: MenuItem[];

  selectedMonth!: { label: string, value: number };
  selectedYear!: { label: string, value: number };

  formGroup = new FormGroup({
    selectedMonth: new FormControl(),
    selectedYear: new FormControl(),
  });

  formReady: boolean;

  control!: FormControl;
  formRoot!: FormGroup;

  get isSubmittedControl(): AbstractControl | null {
    return this.formRoot.get('isSubmitted');
  }

  constructor(
    private controlContainer: ControlContainer
  ) {

    this.formReady = false;

    this.months = [];
    this.years = [];

    this.yearRange = '1900:2030';

    this.autoPopulate = false;
    this.required = false;
    this.showButtonBar = true;
    this.showIcon = true;
    this.showTime = true;
    this.showWeek = true;
    this.timeOnly = false;
    this.readOnlyInput = true;

    this.menuOptions = [
      {
        label: 'Date Format',
        items: [{
          label: 'YYYY-MM-DD',
          command: () => {
            this.calendarElement.dateFormat = 'yy-mm-dd';
            this.calendarElement.updateInputfield();
          }
        },{
          label: 'DD/MM/YYYY',
          command: () => {
            this.calendarElement.dateFormat = 'dd/mm/yy';
            this.calendarElement.updateInputfield();
          }
        }]
      }
    ];

  }

  ngOnInit(): void {

    const control = this.controlContainer.control?.get(this.controlName) as FormControl;

    if (control) {

      this.control = control;
      this.formRoot = this.control.root as FormGroup;

    }

    if (this.timeOnly) {

      this.showButtonBar = false;
      // this.readOnlyInput = false;

    }

  }

  ngAfterViewInit(): void {

    const monthNames: string[] = this.calendarElement.getTranslation('monthNames');
    const yearOptions: number[] = this.calendarElement.yearOptions;

    // Set values for month form control
    monthNames.forEach((monthName, index) => this.months.push({ label: monthName, value: index }));
    
    // Set values for year form control
    yearOptions.forEach((year, index) => this.years.push({ label: year.toString(), value: year }));

    // Set default calendar values
    setTimeout(() => {

      const date = new Date();

      this.calendarElement.currentSecond = 0;
      
      // Set hour and minute to 0 id we aren't using the time property
      if (!this.showTime) {

        this.calendarElement.currentMinute = 0;
        this.calendarElement.currentHour = 0;

      }

      // If we we're auto populating the input with the date/time value
      if (!this.control.value && this.autoPopulate) {

        this.calendarElement.selectDate({ year: date.getFullYear(), month: date.getMonth(), day: date.getDate() });
  
        this.patchMonthValue({ month: this.calendarElement.currentMonth, year: this.calendarElement.currentYear });
        this.patchYearValue({ month: this.calendarElement.currentMonth, year: this.calendarElement.currentYear });
  
        this.calendarElement.updateInputfield();

      }

      this.formReady = true;

    }, 1);

  }

  /**
   * Click on the prev / back month button in header
   */
  onPrevMonthClick(event: Event): void {
    this.calendarElement.navigationState = { backward: true, button: true };
    this.calendarElement.navBackward(event);
    this.patchMonthValue({ month: this.calendarElement.currentMonth, year: this.calendarElement.currentYear });
    this.patchYearValue({ month: this.calendarElement.currentMonth, year: this.calendarElement.currentYear });
  }

  /**
   * Click on the next / forward month button in header
   */
  onNextMonthClick(event: Event): void {
    this.calendarElement.navigationState = { backward: false, button: true };
    this.calendarElement.navForward(event);
    this.patchMonthValue({ month: this.calendarElement.currentMonth, year: this.calendarElement.currentYear });
    this.patchYearValue({ month: this.calendarElement.currentMonth, year: this.calendarElement.currentYear });
  }

  /**
   * Select different month from dropdown menu
   */
  onChangeMonth(event: { event: Event, value: number }) {
    this.calendarElement.onMonthDropdownChange(event.value.toString());
  }

  /**
   * Select different year from dropdown menu
   */
  onChangeYear(event: { event: Event, value: number }) {
    this.calendarElement.onYearDropdownChange(event.value.toString());
  }

  onSelect(event: any) {
    // console.log(event);
  }

  onInput(event: any) {
    // console.log(event);
  }

  /**
   * Update the month dropdown menu when month changes
   */
  private patchMonthValue(data: CalendarEvent) {
    this.formGroup.get('selectedMonth')?.setValue(this.months[data.month].value);
  }

  /**
   * Update the year dropdown menu when year changes
   */
  private patchYearValue(data: CalendarEvent) {
    const yearIndex = _.findIndex(this.years, year => year.value === data.year);
    this.formGroup.get('selectedYear')?.setValue(this.years[yearIndex].value);
  }

}
