import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UnreadMessageCount, UnreadMessageCountResponse } from '../models/user';
import { set } from 'dot-object';
import moment, { Moment } from 'moment';
import { Arrangement } from '../classes/arrangement';
import { FormControl, FormGroup } from '@angular/forms';
import _ from 'lodash';

export enum PanelType {
  Message = 'message-panel',
  Notification = 'notification-panel',
  Profile = 'profile-panel',
  DataFilter = 'data-filter',
  Contacts = 'contacts',
  Timeline = 'timeline',
  MainMenu = 'main-menu',
  ArrangementDetail = 'arrangement-detail',
  ServiceProviderDetail = 'service-provider-detail',
  ProductDetail = 'product-detail',
  ArrangementNote = 'arrangement-note',
  ArrangementTask = 'arrangement-task',
  ArrangementTasks = 'arrangement-tasks',
  SendEmail = 'send-email',
  ArrangementMenu = 'arrangement-menu',
  EmailTrails = 'email-trails',
  DocumentLibrary = 'document-library',
}

export interface SiteServicePanel {
  isOpen: boolean;
  data: any;
}

export interface SiteSubscriptionLog {
  key: string;
  finalised: boolean;
  createdAt: string;
  finalisedAt?: string;
  name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  preventScroll$: Observable<boolean>;

  messagePanel$: Observable<boolean>;
  notificationPanel$: Observable<boolean>;
  profilePanel$: Observable<boolean>;
  dataFilterPanel$: Observable<SiteServicePanel>;
  contactPanel$: Observable<SiteServicePanel>;
  timelinePanel$: Observable<SiteServicePanel>;
  arrangementDetailPanel$: Observable<SiteServicePanel>;
  serviceProviderDetailPanel$: Observable<SiteServicePanel>;
  productDetailPanel$: Observable<SiteServicePanel>;
  arrangementNotePanel$: Observable<SiteServicePanel>;
  arrangementTaskPanel$: Observable<SiteServicePanel>;
  arrangementTasksPanel$: Observable<SiteServicePanel>;
  sendEmailPanel$: Observable<SiteServicePanel>;
  mainMenu$: Observable<boolean>;
  arrangementMenuPanel$: Observable<boolean>;
  emailTrailsPanel$: Observable<SiteServicePanel>;
  documentLibraryPanel$: Observable<SiteServicePanel>;
  blockUi$: Observable<boolean>;
  criticalDataReady$: Observable<boolean>;

  unreadMessageCount$: Observable<UnreadMessageCount>;

  allPanels: PanelType[];
  sitePanels: PanelType[];
  arrangementPanels: PanelType[];

  private subscriptionLog: SiteSubscriptionLog[] = [];
  
  private preventScrollSource: BehaviorSubject<boolean>;

  private messagePanelSource: BehaviorSubject<boolean>;
  private notificationPanelSource: BehaviorSubject<boolean>;
  private profilePanelSource: BehaviorSubject<boolean>;
  private dataFilterPanelSource: BehaviorSubject<SiteServicePanel>;
  private contactPanelSource: BehaviorSubject<SiteServicePanel>;
  private timelinePanelSource: BehaviorSubject<SiteServicePanel>;
  private arrangementDetailPanelSource: BehaviorSubject<SiteServicePanel>;
  private serviceProviderDetailPanelSource: BehaviorSubject<SiteServicePanel>;
  private productDetailPanelSource: BehaviorSubject<SiteServicePanel>;
  private arrangementNotePanelSource: BehaviorSubject<SiteServicePanel>;
  private arrangementTaskPanelSource: BehaviorSubject<SiteServicePanel>;
  private arrangementTasksPanelSource: BehaviorSubject<SiteServicePanel>;
  private sendEmailPanelSource: BehaviorSubject<SiteServicePanel>;
  private mainMenuSource: BehaviorSubject<boolean>;
  private arrangementMenuPanelSource: BehaviorSubject<boolean>;
  private emailTrailsPanelSource: BehaviorSubject<SiteServicePanel>;
  private documentLibraryPanelSource: BehaviorSubject<SiteServicePanel>;
  private blockUiSource: BehaviorSubject<boolean>;
  private criticalDataReadySource: BehaviorSubject<boolean>;

  private unreadMessageCountSource!: BehaviorSubject<UnreadMessageCount>;

  get mainMenuAsValue(): boolean {
    return this.mainMenuSource.value;
  }

  constructor() {

    this.preventScrollSource = new BehaviorSubject<boolean>(false);
    this.preventScroll$ = this.preventScrollSource.asObservable();

    this.messagePanelSource = new BehaviorSubject<boolean>(false);
    this.messagePanel$ = this.messagePanelSource.asObservable();

    this.notificationPanelSource = new BehaviorSubject<boolean>(false);
    this.notificationPanel$ = this.notificationPanelSource.asObservable();

    this.profilePanelSource = new BehaviorSubject<boolean>(false);
    this.profilePanel$ = this.profilePanelSource.asObservable();

    this.dataFilterPanelSource = new BehaviorSubject<SiteServicePanel>({ isOpen: false, data: null });
    this.dataFilterPanel$ = this.dataFilterPanelSource.asObservable();

    this.contactPanelSource = new BehaviorSubject<SiteServicePanel>({ isOpen: false, data: null });
    this.contactPanel$ = this.contactPanelSource.asObservable();

    this.timelinePanelSource = new BehaviorSubject<SiteServicePanel>({ isOpen: false, data: null });
    this.timelinePanel$ = this.timelinePanelSource.asObservable();

    this.arrangementDetailPanelSource = new BehaviorSubject<SiteServicePanel>({ isOpen: false, data: null });
    this.arrangementDetailPanel$ = this.arrangementDetailPanelSource.asObservable();

    this.serviceProviderDetailPanelSource = new BehaviorSubject<SiteServicePanel>({ isOpen: false, data: null });
    this.serviceProviderDetailPanel$ = this.serviceProviderDetailPanelSource.asObservable();

    this.productDetailPanelSource = new BehaviorSubject<SiteServicePanel>({ isOpen: false, data: null });
    this.productDetailPanel$ = this.productDetailPanelSource.asObservable();

    this.arrangementNotePanelSource = new BehaviorSubject<SiteServicePanel>({ isOpen: false, data: null });
    this.arrangementNotePanel$ = this.arrangementNotePanelSource.asObservable();

    this.arrangementTaskPanelSource = new BehaviorSubject<SiteServicePanel>({ isOpen: false, data: null });
    this.arrangementTaskPanel$ = this.arrangementTaskPanelSource.asObservable();

    this.arrangementTasksPanelSource = new BehaviorSubject<SiteServicePanel>({ isOpen: false, data: null });
    this.arrangementTasksPanel$ = this.arrangementTasksPanelSource.asObservable();

    this.sendEmailPanelSource = new BehaviorSubject<SiteServicePanel>({ isOpen: false, data: null });
    this.sendEmailPanel$ = this.sendEmailPanelSource.asObservable();

    this.mainMenuSource = new BehaviorSubject<boolean>(false);
    this.mainMenu$ = this.mainMenuSource.asObservable();

    this.arrangementMenuPanelSource = new BehaviorSubject<boolean>(false);
    this.arrangementMenuPanel$ = this.arrangementMenuPanelSource.asObservable();

    this.emailTrailsPanelSource = new BehaviorSubject<SiteServicePanel>({ isOpen: false, data: null });
    this.emailTrailsPanel$ = this.emailTrailsPanelSource.asObservable();

    this.documentLibraryPanelSource = new BehaviorSubject<SiteServicePanel>({ isOpen: false, data: null});
    this.documentLibraryPanel$ = this.documentLibraryPanelSource.asObservable();

    this.blockUiSource = new BehaviorSubject<boolean>(true);
    this.blockUi$ = this.blockUiSource.asObservable();
    
    this.criticalDataReadySource = new BehaviorSubject<boolean>(false);
    this.criticalDataReady$ = this.criticalDataReadySource.asObservable();

    this.unreadMessageCountSource = new BehaviorSubject<UnreadMessageCount>({ totalCount: 0, counts: {} });
    this.unreadMessageCount$ = this.unreadMessageCountSource.asObservable();

    this.allPanels = [
      PanelType.Message,
      PanelType.Notification,
      PanelType.Profile,
      PanelType.DataFilter,
      PanelType.Contacts,
      PanelType.Timeline,
      PanelType.MainMenu,
      PanelType.ArrangementDetail,
      PanelType.ServiceProviderDetail,
      PanelType.ProductDetail,
      PanelType.ArrangementNote,
      PanelType.ArrangementTask,
      PanelType.ArrangementTasks,
      PanelType.SendEmail,
      PanelType.EmailTrails,
      PanelType.DocumentLibrary
    ];

    this.sitePanels = [
      PanelType.DataFilter,
      PanelType.Contacts,
      PanelType.Timeline,
      PanelType.MainMenu,
      PanelType.ArrangementDetail,
      PanelType.ServiceProviderDetail,
      PanelType.ArrangementTasks,
      PanelType.EmailTrails,
      PanelType.DocumentLibrary,
    ];

    this.arrangementPanels = [
      PanelType.ProductDetail,
      PanelType.ArrangementNote,
      PanelType.ArrangementTask,
    ];

  }

  closeArrangementPanels(): void {

    this.arrangementPanels.forEach(panel => this.closePanel(panel));

  }

  closeSitePanels(): void {

    this.sitePanels.forEach(panel => this.closePanel(panel));

  }

  closeAllPanels(): void {

    this.allPanels.forEach(panel => this.closePanel(panel));

  }

  blockUi() {

    if (this.blockUiSource.getValue() !== true) {

      this.blockUiSource.next(true);

      const element: any = document.querySelector('#block-conductor-ui');

      if (element) {
        element.style.display = 'flex';
        element.style.opacity = 1;
      }

    }

  }

  unBlockUi() {

    if (this.blockUiSource.getValue() !== false) {

      this.blockUiSource.next(false);

      const element: any = document.querySelector('#block-conductor-ui');
      
      if (element) {

        element.style.opacity = 0;

        setTimeout(() => {
          element.style.display = 'none';
          element.style.background = 'rgba(255, 255,255, 0.75)';

          const pElement = document.querySelector('#block-conductor-ui p');

          if (pElement) {
            pElement.textContent = 'Processing. Please wait...';
          }

        }, 500);

      }

    }

  }

  criticalDataReady() {
    if (this.criticalDataReadySource.getValue() !== true) {
      this.criticalDataReadySource.next(true);
    }
  }

  openPanel(panel: PanelType, data?: any, preventScroll: boolean = true) {

    this.preventScrollSource.next(preventScroll);

    if (panel === PanelType.Message) {

      if (this.messagePanelSource.getValue() !== true) {

        this.messagePanelSource.next(true);

      }

    } else if (panel === PanelType.Notification) {

      if (this.notificationPanelSource.getValue() !== true) {

        this.notificationPanelSource.next(true);

      }

    } else if (panel === PanelType.Profile) {

      if (this.profilePanelSource.getValue() !== true) {

        this.profilePanelSource.next(true);

      }

    } else if (panel === PanelType.DataFilter) {

      if (this.dataFilterPanelSource.getValue().isOpen !== true) {

        this.dataFilterPanelSource.next({ isOpen: true, data });

      }

    } else if (panel === PanelType.Contacts) {

      if (this.contactPanelSource.getValue().isOpen !== true) {

        this.contactPanelSource.next({ isOpen: true, data });

      }

    } else if (panel === PanelType.Timeline) {

      if (this.timelinePanelSource.getValue().isOpen !== true) {

        this.timelinePanelSource.next({ isOpen: true, data });

      }

    } else if (panel === PanelType.ArrangementDetail) {

      if (this.arrangementDetailPanelSource.getValue().isOpen !== true) {

        this.arrangementDetailPanelSource.next({ isOpen: true, data });

      }

    } else if (panel === PanelType.ServiceProviderDetail) {

      if (this.serviceProviderDetailPanelSource.getValue().isOpen !== true) {

        this.serviceProviderDetailPanelSource.next({ isOpen: true, data });

      }

    } else if (panel === PanelType.ProductDetail) {

      if (this.productDetailPanelSource.getValue().isOpen !== true) {

        this.productDetailPanelSource.next({ isOpen: true, data });

      }

    } else if (panel === PanelType.ArrangementNote) {

      if (this.arrangementNotePanelSource.getValue().isOpen !== true) {
        
        this.arrangementNotePanelSource.next({ isOpen: true, data });
        
      }
      
    } else if (panel === PanelType.ArrangementTask) {
      
      if (this.arrangementTaskPanelSource.getValue().isOpen !== true) {

        this.arrangementTaskPanelSource.next({ isOpen: true, data });

      }

    } else if (panel === PanelType.ArrangementTasks) {
      
      if (this.arrangementTasksPanelSource.getValue().isOpen !== true) {

        this.arrangementTasksPanelSource.next({ isOpen: true, data });

      }

    } else if (panel === PanelType.SendEmail) {
      
      if (this.sendEmailPanelSource.getValue().isOpen !== true) {

        this.sendEmailPanelSource.next({ isOpen: true, data });

      }

    } else if (panel === PanelType.MainMenu) {

      if (this.mainMenuSource.getValue() !== true) {

        this.mainMenuSource.next(true);

      }

    } else if (panel === PanelType.ArrangementMenu) {

      if (this.arrangementMenuPanelSource.getValue() !== true) {
        
        this.arrangementMenuPanelSource.next(true);

      }

    } else if (panel === PanelType.EmailTrails) {

      if (this.emailTrailsPanelSource.getValue().isOpen !== true) {
        
        this.emailTrailsPanelSource.next({ isOpen: true, data });

      }

    } else if (panel === PanelType.DocumentLibrary) {

      if (this.documentLibraryPanelSource.getValue().isOpen !== true ) {

        this.documentLibraryPanelSource.next({ isOpen: true, data });

      }
    } else {

      console.error(`openPanel() : The supplied panel type of '${panel}' isn't supported.`);

    }
    
  }

  closePanel(panel: PanelType) {

    this.preventScrollSource.next(false);

    if (panel === PanelType.Message) {

      if (this.messagePanelSource.getValue() !== false) {

        this.messagePanelSource.next(false);

      }

    } else if (panel === PanelType.Notification) {

      if (this.notificationPanelSource.getValue() !== false) {

        this.notificationPanelSource.next(false);

      }

    } else if (panel === PanelType.Profile) {

      if (this.profilePanelSource.getValue() !== false) {

        this.profilePanelSource.next(false);

      }

    } else if (panel === PanelType.DataFilter) {

      if (this.dataFilterPanelSource.getValue().isOpen !== false) {

        this.dataFilterPanelSource.next({ isOpen: false, data: null });

      }

    } else if (panel === PanelType.Contacts) {

      if (this.contactPanelSource.getValue().isOpen !== false) {

        this.contactPanelSource.next({ isOpen: false, data: null });

      }

    } else if (panel === PanelType.Timeline) {

      if (this.timelinePanelSource.getValue().isOpen !== false) {

        this.timelinePanelSource.next({ isOpen: false, data: null });

      }

    } else if (panel === PanelType.ArrangementDetail) {

      if (this.arrangementDetailPanelSource.getValue().isOpen !== false) {

        this.arrangementDetailPanelSource.next({ isOpen: false, data: null });

      }

    } else if (panel === PanelType.ServiceProviderDetail) {

      if (this.serviceProviderDetailPanelSource.getValue().isOpen !== false) {

        this.serviceProviderDetailPanelSource.next({ isOpen: false, data: null });

      }

    } else if (panel === PanelType.ProductDetail) {

      if (this.productDetailPanelSource.getValue().isOpen !== false) {

        this.productDetailPanelSource.next({ isOpen: false, data: null });

      }

    } else if (panel === PanelType.ArrangementNote) {

      if (this.arrangementNotePanelSource.getValue().isOpen !== false) {

        this.arrangementNotePanelSource.next({ isOpen: false, data: null });

      }

    } else if (panel === PanelType.ArrangementTask) {

      if (this.arrangementTaskPanelSource.getValue().isOpen !== false) {

        this.arrangementTaskPanelSource.next({ isOpen: false, data: null });

      }

    } else if (panel === PanelType.ArrangementTasks) {

      if (this.arrangementTasksPanelSource.getValue().isOpen !== false) {

        this.arrangementTasksPanelSource.next({ isOpen: false, data: null });

      }

    } else if (panel === PanelType.SendEmail) {

      if (this.sendEmailPanelSource.getValue().isOpen !== false) {

        this.sendEmailPanelSource.next({ isOpen: false, data: null });

      }

    } else if (panel === PanelType.MainMenu) {

      if (this.mainMenuSource.getValue() !== false) {

        this.mainMenuSource.next(false);

      }

    } else if (panel === PanelType.ArrangementMenu) {
      
      if (this.arrangementMenuPanelSource.getValue() !== false) {
        
        this.arrangementMenuPanelSource.next(false);

      }

    } else if (panel === PanelType.EmailTrails) {
      
      if (this.emailTrailsPanelSource.getValue().isOpen !== false) {
        
        this.emailTrailsPanelSource.next({ isOpen: false, data: null });

      }

    } else if (panel === PanelType.DocumentLibrary) {

      if (this.documentLibraryPanelSource.getValue().isOpen !== false ) {

        this.documentLibraryPanelSource.next({ isOpen: false, data: null });
      
      }
    } else {

      console.error(`closePanel() : The supplied panel type of '${panel}' isn't supported.`);

    }
    
  }

  updateUnreadMessageCount(unreadMessageCount: UnreadMessageCount) {
    this.unreadMessageCountSource.next(unreadMessageCount);
  }

  getUnreadMessageCount(): UnreadMessageCount {
    return this.unreadMessageCountSource.value;
  }

  /**
   * This logs a subscription to the site service. It's used for debugging purposes.
   * @param key The key to set in the data object
   */

  addSubscriptionLog(that: any, key: string): void {

    const data = { key, createdAt: moment().format('DD/MM/YYYY HH:mm:ss'), finalised: false, name: 'N/A'};

    const arrangement = this.getArrangementFromThat(that);

    if (arrangement && arrangement.form) {
      data.name = arrangement.form.get('deceased.name.first')?.value + ' ' + arrangement.form.get('deceased.name.last')?.value;
    }

    this.subscriptionLog.push(data);

  }

  setSubscriptionLogFinalised(key: string): void {
    const log = this.subscriptionLog.find(l => l.key === key && l.finalised === false);
    if (log) {
      log.finalised = true;
      log.finalisedAt = moment().format('DD/MM/YYYY HH:mm:ss');
    }
  }

  storeSubscriptionLog(): void {
    
    let storedLog: SiteSubscriptionLog[] = [];

    let temp = localStorage.getItem('subscriptionLog');

    if (temp) {
      storedLog = JSON.parse(temp);
    } else {
      storedLog = [];
    }

    storedLog = storedLog.concat(this.subscriptionLog);

    this.subscriptionLog = [];

    // only keep the most recent 10000 logs
    if (storedLog.length > 10000) {
      storedLog = storedLog.slice(storedLog.length - 10000);
    }

    localStorage.setItem('subscriptionLog', JSON.stringify(storedLog));

  }

  viewSubscriptionLog(): void {
    console.table(this.subscriptionLog);
  }

  private getArrangementFromThat(that: any): Arrangement | null {

    if (that instanceof Arrangement) {
      return that;
    }

    if (that instanceof FormGroup) {

      const formGroup = that as FormGroup;

      const arrangement = formGroup.root.get('_arrangement')?.value;

      if (arrangement) {
        return arrangement;
      }

    }

    if (that && that.arrangement instanceof Arrangement) {
      return that.arrangement;
    }

    // Date-v2 Component
    if (that && that.externalControl instanceof FormControl) {
        
      const formControl = that.externalControl as FormControl;

      const arrangement = formControl.root.get('_arrangement')?.value;

      if (arrangement) {
        return arrangement;
      }

    }

    // Contact Component
    if (that && that.formRoot instanceof FormGroup) {
        
      const formGroup = that.formRoot as FormGroup;

      const arrangement = formGroup.root.get('_arrangement')?.value;

      if (arrangement) {
        return arrangement;
      }

    }

    return null;

  }

}
