<form *ngIf="formReady" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>

  <div class="formgrid grid">

    <div class="col-12">
      <h4 class="text-700 border-bottom-1 border-400 mt-3">Variables</h4>
    </div>

    <div class="col-12" *ngFor="let variable of variables?.controls; let index = index; let count = count;" [formGroup]="$any(variable)">

      <div class="grid">

        <div class="field col-6">
          <label for="form-variable-path-{{index}}">Path</label>
          <input id="form-variable-path-{{index}}" type="text" pInputText formControlName="path" class="w-full">
        </div>

        <div class="field col-6 flex">

          <div class="flex-1">
            <label for="form-variable-name-{{index}}" class="inline-block mb-2">Name</label>
            <input id="form-variable-name-{{index}}" type="text" pInputText formControlName="name" class="w-full">
          </div>

          <div class="flex-none align-self-end">
            <button type="button" pButton pRipple icon="pi pi-plus" class="p-button-rounded p-button-primary ml-2" (click)="addVariable($event)"></button>
            <button type="button" pButton pRipple icon="pi pi-minus" class="p-button-rounded p-button-danger ml-2" (click)="removeVariable($event, index)" [disabled]="count <= 1"></button>
          </div>

        </div>

      </div>

    </div>

    <div class="col-12">
      <h4 class="text-700 border-bottom-1 border-400 mt-0">Code</h4>
    </div>

    <div class="col-12">
      <!-- <ngx-monaco-editor class="shadow-2 border-round" [options]="editorOptions" formControlName="code"></ngx-monaco-editor> -->
    </div>
    
    <div class="col-12 mt-3 flex justify-content-end">
      <button type="button" pButton pRipple label="Clear" (click)="onClear($event)" icon="pi pi-trash" class="p-button-danger"></button>
      <button type="button" pButton pRipple label="Cancel" (click)="onCancel($event)" class="p-button-secondary ml-3"></button>
      <button type="submit" pButton pRipple label="Save" icon="pi pi-save" class="ml-3"></button>
    </div>

  </div>

</form>