import { HttpClient, HttpParams } from '@angular/common/http';
import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { Arrangement } from '../classes/arrangement';
import { AccountService } from './account.service';
import { SiteService } from './site.service';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import _ from 'lodash';
import { delay, finalize, map, tap } from 'rxjs/operators';
import { ArrangementDocument, ArrangementResponse } from '../models/arrangement';
import { objectsToArrays } from '../array-fix/functions/object-to-array';
import { ServiceProviderService } from './service-provider.service';
import { easyFormatter } from '../easyFormatter';
import { ModalService } from './modal.service';
import { ArrangementUpdatedGQLService } from './graphql/arrangement-updated-gql';
import { ArrangementCreatedGQLService } from './graphql/arrangement-created-gql';
import { ArrangementLockedGQLService } from './graphql/arrangement-locked-gql';
import { ArrangementUnlockedGQLService } from './graphql/arrangement-unlocked-gql';
import { changeDetection } from '../change-detection';

@Injectable({
  providedIn: 'root'
})
export class ArrangementService {

  arrangements$: Observable<Arrangement[]>;

  recentlyCreatedArrangements$: Observable<Arrangement[]>;
  
  private arrangementsSource!: BehaviorSubject<Arrangement[]>;

  private recentlyCreatedArrangementsSource!: BehaviorSubject<Arrangement[]>;

  get arrangementsSourceValue(): Arrangement[] {
    return this.arrangementsSource.value;
  }

  constructor(
    private http: HttpClient,
    private siteService: SiteService,
    private userService: UserService,
    private accountService: AccountService,
    private serviceProviderService: ServiceProviderService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private arrangementUpdatedGQLService: ArrangementUpdatedGQLService,
    private arrangementCreatedGQLService: ArrangementCreatedGQLService,
    private arrangementLockedGQLService: ArrangementLockedGQLService,
    private arrangementUnlockedGQLService: ArrangementUnlockedGQLService,
  ) {

    this.arrangementsSource = new BehaviorSubject<Arrangement[]>([]);
    this.recentlyCreatedArrangementsSource = new BehaviorSubject<Arrangement[]>([]);

    this.arrangements$ = this.arrangementsSource.asObservable();
    this.recentlyCreatedArrangements$ = this.recentlyCreatedArrangementsSource.asObservable();

    this.siteService.addSubscriptionLog(this, 'arrangement.service.ts->constructor->this.arrangementLockedGQLService.subscribe()');

    this.arrangementLockedGQLService.subscribe().pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.service.ts->constructor->this.arrangementLockedGQLService.subscribe()')),
      map((data: any) => _.get(data, 'data.onNotifyArrangementLocked', null)),
    ).subscribe({
      next: (data: { arrangementId: number, userId: number, uId: string }) => {

        const arrangement = this.arrangementsSource.value.find(arrangement => arrangement.id === data.arrangementId);
        
        if (arrangement) {

          arrangement.form.get('locked')?.patchValue({ locked: true, userId: data.userId, uId: data.uId });
        
          arrangement.arrangementLockedSource.next(true);

          const user = this.userService.allUsersSourceValue.find(user => user.data.id === data.userId);

          if (user) {
            arrangement.arrangementLockedUserSource.next(user);
          }

        }

      }
    });

    this.siteService.addSubscriptionLog(this, 'arrangement.service.ts->constructor->this.arrangementUnlockedGQLService.subscribe()');

    this.arrangementUnlockedGQLService.subscribe().pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.service.ts->constructor->this.arrangementUnlockedGQLService.subscribe()')),
      map((data: any) => _.get(data, 'data.onNotifyArrangementUnlocked', null)),
    ).subscribe({
      next: (data: { arrangementId: number, userId: number, uId: string }) => {

        const arrangement = this.arrangementsSource.value.find(arrangement => arrangement.id === data.arrangementId);
        
        if (arrangement) {

          arrangement.form.get('locked')?.patchValue({ locked: false, userId: null, uId: null });
        
          arrangement.arrangementLockedSource.next(false);
          arrangement.arrangementLockedUserSource.next(null);

        }

      }
    });

    // merge(
    //   this.arrangementCreatedGQLService.subscribe().pipe(
    //     map(data => data.data.onNotifyArrangementCreated.arrangementId),
    //     delay(5000)
    //   ),
    //   this.arrangementUpdatedGQLService.subscribe().pipe(
    //     map(data => data.data.onNotifyArrangementUpdated.arrangementId)
    //   ),
    // ).subscribe({
    //   next: async (arrangementId: number) => {

    //     console.log('AppSync - arrangementId: ', arrangementId);

    //     const arrangementFromServer = await this.getById(arrangementId).toPromise();

    //     console.log('AppSync - arrangementFromServer: ', arrangementFromServer);

    //     const currentArrangements = this.arrangementsSource.value;

    //     let foundExistingArrangement = false;

    //     // Try to find from currentArrangement
    //     this.arrangementsSource.next(currentArrangements.map(arrangement => {

    //       // If found in list -> update it 
    //       if (arrangement.id === arrangementFromServer.id) {

    //         foundExistingArrangement = true;

    //         arrangementFromServer.isRecentlyUpdated = true;

    //         return arrangementFromServer;

    //       }

    //       return arrangement

    //     }))

    //     // Try to find from recentlyCreatedArrangementsSource
    //     this.recentlyCreatedArrangementsSource.value.forEach(arrangement => {

    //         // If found in list -> update it 
    //         if (arrangement.id === arrangementFromServer.id) {

    //             foundExistingArrangement = true;
            
    //         }

    //     })


    //     console.log('Found existing arrangement in list: ', foundExistingArrangement);

    //     // If not found in list -> insert to recentlyCreatedArrangementsSource and transfer to arrangementsSource later
    //     if (!foundExistingArrangement) {

    //       arrangementFromServer.isRecentlyCreated = true;

    //       this.recentlyCreatedArrangementsSource.next([
    //         arrangementFromServer,
    //         ...this.recentlyCreatedArrangementsSource.value
    //       ])

    //     }

    //   }
    // })

  }

  getAll(queryParams?: { [key: string]: string | string[] }): Observable<any> {

    let params = new HttpParams();

    if (queryParams && Object.keys(queryParams).length > 0) {

      Object.keys(queryParams).forEach(key => {
  
        const value = queryParams[key];
  
        if (Array.isArray(value)) {
          // If the value is an array, append each item individually
          value.forEach(item => {
            if (item) {
              params = params.append(key, item);
            }
          });
        } else {
          // If it's a string, append it directly
          if (value) {
            params = params.append(key, value);
          }
        }
      });

    }

    const url = environment.api.host + environment.api.paths.api.arrangement.get;

    this.siteService.addSubscriptionLog(this, 'arrangement.service.ts->getAll->this.http.get<ArrangementResponse>(url, { params })');

    return this.http.get<ArrangementResponse>(url, { params }).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.service.ts->getAll->this.http.get<ArrangementResponse>(url, { params })')),
      map(res => {

        this.arrangementsSource.next([]);

        if (Array.isArray(res.data.arrangements)) {

          const arrangements = res.data.arrangements.map((d: any) => {

            const data = objectsToArrays(d);
    
            const arrangement = new Arrangement(
              this.http,
              this.siteService,
              this.userService,
              this.accountService,
              this.serviceProviderService,
              this.formBuilder,
              this.modalService,
              data,
            );
    
            arrangement.isPartial = true;
    
            return arrangement;
    
          });
    
          changeDetection(() => {
            this.arrangementsSource.next(arrangements);
          },100);

          return res.data;

        } else {

          return res.data;

        }

      })
    );

  }

  getById(id: number): Observable<Arrangement> {

    const url = easyFormatter(environment.api.host + environment.api.paths.api.arrangement.getById, { arrangementId: id.toString() });

    this.siteService.addSubscriptionLog(this, 'arrangement.service.ts->getById->this.http.get<ArrangementResponse>(url)');

    return this.http.get<ArrangementResponse>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.service.ts->getById->this.http.get<ArrangementResponse>(url)')),
      map(res => {
      
        const data = objectsToArrays(res.data);

        const arrangement = new Arrangement(
          this.http,
          this.siteService,
          this.userService,
          this.accountService,
          this.serviceProviderService,
          this.formBuilder,
          this.modalService,
          data,
        );

        arrangement.isPartial = false;

        return arrangement;

      })
    );

  }

  create(): Arrangement {

    const arrangement = new Arrangement(
      this.http,
      this.siteService,
      this.userService,
      this.accountService,
      this.serviceProviderService,
      this.formBuilder,
      this.modalService,
    );

    return arrangement;

  }

  addToArray(arrangement: Arrangement): void {

    let arrangements = this.arrangementsSource.value;

    arrangements.push(arrangement);

    this.arrangementsSource.next(arrangements);

  }

  remove(id: number): void {

    const arrangements: Arrangement[] = this.arrangementsSource.value;

    const filteredArrangements: Arrangement[] = arrangements.filter(arrangement => arrangement.id !== id);

    this.arrangementsSource.next(filteredArrangements);

  }

  refreshSource(): void {

    const arrangements = this.arrangementsSource.value;

    this.arrangementsSource.next([]);

    setTimeout(() => {
      this.arrangementsSource.next(arrangements);
    }, 0);

  }

  getAllDocuments(arrangementId: number){

    const url = easyFormatter(environment.api.host + environment.api.paths.api.arrangement.document.getAll, { arrangementId: arrangementId.toString() });

    return this.http.get(url) as Observable<{documents: ArrangementDocument[]}>;

  }
  
  createDocuments(arrangementId: number, fileIds: string[]){

    const url = easyFormatter(environment.api.host + environment.api.paths.api.arrangement.document.create, { arrangementId: arrangementId.toString() });

    return this.http.post(url, { fileIds }) as Observable<{documents: ArrangementDocument[]}>;

  }

  deleteDocumentsBatch(arrangementId: number, documentIds: number[]){

    const url = easyFormatter(environment.api.host + environment.api.paths.api.arrangement.document.deleteBatch, {
      arrangementId: arrangementId.toString(),
    });

    return this.http.delete(url, {
      body: {
        documentIds
      }
    });
    
  }

  loadRecentCreatedArrangements(arrangementIds: number[]) {

    const toBeAddedArrangements: Arrangement[] = [];
    const toBeKeptArrangements: Arrangement[] = [];

    this.recentlyCreatedArrangementsSource.value.forEach((arrangement) => {

      if (arrangementIds.includes(arrangement.id!)) {

        toBeAddedArrangements.push(arrangement)
        
      } else {

        toBeKeptArrangements.push(arrangement)

      }

    });

    this.arrangementsSource.next([
      ...toBeAddedArrangements,
      ...this.arrangementsSource.value,
    ]);

    this.recentlyCreatedArrangementsSource.next(toBeKeptArrangements);

  }

  loadAllRecentCreatedArrangements() {

    this.arrangementsSource.next([
      ...this.recentlyCreatedArrangementsSource.value,
      ...this.arrangementsSource.value
    ]);

    this.recentlyCreatedArrangementsSource.next([]);

  }

  clearArrangementAppSyncStatus(arrangementId: number) {

    this.arrangementsSource.next(

      this.arrangementsSource.value.map(arrangement => {

        if (arrangement.id == arrangementId) {
          arrangement.isRecentlyCreated = false;
          arrangement.isRecentlyUpdated = false;
        }

        return  arrangement;

      })
      
    );

  }

}

