import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from './classes/contact';

@Pipe({
  name: 'filterContacts'
})
export class FilterContactsPipe implements PipeTransform {

  transform(contacts: Contact[] | null, search: string): Contact[] {

    if (!contacts) {
      return [];
    }

    return contacts.filter(contact => {

      const name = (contact.name) ? contact.name.toLowerCase().includes(search.toLowerCase()) : false;
      const email = (contact.email) ? contact.email.toLowerCase().includes(search.toLowerCase()) : false;

      if (name || email) {
        return true;
      } else {
        return false;
      }

    });
  }

}
