<ng-container *ngIf="(account$ | async) as account">

  <p-table 
    #hospitalOrNursingHomeTable 
    [value]="account.data.hospitalAndNursingHomes" 
    [globalFilterFields]="['name']"
    styleClass="mt-5 p-datatable-striped" 
    dataKey="_meta.id" 
    responsiveLayout="scroll"
  >

    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="hospitalOrNursingHomeTable.filterGlobal($any($event).target.value, 'contains')" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="body" let-religion>
      <tr [pSelectableRow]="religion">
        <td class="religion-text">{{ religion.name }}</td>
        <td class="religion-actions">
          <button pButton pRipple type="button" icon="pi pi-pencil" (click)="onEditHospitalOrNursingHome($event, religion)" class="p-button-rounded p-button-text"></button>
          <button *ngIf="!religion.disabled" pButton pRipple type="button" icon="pi pi-trash" (click)="onDeleteHospitalOrNursingHome($event, religion)" class="p-button-rounded p-button-danger p-button-text"></button>
          <button *ngIf="religion.disabled" pButton pRipple type="button" icon="pi pi-refresh" (click)="onRestoreHospitalOrNursingHome($event, religion)" class="p-button-rounded p-button-success p-button-text"></button>
        </td>
      </tr>
    </ng-template>

  </p-table>

</ng-container>