import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { AvatarModule as PrimeNgAvatarModule } from 'primeng/avatar';

import { AvatarComponent } from './avatar.component';


@NgModule({
  declarations: [
    AvatarComponent
  ],
  imports: [
    CommonModule,
    StyleClassModule,
    PrimeNgAvatarModule,
    AvatarGroupModule,
    ButtonModule,
    BadgeModule,
  ],
  exports: [
    AvatarComponent
  ]
})
export class AvatarModule { }
