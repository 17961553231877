import { HttpClient } from "@angular/common/http";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { objectsToArrays } from "../array-fix/functions/object-to-array";
import { Contact } from "./contact";
import { ServiceProvider as ServiceProviderModel, ServiceProviderResponse } from "../models/service-provider";
import _ from "lodash";
import { FormAddressStructure, FormContactStructure, FormServiceProviderServiceStructure, FormStructureType, FormStructureValueType } from "../forms/form-structure";
import { FormGenerator } from "../forms/form-generator";
import { Observable, of } from "rxjs";
import { easyFormatter } from "../easyFormatter";
import { environment } from "src/environments/environment";
import { finalize, map, tap } from "rxjs/operators";
import { serviceVisibilityFormValue } from "../forms/form-values";
import moment from "moment";
import { arraysToObjects } from "../array-fix/functions/array-to-object";
import { DynamicComponents, DynamicService } from "../dynamic/services/dynamic.service";
import { Service } from "./service";
import * as dot from 'dot-object';
import { UserService } from "../services/user.service";
import { User } from "./user";
import { SiteService } from "../services/site.service";

export class ServiceProvider {

  isPartial: boolean;
  id: number | null;

  contacts: Contact[] = [];

  form!: FormGroup;
  obj!: { [key: string] : any };

  private validatorPaths: string[];

  constructor(
    private siteService: SiteService,
    private http: HttpClient,
    private dynamicService: DynamicService,
    private userService: UserService,
    data?: any,
  ) {

    this.id = null;
    this.isPartial = false;

    this.validatorPaths = [];

    if (data) {

      this.id = data.id;

      this.obj = objectsToArrays(data.data);

      const formData = this.postDataAsForm(this.obj as ServiceProviderModel);

      this.initialiseListingForm(formData);
      
    } else {

      this.initialiseCreateForm();

    }

  }

  get name(): string {
    return this.form.get('title')?.value;
  }

  get address(): any {
    return this.form.get('address')?.value;
  }

  get contact(): any {
    return this.form.get('contact')?.value;
  }

  get isGlobal(): any {
    return !!this.form.get('isGlobal')?.value;
  }

  public save(): Observable<boolean | any> {

    if (this.id) {

      return this.update();

    } else {

      return this.create();

    }

  }

  public retrieve(): Observable<boolean> {

    const url = easyFormatter(environment.api.host + environment.api.paths.api.serviceProvider.getById, { id: (this.id as number).toString() });

    this.siteService.addSubscriptionLog(this, 'service-provider.ts->retrieve->this.http.get<ServiceProviderResponse>(url)');

    return this.http.get<ServiceProviderResponse>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('service-provider.ts->retrieve->this.http.get<ServiceProviderResponse>(url)')),
      map(res => {
        
        this.obj = objectsToArrays((res.data as ServiceProviderModel).data);

        const formData = this.postDataAsForm(_.cloneDeep(this.obj) as ServiceProviderModel);
        
        this.initialiseCompleteForm(formData);

        return true;

      })
    );

  }

  public getServiceById(id: string): Service | null {

    const services = this.form.get('services') as FormArray;

    for (var i = 0; i < services.length; i++) {

      const service = services.at(i) as FormGroup;

      const serviceId = service.get('_meta.id')?.value;

      if (id === serviceId) {

        return service.get('service')?.value as Service;

      }

    }

    return null;

  }

  /**
   * Get the linked user from the form
   */
  public getLinkedUser(): User | null {

    const user = this.form.get('user')?.value;

    if (user) {

      return user as User;

    }

    return null;

  }

  private update(): Observable<any> {

    const url = easyFormatter(environment.api.host + environment.api.paths.api.serviceProvider.patch, { id: (this.id as number).toString() });

    const data = this.formAsPostData();

    const arrayToObject = arraysToObjects(data);

    this.siteService.addSubscriptionLog(this, 'service-provider.ts->update->this.http.patch<any>(url, arrayToObject)');

    return this.http.patch<any>(url, arrayToObject).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('service-provider.ts->update->this.http.patch<any>(url, arrayToObject)')),
      map(res => {
        
        this.obj = res.data.service.data;
        this.postDataAsForm(res.data.service.data);

        return res;

      })
    );

  }

  private create(): Observable<boolean> {

    const url = environment.api.host + environment.api.paths.api.serviceProvider.post;

    const data = this.formAsPostData();

    const arrayToObject = arraysToObjects(data);

    this.siteService.addSubscriptionLog(this, 'service-provider.ts->create->this.http.post<any>(url, arrayToObject)');

    return this.http.post<any>(url, arrayToObject).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('service-provider.ts->create->this.http.post<any>(url, arrayToObject)')),
      map(res => {

        this.id = res.data.id;
        this.obj = res.data.data;
        this.postDataAsForm(res.data.data);

        return true;

      })
    );

  }

  private initialiseListingForm(data?: any) {

    this.initialiseCompleteForm(data);

  }

  private initialiseCreateForm(data?: any) {

    this.initialiseCompleteForm(data);

  }

  private initialiseCompleteForm(data: any) {

    const formStructure: any = {
      title: { type: FormStructureType.FormControl },
      abn: { type: FormStructureType.FormControl },
      user: { type: FormStructureType.FormControl },
      visibility: { type: FormStructureType.FormControl, value: serviceVisibilityFormValue[1] },
      tags: { type: FormStructureType.FormControl, value: [] },
      contact: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure), valueAs: FormStructureValueType.Contact },
      address: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormAddressStructure), valueAs: FormStructureValueType.Address },
      services: { type: FormStructureType.FormArray, value: _.cloneDeep(FormServiceProviderServiceStructure) },
      isGlobal: { type: FormStructureType.FormControl, value: false },
    };

    delete formStructure.contact.relationToDeceased;
    
    const formGenerator = new FormGenerator();

    this.form = formGenerator.generate(formStructure, data);

    const services = this.form.get('services') as FormArray;

    for (let i = 0; i < services.length; i++) {

      const serviceWrapperFormGroup = services.at(i) as FormGroup;

      // Note: This is a special control. It's used to get a reference back to `this` when working with services in a form.
      // Don't remove this, or modify its use without talking to Ivan.
      let _serviceProvider = serviceWrapperFormGroup.get('_serviceProvider') as FormControl;

      if (_serviceProvider) {

        _serviceProvider.patchValue(this);

      } else {

        serviceWrapperFormGroup.addControl('_serviceProvider', new FormControl(this));

      }

      const service = new Service(this, serviceWrapperFormGroup);
      const type = serviceWrapperFormGroup.get('type')?.value;

      if (service && type && type.value) {

        const serviceFormStructure = this.dynamicService.getDynamicFormStructure(type.value);

        if (serviceFormStructure) {

          formGenerator.generate(serviceFormStructure, _.get(data, `services.${i}.service`, {}), service.form);

          const serviceFormControl = serviceWrapperFormGroup.get('service') as FormControl;

          if (serviceFormControl) {

            serviceFormControl.patchValue(service);

          }

        }

      }

    }

  }

  private postDataAsForm(data: any): any {

    if (data.user) {
        
      const user = this.userService.getById(data.user);
      
      if (user) {

        data.user = user;

      } else {

        data.user = null;

      }
  
    }

    return data;

  }

  private formAsPostData() {

    const convertDates = (data: any, dates: any[]) => {

      for (let date of dates) {

        const d = _.get(data, date.path);

        if (moment.isDate(d)) {

          _.set(data, date.path, moment(d).format(environment.dbDateFormat));
          
        } else if (moment.isMoment(d)) {

          _.set(data, date.path, d.format(environment.dbDateFormat));
          
        } else {

          _.set(data, date.path, null);

        }

      }

      return data;

    };

    const convertServiceClassToForm = (data: any) => {

      if (_.has(data, 'services') && data.services.length) {

        data.services = _.map(data.services, service => {
          delete service._serviceProvider;
          service.service = service.service.form.value;
          return service;
        });

      }

      return data;

    };

    /**
     * Empty arrays (eg. []) / objects (eg. {}) cause problems when saving to the DB, so we remove them
     * Note: Maybe I should move this to the server?? For now it will be here...
     */
    const removeEmptyObjects = (data: any) => {

      let keyRemoved = false;

      const dotted = dot.dot(data);

      for (let key in dotted) {

        if (dotted.hasOwnProperty(key)) {

          if ((_.isArray(dotted[key]) || _.isObject(dotted[key])) && !_.isDate(dotted[key])) {

            keyRemoved = true;

            _.unset(data, key);
          }

        }

      }

      if (keyRemoved) {

        data = removeEmptyObjects(data);

      }

      return data;

    };

    let data = _.cloneDeep(this.form.value);

    delete data.isSubmitted;

    if (_.isEmpty(data.tags)) {

      delete data.tags;

    }

    data = convertDates(data, []);

    data = convertServiceClassToForm(data);

    if (data.user) {

      data.user = data.user.data.id;

    }

    // This must be last function called within this.formAsPostData()
    data = removeEmptyObjects(data);

    return data;

  }

}
