import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { Observable } from 'rxjs';
import { User } from 'src/app/shared/classes/user';
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  searchInput: string='';
  
  users: Observable<User[] | null> = this.userService.allUsers$;
  
  constructor(private userService:UserService) { }

  ngOnInit(): void { }

}
