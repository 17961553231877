import { Injectable } from '@angular/core';
import { Subscription, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationGqlService extends Subscription {
  document = gql`
    subscription onNotifyNotification($recipientId: Int!) {
      onNotifyNotification(
        recipientId: $recipientId
      ) {
        notification
        recipientId
        senderId
        popup
      }
    }
  `;
}
