import { Injectable } from '@angular/core';
import { Subscription, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class ArrangementUnlockedGQLService extends Subscription {
  document = gql`
    subscription onNotifyArrangementUnlocked {
      onNotifyArrangementUnlocked {
        arrangementId
        userId
        uId
      }
    }
  `;
}
