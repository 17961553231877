import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appSelectAllOnFocus]'
})
export class SelectAllOnFocusDirective {

  constructor(private el: ElementRef) {}

  @HostListener('focus')
  onFocus() {
    const input = this.el.nativeElement;
    // Check if it's actually an input or textarea
    if (input.select) {
      input.select();
    }
  }

}
