import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ButtonModule } from 'primeng/button';
import { ImageCropperModalComponent } from './image-cropper-modal.component';

@NgModule({
  declarations: [ImageCropperModalComponent],
  imports: [CommonModule, ButtonModule, ImageCropperModule],
  exports: [ImageCropperModalComponent],
})
export class ImageCropperModalModule {}
