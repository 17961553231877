<div class="flex flex-column">

  <div class="wrapper mb-4">

    <canvas #signaturePad class="signature-pad" [class.locked]="isLocked"></canvas>

  </div>

  <div class="actions-container flex-1 flex justify-content-end">

    <!-- <button pButton pRipple type="button" [label]="(isLocked) ? 'Unlock' : 'Lock'" class="p-button-secondary" [icon]="(isLocked) ? 'pi pi-unlock' : 'pi pi-lock'" (click)="onLock($event)"></button> -->
    
    <button *ngIf="isLocked" pButton pRipple type="button" label="Clear" class="p-button-secondary" icon="pi pi-ban" (click)="onClear($event)"></button>
    <button *ngIf="!isLocked" pButton pRipple type="button" label="Save" class="p-button-primary" icon="pi pi-save" (click)="onSave($event)"></button>

  </div>

  <!-- We can't intercept/prevent modal closing in the dialog's onClose callback. So we create a new button to handle this -->
  <button pButton pRipple type="button" class="p-button-rounded p-button-text p-button-plain absolute close-button" icon="pi pi-times" (click)="onClose($event)"></button>

</div>