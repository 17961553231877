import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'arrangementOrder'
})
export class ArrangementOrderPipe implements PipeTransform {

  transform(arrangements: any, orderInput: string): any {

    const getId = (arrangement: any) => {
      return arrangement.id;
    };

    const getName = (arrangement: any) => {

      let fullName = '';

      const data = arrangement.form.value;
      
      const firstName = _.get(data, 'deceased.name.first');
      const lastName = _.get(data, 'deceased.name.last');

      if (firstName) {
        fullName += firstName;
      }

      if (lastName) {
        fullName += ' ' + lastName;
      }

      return fullName;
      
    };

    if (!orderInput || !Array.isArray(arrangements)) {

      return arrangements;

    }

    if (orderInput === 'date-asc') {

      return _.orderBy(arrangements, [getId], ['asc']);

    } else if (orderInput === 'date-desc') {

      return _.orderBy(arrangements, [getId], ['desc']);

    } else if (orderInput === 'deceased-name-asc') {

      return _.orderBy(arrangements, [getName], ['asc']);

    } else if (orderInput === 'deceased-name-desc') {

      return _.orderBy(arrangements, [getName], ['desc']);

    } else {

      return arrangements;

    }

  }

}
