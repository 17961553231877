<form *ngIf="isReady" [formGroup]="form" (ngSubmit)="onSubmit($event)" class="flex flex-column">

  <div class="formgrid grid">
    
    <div class="col-12 flex align-items-center">
      <h3 class="flex-grow-1 text-500 border-bottom-1 border-200">Display Name</h3>
    </div>

    <div class="field col-12">

      <input pInputText formControlName="name" type="text" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty': form.get('isSubmitted')?.value && form.get('name')?.invalid }" />
    
    </div>

    <div class="field col-12">

      <app-contact 
        #contact 
        [class.show-contact]="this.showContact | async" 
        controlName="contact"
      ></app-contact>

    </div>

  </div>
  
  <div class="actions-container flex-1 flex justify-content-end">
  
    <button pButton pRipple type="button" label="Cancel" class="p-button-secondary" (click)="ref.close('cancel')"></button>
    <button pButton pRipple type="submit" label="Save" icon="pi pi-save" [loading]="isSubmitting"></button>
    
  </div>

</form>