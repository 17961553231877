import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AuthenticationService } from './shared/services/authentication.service';
import { DeviceService } from './shared/services/device.service';
import { UserService } from './shared/services/user.service';

import { forkJoin, Subject, Subscription } from 'rxjs';
import { AppSyncNotification, User, UserStatus } from './shared/models/user';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LoginResponse } from './shared/models/authentication/login-response';
import { AccountService } from './shared/services/account.service';
import { ModalService } from './shared/services/modal.service';
import { ArrangementService } from './shared/services/arrangement.service';

import { environment } from 'src/environments/environment';
import { ServiceProviderService } from './shared/services/service-provider.service';
import { SiteService } from './shared/services/site.service';
import { NewAppVersionGQLService } from './shared/services/graphql/new-app-version-gql';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import gtSemver from 'semver/functions/gt';

import packageJson from '../../package.json';
import { ArrangementLockedGQLService } from './shared/services/graphql/arrangement-locked-gql';
import _ from 'lodash';
import moment from 'moment-timezone';
import { FormControl, FormGroup } from '@angular/forms';

// Add siteService to window for debugging
declare global {
  interface Window {
    siteService: SiteService;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  
  private unsubscribe$ = new Subject<void>();

  readonly NEW_APP_VERSION_REMIND_TIMEOUT_MINS = 15;
  readonly NEW_APP_VERSION_REMIND_TIMEOUT_MS = this.NEW_APP_VERSION_REMIND_TIMEOUT_MINS * 60 * 1000;

  currentNewVersionReminderTimer?: NodeJS.Timer;

  @HostListener('keypress', ['$event'])
  @HostListener('mousemove', ['$event'])
  onEvent(event: any) {

    if (!this.authenticated) {
      return;
    }

    this.idleTime = 0;

    // Get the authenticated user's do-not-disturb status
    const isDnd: boolean = localStorage.getItem('isDND') === '1';

    // Get the authenticated user's current status
    const currentStatus: UserStatus | null = (this.userService.userSourceValue && this.userService.userSourceValue.status) ? this.userService.userSourceValue.status : null;

    // Set the authenticated user's status to online and restart away timeout
    if (currentStatus !== UserStatus.Online && !isDnd) {
      this.userService.userSourceValue.status = UserStatus.Online;
      // this.userService.setStatus(UserStatus.Online);
      // this.startInterval();
    }

  }

  idleTime: number = 0;
  idleInterval: any;
  user!: User | null;

  authenticated: boolean;
  deviceIsReady: boolean;
  deviceIsOnline: boolean;
  deviceIsPaused: boolean;
  devicePlatform: string;
  cordovaPlatform: string;

  authenticationChecked$ = this.authenticationService.authenticationChecked;

  subscriptions: Subscription[];

  constructor(
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
    private deviceService: DeviceService,
    private primengConfig: PrimeNGConfig,
    private arrangementService: ArrangementService,
    private serviceProviderService: ServiceProviderService,
    private userService: UserService,
    private modalService: ModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private siteService: SiteService,
    private newAppVersionGQLService: NewAppVersionGQLService,
    private arrangementLockedGQLService: ArrangementLockedGQLService,
    private renderer: Renderer2,
    private elRef: ElementRef,
  ) {

    this.primengConfig.ripple = true;

    this.authenticated = false;
    this.deviceIsReady = false;
    this.deviceIsOnline = true;
    this.deviceIsPaused = false;
    this.devicePlatform = 'unknown';
    this.cordovaPlatform = 'unknown';

    this.subscriptions = [];

    const originalConsoleError = console.error;

    console.error = function(...args) {

      originalConsoleError.apply(console, args);

      // Convert error objects to string to ensure they are stored correctly
      const errorStrings = args.map(arg => {

        if (arg instanceof Error) {
          return `${moment().format('YYYY-MM-DD HH:mm:ss')}\nError: ${arg.message}\nStack: ${arg.stack}`;
        } else {

          if (_.has(arg, 'error.error')) {
            return moment().format('YYYY-MM-DD HH:mm:ss') + '\n' + JSON.stringify(arg.error.error);
          } else if (_.has(arg, 'arg.error')) {
            return moment().format('YYYY-MM-DD HH:mm:ss') + '\n' + JSON.stringify(arg.error);
          } else {
            return moment().format('YYYY-MM-DD HH:mm:ss') + '\n' + JSON.stringify(arg);
          }

        }

      });

      // Get existing errors from local storage
      const existingErrors = JSON.parse(localStorage.getItem('loggedErrors') || '[]');

      // Add the new error to the start of the array
      existingErrors.unshift(errorStrings.join('\n\n'));

      if (navigator && navigator.userAgent) {
        existingErrors.unshift('User Agent: ' + navigator.userAgent + '\n\n');
      }

      // Platform is deprecated, I know. But it's still useful for debugging
      if (navigator && navigator.platform) {
        existingErrors.unshift('Platform: ' + navigator.platform + '\n\n');
      }

      // Keep only the last 100 errors
      if (existingErrors.length > 100) {
        existingErrors.length = 100;
      }

      // Update local storage
      localStorage.setItem('loggedErrors', JSON.stringify(existingErrors));

    };

  }

  ngOnInit() {
    
    this.siteService.addSubscriptionLog(this, 'app.component.ts->ngOnInit->this.router.events');

    this.router.events.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('app.component.ts->ngOnInit->this.router.events')),
      takeUntil(this.unsubscribe$),
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      console.log('NavigationEnd', event);
      if (event.id === 1) {
        if (!event.url.includes('dashboard')) {
          this.siteService.unBlockUi();
        }
      }
    });

    this.authenticationService.isAuthenticated.subscribe({
      next: (isAuthenticated) => {

        if (isAuthenticated === null) {

          return;

        }

        if (isAuthenticated === true) {

          const user = this.user = this.authenticationService.currentUserValue;
        
          if (user) {
  
            const status = isAuthenticated ? UserStatus.Online : UserStatus.Offline;
  
            this.userService.set(user);

            if(!user.isActivated){
              const modal = this.modalService.activateAccount();
            }

            this.userService.setNotifications(user)

          }

          forkJoin([
            this.userService.getRegions(),
            this.userService.getRoles(),
            this.userService.retrieveAll(),
            this.accountService.get(),
            this.serviceProviderService.getAll(),
          ]).subscribe({
            next: (res) => {

              if (this.router.url === '/') {
                
                this.router.navigate(['dashboard', 'arrangements', 'list']);
                
              }

              this.siteService.criticalDataReady();
              this.siteService.unBlockUi();

            }
          });

          this.authenticated = true;

        } else if (isAuthenticated === false) {

          this.siteService.unBlockUi();

          const token = localStorage.getItem('token');

          if (token) {

            this.modalService.error({
              title: 'Authentication Error',
              message: ['It looks like your session has expired.', 'Please login again to continue.']
            });

          }

        }

      },
      error: (err) => {

        this.siteService.unBlockUi();

        this.modalService.error(err.error.error);

      }
    });

    this.deviceService.deviceIsReady.subscribe({
      next: (device) => { 
        this.deviceIsReady = true; 
        this.devicePlatform = device.platform;
      }
    });

    this.deviceService.devicePlatform.subscribe({
      next: (platform) => { 
        this.cordovaPlatform = platform;
      }
    });

    this.deviceService.deviceOnOffline.subscribe({
      next: () => { 
        this.deviceIsOnline = false; 
      }
    });

    this.deviceService.deviceOnOnline.subscribe({
      next: () => { 
        this.deviceIsOnline = true; 
      }
    });

    this.deviceService.deviceOnPause.subscribe({
      next: () => { 

        // if(this.idleInterval){
        //   clearInterval(this.idleInterval);
        // }

        // this.deviceIsPaused = true; 

        // this.changeStatus(UserStatus.Away);

      }
    });

    this.deviceService.deviceOnResume.subscribe({
      next: () => {

        // this.deviceIsPaused = false; 

        // if (this.user) {

        //   this.startInterval();

        //   this.changeStatus(UserStatus.Online);

        // }

      }
    });

    this.newAppVersionGQLService.subscribe().subscribe({
      next: (data: any) => {

        this.onNewVersion(data.data.onNotifyNewAppVersion);

      }
    });

    this.siteService.addSubscriptionLog(this, 'app.component.ts->ngOnInit->this.authenticationService.isAuthenticated');

    this.authenticationService.isAuthenticated.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('app.component.ts->ngOnInit->this.authenticationService.isAuthenticated')),
      takeUntil(this.unsubscribe$),
      filter(isAuthenticated => !!isAuthenticated),
      switchMap(() => this.userService.user$),
      switchMap((user) => user.notification$),
      filter((notification) => !!notification),
    ).subscribe({
      
      next: (data: AppSyncNotification) => {

        this.onNewNotification(data);

      }
    });
  
    this.authenticationService.verifyAuthenticationToken().subscribe();

  }

  ngOnDestroy(): void {
  
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

  }

  startInterval() {

    // Commented out by Ivan because we don't use this any more

    // if (this.idleInterval) {

    //   clearInterval(this.idleInterval);

    // }

    // const interval = environment.status.interval || 1000;
    // const awayStatusTimeout = environment.status.awayTimeout;

    // this.idleTime = 0;

    // this.idleInterval = setInterval(() => {

    //   this.idleTime += interval;

    //   if (this.idleTime >= awayStatusTimeout) {

    //     this.changeStatus(UserStatus.Away);

    //     clearInterval(this.idleInterval);

    //   } else {

    //     // Get the authenticated user's do-not-disturb status
    //     const isDnd: boolean = localStorage.getItem('isDND') === '1';

    //     if (!isDnd) {

    //       this.changeStatus(UserStatus.Online);

    //     }

    //   }

    // }, interval);

  }

  changeStatus(status:UserStatus) {

    // Commented out by Ivan because we don't use this any more

    // const user = this.authenticationService.currentUserValue;

    // if (user && localStorage.getItem('isDND') !== '1') {

    //   this.userService.setStatus(status);

    // } 

  }

  private onNewVersion(versionInfo: any) {
    // Clean up old reminder if any
    if (this.currentNewVersionReminderTimer) {
      clearTimeout(this.currentNewVersionReminderTimer);
      this.currentNewVersionReminderTimer = undefined;
    }

    const isNewerThanCurrentVersion = gtSemver(
      versionInfo.version,
      packageJson.version
    );

    // console.log('New version: ' + versionInfo.version);
    // console.log('Current version: ' + packageJson.version);
    // console.log('Should update: ', isNewerThanCurrentVersion);

    if (isNewerThanCurrentVersion) {
      const modal = this.modalService.generic({
        title: `New version ${versionInfo.version} available!`,
        copy: [
          versionInfo.changelog || '',
          `<b>Do you want to reload browser tab and get the latest update now?</b>`,
        ],
        buttons: [
          {
            label: `Remind me in ${this.NEW_APP_VERSION_REMIND_TIMEOUT_MINS} mins`,
            key: 'cancel',
            class: 'p-button-secondary',
          },
          { label: 'Yes, please!', key: 'confirm', class: '' },
        ],
      });

      modal.onClose.subscribe((confirmResult) => {
        // On confirm. Reload browser immediately
        if (confirmResult === 'confirm') {
          document.location.reload();
          return;
        }

        // On skip. Remind after a while
        this.currentNewVersionReminderTimer = setTimeout(() => {
          this.onNewVersion(versionInfo);
        }, this.NEW_APP_VERSION_REMIND_TIMEOUT_MS);
      });
    }
  }
  
  private onNewNotification(notification: AppSyncNotification) {
      if (notification.popup) {
        this.modalService.generic({
          title: 'Notification',
          copy: [notification.notification],
          buttons: [
            { label: 'Ok', key: 'close', class: '' },
          ],
        });
      }
  }

}
