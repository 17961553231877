<p-calendar 
  #calendarElement 
  dataType="date" 
  [formControlName]="controlName" 
  [showWeek]="showWeek" 
  [showTime]="showTime" 
  [showButtonBar]="showButtonBar" 
  [showIcon]="showIcon" 
  [yearRange]="yearRange" 
  [touchUI]="false" 
  [readonlyInput]="readOnlyInput" 
  [timeOnly]="timeOnly" 
  (onSelect)="onSelect($event)" 
  (onInput)="onInput($event)" 
  [ngClass]="{ 'ng-invalid ng-dirty' : control?.invalid && isSubmittedControl?.value }"
>
  <ng-template *ngIf="!timeOnly" pTemplate="header">
    <div class="w-full flex justify-content-between">
      <button type="button" pButton pRipple (click)="onPrevMonthClick($event)" icon="pi pi-chevron-left" class="p-button-outlined p-button-rounded"></button>
      <div class="flex-1 flex justify-content-center" [formGroup]="formGroup">

        <p-dropdown (onChange)="onChangeMonth($event)" formControlName="selectedMonth" [options]="months" placeholder="Select a Month" class="mr-3"></p-dropdown>

        <p-dropdown (onChange)="onChangeYear($event)" formControlName="selectedYear" [options]="years" placeholder="Select a Year" class="ml-3"></p-dropdown>

      </div>
      <button type="button" pButton pRipple (click)="onNextMonthClick($event)" icon="pi pi-chevron-right" class="p-button-outlined p-button-rounded"></button>
    </div>
  </ng-template>
</p-calendar>
<p-menu #optionsMenu [popup]="true" [model]="menuOptions" appendTo="body"></p-menu>
<button class="btn-options" type="button" pButton icon="pi pi-cog" (click)="optionsMenu.toggle($event)"></button>