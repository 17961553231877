import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericComponent } from './generic.component';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { PipeModule } from '../../pipe.module';



@NgModule({
  declarations: [
    GenericComponent
  ],
  imports: [
    CommonModule,
    RippleModule,
    ButtonModule,
    TooltipModule,
    PipeModule,
  ],
  exports: [
    GenericComponent
  ],
})
export class GenericModule { }
