import { Component, EventEmitter, Input, OnInit, Output, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, AbstractControl } from '@angular/forms';
import _ from 'lodash';
import { MenuItem } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { changeDetection } from 'src/app/shared/change-detection';
import { UniqueId } from 'src/app/shared/uniqueId';

export interface Chip {
  name: string;
  value: any;
  _meta?: {
    id: string;
    index: number;
  };
}

export interface OnChangeEvent {
  originalEvent: Event;
  value: Chip[];
  itemValue: Chip;
}

@Component({
  selector: 'app-select-multi',
  templateUrl: './select-multi.component.html',
  styleUrls: ['./select-multi.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class SelectMultiComponent implements OnInit {

  @Input() options!: any[];

  @Input() controlName!: string;
  @Input() placeHolder!: string;

  @Input() showOptions!: boolean;

  @ViewChild('multiSelectElement') selectElement!: MultiSelect;

  @Output() onChange: EventEmitter<OnChangeEvent> = new EventEmitter<OnChangeEvent>();

  formRoot!: AbstractControl;
  formGroup!: AbstractControl;

  isInvalid: boolean;

  menuOptions: MenuItem[];

  clonedOptions: any[];

  isReady: boolean;
  
  get isSubmittedControl(): AbstractControl | null {
    return this.formRoot.get('isSubmitted');
  }

  get formControl(): AbstractControl | null {
    return this.formGroup;
  }

  constructor(
    private controlContainer: ControlContainer
  ) { 

    this.isInvalid = false;

    this.menuOptions = [
      {
        label: 'Add Option',
        command: () => { },
        icon: 'pi pi-plus'
      }
    ];

    this.clonedOptions = [];

    this.isReady = false;

  }

  ngOnInit(): void {

    this.clonedOptions = _.cloneDeep(this.options);

    const control = this.controlContainer.control?.get(this.controlName);

    if (control) {

      this.formGroup = control;
      this.formRoot = this.formGroup.root;

      this.formGroup.statusChanges.subscribe({
        next: (status: any) => {
          setTimeout(() => {
            if (status === 'INVALID') {
              this.isInvalid = true;
            } else {
              this.isInvalid = false;
            }
          }, 1);
        }
      });

      if (this.formGroup.value) {

        (this.formGroup.value as Chip[]).forEach((v, i, a) => {
          
          const index = _.findIndex(this.clonedOptions, option => option.value === v.value);

          if (index > -1) {
            this.clonedOptions[index] = v;
          } else {
            this.clonedOptions.push(v);
          }

        });

      }

    }

    changeDetection(() => {
      this.isReady = true;
    });

  }

  _onChange(event: OnChangeEvent ): void {

    event.value = event.value.map((v, i, a) => {

      if (!v._meta) {
        
        const uniqueId = new UniqueId();

        v._meta = {
          id: uniqueId.getUniqueInArray(a, '_meta.id', 'id-'),
          index: a.length -1,
        }

      } else {

        v._meta.index = i;

      }

      return v;

    });

    this.onChange.emit(event);

  }

}
