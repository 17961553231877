/* eslint-disable max-len */
/* eslint-disable prefer-arrow/prefer-arrow-functions */

import * as _ from 'lodash';

export function easyFormatter(haystack: string, needles: { [key: string]: string }): string {

  let str = _.clone(haystack);

  // Find the length of the key
  const needlesWithLength = _.mapValues(needles, (v,k) => ({
      length: '' + k.length,
      value: '' + v,
      key: '' + k
    }));

  // Sort OBJ by key length
  const needlesSortedByLength = _.sortBy(needlesWithLength, needle => needle.length);

  // Reverse so we don't replace :param123 with the value in :param
  _.reverse(needlesSortedByLength);

  // Do the replace
  _.forEach(needlesSortedByLength, needle => {
    str = _.replace(str, `:${needle.key}`, needle.value);
  });

  // Spit out our new formatted string
  return str;

}
