import { Injectable } from '@angular/core';
import { DynamicItem } from '../dynamic-item';

import _ from 'lodash';

import { DynamicFormStructure } from '../dynamic-form-structure';

import { FormStructure } from '../../forms/form-structure';

import { AudioVisualComponent } from '../components/audio-visual/audio-visual.component';
import { BirthsDeathsAndMarriagesComponent } from '../components/births-deaths-and-marriages/births-deaths-and-marriages.component';
import { BurialComponent } from '../components/burial/burial.component';
import { CateringComponent } from '../components/catering/catering.component';
import { CoffinComponent } from '../components/coffin/coffin.component';
import { CremationComponent } from '../components/cremation/cremation.component';
import { EventStaffComponent } from '../components/event-staff/event-staff.component';
import { FlowerComponent } from '../components/flower/flower.component';
import { MedicalCertificateComponent } from '../components/medical-certificate/medical-certificate.component';
import { MiscellaneousComponent } from '../components/miscellaneous/miscellaneous.component';
import { MortuaryComponent } from '../components/mortuary/mortuary.component';
import { MusicComponent } from '../components/music/music.component';
import { NotificationComponent } from '../components/notification/notification.component';
import { StationeryComponent } from '../components/stationery/stationery.component';
import { TransportComponent } from '../components/transport/transport.component';
import { UrnComponent } from '../components/urn/urn.component';
import { VenueComponent } from '../components/venue/venue.component';

export enum DynamicComponents {
  AudioVisual = 'audio-visual',
  BirthsDeathsAndMarriages = 'births-deaths-and-marriages',
  Burial = 'burial',
  Catering = 'catering',
  Coffin = 'coffin',
  Cremation = 'cremation',
  EventStaff = 'event-staff',
  Flower = 'flower',
  MedicalCertificate = 'medical-certificate',
  Miscellaneous = 'miscellaneous',
  Mortuary = 'mortuary',
  Music = 'music',
  Notification = 'notification',
  Stationery = 'stationery',
  Transport = 'transport',
  Urn = 'urn',
  Venue = 'venue',
};

@Injectable({
  providedIn: 'root'
})
export class DynamicService {

  constructor() { }

  getDynamicItem(dynamicComponent: DynamicComponents, data: any): DynamicItem | null {

    switch (dynamicComponent) {

      case DynamicComponents.AudioVisual :

        return new DynamicItem(AudioVisualComponent, data);

      case DynamicComponents.BirthsDeathsAndMarriages :

        return new DynamicItem(BirthsDeathsAndMarriagesComponent, data);

      case DynamicComponents.Burial :

        return new DynamicItem(BurialComponent, data);

      case DynamicComponents.Catering :

        return new DynamicItem(CateringComponent, data);

      case DynamicComponents.Coffin :

        return new DynamicItem(CoffinComponent, data);

      case DynamicComponents.Cremation :

        return new DynamicItem(CremationComponent, data);

      case DynamicComponents.EventStaff :

        return new DynamicItem(EventStaffComponent, data);

      case DynamicComponents.Flower :

        return new DynamicItem(FlowerComponent, data);

      case DynamicComponents.MedicalCertificate :

        return new DynamicItem(MedicalCertificateComponent, data);

      case DynamicComponents.Miscellaneous :

        return new DynamicItem(MiscellaneousComponent, data);

      case DynamicComponents.Mortuary :

        return new DynamicItem(MortuaryComponent, data);

      case DynamicComponents.Music :

        return new DynamicItem(MusicComponent, data);

      case DynamicComponents.Notification :

        return new DynamicItem(NotificationComponent, data);

      case DynamicComponents.Stationery :

        return new DynamicItem(StationeryComponent, data);

      case DynamicComponents.Transport :

        return new DynamicItem(TransportComponent, data);

      case DynamicComponents.Urn :

        return new DynamicItem(UrnComponent, data);

      case DynamicComponents.Venue :

        return new DynamicItem(VenueComponent, data);

      default :

        console.error(`The dynamic component of ${dynamicComponent} isn't supported yet.`);

        return null;

    }

  }

  getDynamicFormStructure(dynamicComponent: DynamicComponents): FormStructure | null {

    const dynamicFormStructure = new DynamicFormStructure();

    switch (dynamicComponent) {

      case DynamicComponents.AudioVisual :

        return dynamicFormStructure.getAudioVisualStructure();

      case DynamicComponents.BirthsDeathsAndMarriages :

      return dynamicFormStructure.getBirthsDeathsAndMarriagesStructure();

      case DynamicComponents.Burial :

      return dynamicFormStructure.getBurialStructure();

      case DynamicComponents.Catering :

      return dynamicFormStructure.getCateringStructure();

      case DynamicComponents.Coffin :

      return dynamicFormStructure.getCoffinStructure();

      case DynamicComponents.Cremation :

      return dynamicFormStructure.getCremationStructure();

      case DynamicComponents.EventStaff :

      return dynamicFormStructure.getEventStaffStructure();

      case DynamicComponents.Flower :

      return dynamicFormStructure.getFlowerStructure();

      case DynamicComponents.MedicalCertificate :

      return dynamicFormStructure.getMedicalCertificateStructure();

      case DynamicComponents.Miscellaneous :

      return dynamicFormStructure.getMiscellaneousStructure();

      case DynamicComponents.Mortuary :

      return dynamicFormStructure.getMortuaryStructure();

      case DynamicComponents.Music :

      return dynamicFormStructure.getMusicStructure();

      case DynamicComponents.Notification :

      return dynamicFormStructure.getNotificationStructure();

      case DynamicComponents.Stationery :

      return dynamicFormStructure.getStationeryStructure();

      case DynamicComponents.Transport :

      return dynamicFormStructure.getTransportStructure();

      case DynamicComponents.Urn :

      return dynamicFormStructure.getUrnStructure();

      case DynamicComponents.Venue :

      return dynamicFormStructure.getVenueStructure();

      default :

        console.error(`The dynamic form structure of ${dynamicComponent} isn't supported yet.`);

        return null;

    }

  }

}
