import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompilerDirective } from './compiler.directive';
import { CompilerComposeComponent } from './compiler-compose/compiler-compose.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
// import { MonacoEditorModule } from 'ngx-monaco-editor';
// Note from Ivan: We used "ngx-monaco-editor": "^12.0.0" but it was causing compile issues so I removed it

@NgModule({
  declarations: [
    CompilerDirective,
    CompilerComposeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // MonacoEditorModule.forRoot(),
    StyleClassModule,
    RippleModule,
    ButtonModule,
    InputTextModule,
  ],
  exports: [
    CompilerDirective,
    CompilerComposeComponent,
  ],
})
export class CompilerModule { }
